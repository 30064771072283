export default function upsertGHSData({ sdsId, sdsLanguage } = {}) {
  return {
    templateUrl: require('./upsertGHSData.html'),
    keyboard: true,
    backdrop: true,
    resolve: {
      getGHSDataResponse: [
        'SpinnerService',
        'GHSData',
        function (SpinnerService, GHSData) {
          return SpinnerService.wrap(() => GHSData.getGHSData({}, { sdsIds: [sdsId] }).$promise)();
        }
      ]
    },
    controllerAs: 'ctrl',
    controller: [
      '$uibModalInstance',
      'getGHSDataResponse',
      'SpinnerService',
      'MessagesService',
      'GHSData',
      function ($uibModalInstance, getGHSDataResponse, SpinnerService, MessagesService, GHSData) {
        const existingGHSData = getGHSDataResponse[sdsId];
        const ctrl = this;

        ctrl.isQuantumSDS = !!existingGHSData.authoringProductNumber;
        ctrl.data = {
          language: sdsLanguage,
          productNumber: replaceNewlinesToBr(
            existingGHSData.authoringProductNumber || existingGHSData.productNumber
          ),
          signalWord: replaceNewlinesToBr(existingGHSData.signalWord),
          hazardStatements: replaceBrToNewlines(existingGHSData.hazardStatements),
          precPrevention: replaceBrToNewlines(existingGHSData.precPrevention),
          precResponse: replaceBrToNewlines(existingGHSData.precResponse),
          precStorage: replaceBrToNewlines(existingGHSData.precStorage),
          precDisposal: replaceBrToNewlines(existingGHSData.precDisposal),
          hnoc: replaceBrToNewlines(existingGHSData.hnoc)
        };

        ctrl.dismiss = $uibModalInstance.dismiss;
        ctrl.save = SpinnerService.wrap(function () {
          const payload = { ...ctrl.data };
          for (const key of Object.keys(payload)) {
            const value = payload[key];
            if (value && typeof value === 'string') {
              payload[key] = replaceNewlinesToBr(value);
            }
          }

          return GHSData.upsertGHSData({ sdsId }, payload)
            .$promise.then(ghsData => {
              MessagesService.success('COMMON.MESSAGES.UPDATED');
              $uibModalInstance.close(ghsData);
            })
            .catch(function (err) {
              console.error('upsertGHSData ERR:', err);
              MessagesService.error(
                err.data && err.data.error ? err.data.error.message : err.message
              );
            });
        });
      }
    ]
  };
}

/**
 * Replaces newline characters (\r\n, \n, and \r) with <br> tags
 *
 * @param {string} input
 * @returns {string} the modified string with newlines replaced by <br>
 */
function replaceNewlinesToBr(input) {
  if (!input) return '';

  return input.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
}

/**
 * Replaces <br> tags (including variants like <br/>, <br />) with newline characters
 *
 * @param {string} input
 * @returns {string} the modified string with <br> tags replaced by \n
 */
function replaceBrToNewlines(input) {
  if (!input) return '';

  return input.replace(/<br\s*\/?>/gi, '\n');
}
