import angular from 'angular';
import { get, clone } from 'lodash';
import crypto from 'crypto';
import app from '../../app';
import { objectify, deObjectify, isInArray } from '../../helpers';
import confirmMessage from '../../modals/confirmMessage';

app.controller('settingsCtrl', [
  '$scope',
  '$rootScope',
  '$location',
  '$anchorScroll',
  '$timeout',
  '$translate',
  '$q',
  'moduleNames',
  'bannedListType',
  'CompanyService',
  'CompanySetting',
  'IPWhitelist',
  'BannedList',
  'ProcessingTemplates',
  'MessagesService',
  'ErrorService',
  'StorageCodes',
  'DisposalCodes',
  'ModalService',
  'LabelTemplatesService',
  'roles',
  'companyTypes',
  'SpinnerService',
  'SolrDocument',
  'Company',
  'SchedulerApi',
  'SchedulerService',
  settingsCtrl
]);

function settingsCtrl(
  $scope,
  $rootScope,
  $location,
  $anchorScroll,
  $timeout,
  $translate,
  $q,
  moduleNames,
  bannedListType,
  CompanyService,
  CompanySetting,
  IPWhitelist,
  BannedList,
  ProcessingTemplates,
  MessagesService,
  ErrorService,
  StorageCodes,
  DisposalCodes,
  ModalService,
  LabelTemplatesService,
  roles,
  companyTypes,
  SpinnerService,
  SolrDocument,
  Company,
  SchedulerApi,
  SchedulerService
) {
  // props in commonSettings
  const localeRestrictedSettings = {
    bannedListType: {
      ca: [
        bannedListType.custom,
        bannedListType.stao,
        bannedListType.bc,
        bannedListType.ab,
        bannedListType.mb
      ],
      us: [bannedListType.custom]
    },
    modules: {
      ca: [moduleNames.hse, moduleNames.inv],
      us: [
        moduleNames.hse,
        moduleNames.inv,
        moduleNames.CERCLAReport,
        moduleNames.cers,
        moduleNames.ReportTier2,
        moduleNames.ReportTier2Texas,
        moduleNames.ReportPAHSSF,
        moduleNames.ReportFireResponse,
        moduleNames.ReportWCL,
        moduleNames.Section313Report
      ]
    }
  };
  const roleNames = ['teamAdmin', 'teamMember', 'teacher', 'invManagerRead'];
  const companyStaffRoleNames = ['teamAdmin', 'teacher', 'invManagerRead'];
  const systemRoleNames = ['sdsEditor'];
  const scheduledModules = {
    [moduleNames.CERCLAReport]: ['CERCLA_REPORT'],
    [moduleNames.ReportFireResponse]: ['FIRE_RESPONSE_REPORT'],
    [moduleNames.ReportWCL]: ['WCL_REPORT'],
    [moduleNames.Section313Report]: ['SECTION313_REPORT'],
    [moduleNames.inv]: ['WCL_REPORT', 'FIRE_RESPONSE_REPORT']
  };
  const defaultAddSdsFormField = {
    name: '',
    type: '',
    isRequired: false,
    values: [],
    tagsWorkflow: false,
    inventoryWorkflow: false,
  };
  var vm = this;
  let isLabelBatchUndefined;
  $scope.addSdsFormSet = ['addSdsForm', 'requesterForm', 'approverForm'];
  $scope.addSdsFormSet.forEach(formName => {
    vm[`${formName}Field`] = { ...defaultAddSdsFormField };
  });
  vm.storageCode = {};
  vm.disposalCode = {};
  vm.disableButton = false;
  vm.knownLabelTemplates = LabelTemplatesService.known;
  vm.roles = roles; // store in vm just to prop into azureRoles directive
  vm.roleNames = roles
    .map(function (role) {
      return role.name;
    })
    .filter(function (roleName) {
      return roleNames.includes(roleName);
    });

  vm.companyCustomFields = [];

  vm.companyStaffRoleNames = roles
    .map(function (role) {
      return role.name;
    })
    .filter(function (roleName) {
      return companyStaffRoleNames.includes(roleName);
    });
  vm.systemRoles = roles.filter(function (role) {
    return systemRoleNames.includes(role.name);
  });
  vm.companyTypes = companyTypes;
  vm.companies = [];
  vm.scheduler = {
    schedulerApiStatus: false,
    openSdsRequestsReminder: false,
    openSdsRequestsReminderPeriod: 1
  };
  vm.bannedListType = bannedListType;
  vm.searchPageLinksChanged = false;
  vm.multiselectLabels = {};

  $scope.CompanySetting = {};
  $scope.CompanySetting.sdsLayout = {};
  $scope.CompanySetting.addSdsMailTo = {};
  $scope.bannedListCollapsed = true;

  function textTranslate() {
    $translate([
      'PROCESS.SOLR_FORM.SECTION_1',
      'PROCESS.SOLR_FORM.SECTION_2',
      'PROCESS.SOLR_FORM.SECTION_3',
      'PROCESS.SOLR_FORM.SECTION_4',
      'PROCESS.SOLR_FORM.SECTION_5',
      'PROCESS.SOLR_FORM.SECTION_6',
      'PROCESS.SOLR_FORM.SECTION_7',
      'PROCESS.SOLR_FORM.SECTION_8',
      'PROCESS.SOLR_FORM.SECTION_9',
      'PROCESS.SOLR_FORM.SECTION_10',
      'PROCESS.SOLR_FORM.SECTION_11',
      'PROCESS.SOLR_FORM.SECTION_12',
      'PROCESS.SOLR_FORM.SECTION_13',
      'PROCESS.SOLR_FORM.SECTION_14',
      'PROCESS.SOLR_FORM.SECTION_15',
      'PROCESS.SOLR_FORM.SECTION_16'
    ]).then(function (translations) {
      setTranslations(translations);
    });
    translateLabels();
  }

  function setTranslations(translate) {
    ($scope.CompanySetting.sdsLayout.sections || []).forEach(function (section, index) {
      section.title = translate['PROCESS.SOLR_FORM.SECTION_' + section.number];
    });
  }

  function translateLabels() {
    $translate([
      'COMMON.ACTIONS.CLEAR',
      'COMMON.ACTIONS.RESET_SHORT',
      'COMMON.ACTIONS.SELECT_ALL',
      'COMMON.ACTIONS.UNSELECT_ALL',
      'COMMON.ACTIONS.SELECT',
      'COMMON.ACTIONS.SELECTED'
    ]).then(function (translation) {
      vm.options.clear.label = translation['COMMON.ACTIONS.CLEAR'];
      vm.options.reset.label = translation['COMMON.ACTIONS.RESET_SHORT'];
      vm.multiselectLabels = {
        selectAll: translation['COMMON.ACTIONS.SELECT_ALL'],
        unselectAll: translation['COMMON.ACTIONS.UNSELECT_ALL'],
        select: translation['COMMON.ACTIONS.SELECT'],
        itemsSelected: translation['COMMON.ACTIONS.SELECTED']
      };
    });
  }

  function initialSections() {
    $scope.CompanySetting.sdsLayout.sections = [
      { field: 'product_and_company_identification', number: 1, visible: true, opened: true },
      { field: 'hazards_identification', number: 2, visible: true, opened: true },
      { field: 'composition_information_ingredients', number: 3, visible: true, opened: true },
      { field: 'first_aid_measures', number: 4, visible: true, opened: true },
      { field: 'fire_fighting_measures', number: 5, visible: true, opened: true },
      { field: 'accidental_release_measures', number: 6, visible: true, opened: true },
      { field: 'handling_storage', number: 7, visible: true, opened: true },
      { field: 'exposure_controls_personal_protection', number: 8, visible: true, opened: true },
      { field: 'physical_chemical_properties', number: 9, visible: true, opened: true },
      { field: 'stability_reactivity', number: 10, visible: true, opened: true },
      { field: 'toxilogial_information', number: 11, visible: true, opened: true },
      { field: 'ecological_information', number: 12, visible: true, opened: true },
      { field: 'disposal_considerations', number: 13, visible: true, opened: true },
      { field: 'transport_information', number: 14, visible: true, opened: true },
      { field: 'regulatory_information', number: 15, visible: true, opened: true },
      { field: 'other_information', number: 16, visible: true, opened: true }
    ];

    textTranslate();
  }

  var simpleCatch = ErrorService.simpleCatch;

  $rootScope.$on('$translateChangeSuccess', function () {
    textTranslate();
  });

  $scope.borderStyles = [
    'Crosshatched 1',
    'Crosshatched 2',
    'dashed',
    'dotted',
    'solid',
    'double',
    'groove',
    'ridge',
    'inset',
    'outset',
    'hidden'
  ];

  var processingTemplates = new ProcessingTemplates();
  $scope.processingTemplates = processingTemplates.processingTemplates;
  $scope.selectedTemplate = processingTemplates.selectedTemplate;
  $scope.onTemplateSelected = processingTemplates.onTemplateSelected;

  // unless we have only portal and search for common users [1/3]
  $scope.commonSettings = {};

  $scope.scrollTo = function (elementStr) {
    if (elementStr) $location.hash(elementStr);
    if ($location.hash() === 'banned-list') $scope.bannedListCollapsed = false;
    $anchorScroll();
  };

  $scope.$on('$stateChangeSuccess', function (event, newLoc, oldLoc) {
    $timeout($scope.scrollTo);
  });

  $scope.onCompanySelected = SpinnerService.wrap(function (selectedCompany) {
    $scope.companyChanging = true;
    $scope.CompanySetting.layoutSaved = '';
    $scope.BannedList.bannedAdded = '';

    vm.selectedCompany = selectedCompany;
    vm.parentCompanies = [
      { id: null, name: 'No parent SDS company' },
      ...vm.companies.filter(
        company => company.id !== vm.selectedCompany.id && !company.sdsParentCompanyId
      )
    ];

    vm.currentParentSdsCompany = vm.parentCompanies.find(function (company) {
      return company.id == vm.selectedCompany.sdsParentCompanyId;
    });

    vm.currentCompanyType = vm.companyTypes.find(function (type) {
      return type.id === vm.selectedCompany.companyTypeId;
    });

    getCompanyReminder(vm.selectedCompany.id);

    return initCompanySettings().finally(() => ($scope.companyChanging = false));
  });

  $scope.onParentCompanySelected = SpinnerService.wrap(function () {
    const sdsParentCompanyId = vm.currentParentSdsCompany.id;
    const prevParentCompanyId = vm.selectedCompany.sdsParentCompanyId;

    return Company.prototype$updateAttributes({ id: vm.selectedCompany.id }, { sdsParentCompanyId })
      .$promise.then(() => refreshCompany(sdsParentCompanyId || prevParentCompanyId))
      .then(function () {
        vm.selectedCompany.sdsParentCompanyId = sdsParentCompanyId;
        MessagesService.success('COMMON.MESSAGES.UPDATED');
      })
      .catch(simpleCatch);
  });

  $scope.onCompanyTypeSelected = SpinnerService.wrap(function () {
    return Company.prototype$updateAttributes(
      { id: vm.selectedCompany.id },
      { companyTypeId: vm.currentCompanyType.id }
    )
      .$promise.then(function () {
        vm.selectedCompany.companyType = vm.currentCompanyType;
        MessagesService.success('COMMON.MESSAGES.UPDATED');
      })
      .catch(simpleCatch);
  });

  vm.labelsForAccess = {};

  vm.updateMultiselectValues = function (settingsName, value) {
    if (!value) return;

    let data = {};
    data[settingsName] = value;

    upsertCompanySetting(data, true); // we have to use the quiet update because the library
    // triggers this function on init, not sure why
  };

  $scope.updateBannedTemplateMessage = function (settings) {
    let data = {};

    $rootScope.languages.forEach(lang => {
      let propName =
        'bannedTemplateMessage' + (lang === $rootScope.majorLanguage ? '' : '_' + lang);
      data[propName] = settings[propName];
    });

    upsertCompanySetting(data);
  };

  $scope.BannedList = {};
  $scope.BannedList.bannedList = [];

  $scope.BannedList.removeFromBannedList = RemoveFromBannedList;

  $scope.CompanySetting.saveLayoutClicked = function () {
    $scope.CompanySetting.layoutSaved = '';
    $scope.CompanySetting.sdsLayout.companyId = $scope.currentCompanyId;

    if ($scope.CompanySetting.sdsLayout.id) {
      UpdateLayout($scope.CompanySetting.sdsLayout);
    } else {
      GetCompanySettings(vm.selectedCompany.id, UpdateLayout);
    }
  };

  $scope.CompanySetting.addEmail = function () {
    $scope.CompanySetting.addSdsMailTo.added = '';
    var email = {};

    for (var i = 0; i < this.distributionList.length; i++) {
      if (this.distributionList[i] === this.addSdsMailTo.email) {
        $translate('SETTINGS.EMAIL_EXISTS').then(
          function (message) {
            $scope.CompanySetting.addSdsMailTo.added = message;
          },
          function (translationId) {
            $scope.CompanySetting.addSdsMailTo.added = translationId;
          }
        );

        return;
      }
    }

    email.distributionList = this.distributionList.slice();
    email.distributionList.push(this.addSdsMailTo.email);
    email.id = vm.selectedCompany.id;

    CompanySetting.upsert(
      email,
      function (updatedCompanySetting) {
        console.log('success');
        $scope.CompanySetting.distributionList = updatedCompanySetting.distributionList;
        $scope.CompanySetting.addSdsMailTo.email = '';

        $translate('COMMON.MESSAGES.SAVE_COMPLETE').then(
          function (message) {
            $scope.CompanySetting.addSdsMailTo.added = message;
          },
          function (translationId) {
            $scope.CompanySetting.addSdsMailTo.added = translationId;
          }
        );
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err));
        $translate('COMMON.MESSAGES.ERR_SAVING').then(
          function (message) {
            $scope.CompanySetting.addSdsMailTo.added = message;
          },
          function (translationId) {
            $scope.CompanySetting.addSdsMailTo.added = translationId;
          }
        );
      }
    );
  };

  vm.colorOnOpen = function (api, color, $event) {
    var colorPickerScope = api.getScope();

    if (!colorPickerScope.AngularColorPickerController.ngModel) {
      colorPickerScope.AngularColorPickerController.ngModel = '#f89406';
    }
  };

  vm.eventApi = {
    onOpen: vm.colorOnOpen
  };

  vm.options = {
    // input attributes
    required: false,
    // validation
    restrictToFormat: false,
    preserveInputFormat: false,
    allowEmpty: false,
    // color
    format: 'hexString',
    case: 'lower',
    // sliders
    hue: true,
    saturation: false,
    lightness: false,
    alpha: false,
    dynamicHue: true,
    dynamicSaturation: true,
    dynamicLightness: true,
    dynamicAlpha: true,
    // picker
    round: false,
    pos: 'bottom left',
    inline: false,
    horizontal: false,
    // swatch
    swatch: true,
    swatchOnly: false,
    swatchPos: 'left',
    swatchBootstrap: false,
    // show/hide events
    show: {
      swatch: true,
      focus: true
    },
    hide: {
      blur: true,
      escape: true,
      click: true
    },
    // buttons
    clear: {
      show: true,
      label: 'Clear',
      class: ''
    },
    reset: {
      show: true,
      label: 'Reset',
      class: ''
    }
  };

  vm.addStorageCode = function () {
    if (!vm.storageCode.title && !vm.storageCode.color) {
      MessagesService.error('COMMON.MESSAGES.FILL_OUT_ONE_OF_FIELD', { fields: 'title, color' });
      return false;
    }

    vm.disableButton = true;
    vm.storageCode.isEnabled = vm.storageCode.isEnabled ? vm.storageCode.isEnabled : false;
    // vm.storageCode.companyId = vm.selectedCompany.id;

    CompanySetting.storageCodes.create({ id: vm.selectedCompany.id }, vm.storageCode).$promise.then(
      function (response) {
        vm.storageCode.companySettingId = response.companySettingId;
        vm.storageCode.id = response.id;
        $scope.CompanySetting.storageCodes.push(vm.storageCode);
        vm.storageCode = {};
        MessagesService.success('COMMON.MESSAGES.SAVE_COMPLETE');
        vm.disableButton = false;
      },
      function (error) {
        MessagesService.error(error.data.error.message);
        vm.disableButton = false;
      }
    );
  };

  vm.removeStorageCode = function (index) {
    vm.disableButton = true;
    var removeCode = $scope.CompanySetting.storageCodes[index];

    if (removeCode && removeCode.companySettingId && removeCode.id) {
      CompanySetting.storageCodes
        .destroyById({ id: removeCode.companySettingId, fk: removeCode.id })
        .$promise.then(
          function (response) {
            MessagesService.success('COMMON.MESSAGES.DELETED');
            $scope.CompanySetting.storageCodes.splice(index, 1);
            vm.disableButton = false;
          },
          function (err) {
            MessagesService.error(err.data.error.message);
            vm.disableButton = false;
          }
        );
    } else {
      MessagesService.error('COMMON.MESSAGES.SOMETHING_WRONG');
      vm.disableButton = false;
    }
  };

  vm.startEditingCode = function (code, editedCode) {
    angular.copy(code, editedCode);
    code.editable = true;
  };

  vm.updateCode = function (code, editedCode, codeType) {
    if (!editedCode.title && !editedCode.color) {
      MessagesService.error('COMMON.MESSAGES.FILL_OUT_ONE_OF_FIELD', { fields: 'title, color' });
      return false;
    }

    if (codeType === 'storage') {
      StorageCodes.prototype$updateAttributes({ id: code.id }, editedCode)
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.UPDATED');
          angular.copy(editedCode, code);
        })
        .catch(simpleCatch);
    } else if (codeType === 'disposal') {
      DisposalCodes.prototype$updateAttributes({ id: code.id }, editedCode)
        .$promise.then(function () {
          MessagesService.success('COMMON.MESSAGES.UPDATED');
          angular.copy(editedCode, code);
        })
        .catch(simpleCatch);
    }
  };

  vm.addDisposalCode = function () {
    if (!vm.disposalCode.title && !vm.disposalCode.color) {
      MessagesService.error('COMMON.MESSAGES.FILL_OUT_ONE_OF_FIELD', { fields: 'title, color' });
      return false;
    }

    vm.disableButton = true;
    vm.disposalCode.isEnabled = vm.disposalCode.isEnabled ? vm.disposalCode.isEnabled : false;

    CompanySetting.disposalCodes
      .create({ id: vm.selectedCompany.id }, vm.disposalCode)
      .$promise.then(
        function (response) {
          vm.disposalCode.companySettingId = response.companySettingId;
          vm.disposalCode.id = response.id;
          $scope.CompanySetting.disposalCodes.push(vm.disposalCode);
          vm.disposalCode = {};
          MessagesService.success('COMMON.MESSAGES.SAVE_COMPLETE');
          vm.disableButton = false;
        },
        function (error) {
          MessagesService.error(error.data.error.message);
          vm.disableButton = false;
        }
      );
  };

  vm.removeDisposalCode = function (index) {
    vm.disableButton = true;
    var removeCode = $scope.CompanySetting.disposalCodes[index];

    if (removeCode && removeCode.companySettingId && removeCode.id) {
      CompanySetting.disposalCodes
        .destroyById({ id: removeCode.companySettingId, fk: removeCode.id })
        .$promise.then(
          function (response) {
            MessagesService.success('COMMON.MESSAGES.DELETED');
            $scope.CompanySetting.disposalCodes.splice(index, 1);
            vm.disableButton = false;
          },
          function (err) {
            MessagesService.error(err.data.error.message);
            vm.disableButton = false;
          }
        );
    } else {
      MessagesService.error('COMMON.MESSAGES.SOMETHING_WRONG');
      vm.disableButton = false;
    }
  };

  $scope.CompanySetting.removeEmail = function (email) {
    $scope.CompanySetting.addSdsMailTo.added = '';
    var removedEmail = {};
    var emailIndex = this.distributionList.indexOf(email);

    removedEmail.distributionList = this.distributionList.slice();
    removedEmail.distributionList.splice(emailIndex, 1);
    removedEmail.id = vm.selectedCompany.id;

    CompanySetting.upsert(
      removedEmail,
      function (updatedCompanySetting) {
        console.log('success');
        $scope.CompanySetting.distributionList = updatedCompanySetting.distributionList;
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err));
      }
    );
  };

  $scope.IPWhitelist = {};
  $scope.IPWhitelist.IPcode = {};

  $scope.IPWhitelist.addIPToWhitelistClicked = function (IPcode, IPmask) {
    var IPobj = {};
    IPobj.ip = IPmask ? IPcode + '/' + IPmask : IPcode;
    IPobj.companyId = vm.selectedCompany.id;

    var filterStr =
      '{ "filter": { "where" : {"ip": "' +
      IPobj.ip +
      '", "companyId": "' +
      IPobj.companyId +
      '"} } }';
    var filterObj = JSON.parse(filterStr);

    IPWhitelist.find(
      filterObj,
      function (IPlist) {
        if (IPlist.length) {
          $translate('SETTINGS.IP_EXISTS').then(
            function (message) {
              $scope.IPWhitelist.IPadded = message;
            },
            function (translationId) {
              $scope.IPWhitelist.IPadded = translationId;
            }
          );
        } else {
          AddIPToWhitelist(IPobj);
        }
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
      }
    );
  };

  $scope.IPWhitelist.removeIP = RemoveIPFromWhitelist;

  $scope.IPWhitelist.changeIPMask = function () {
    if ($scope.IPWhitelist.IPcode.mask > 32) {
      $scope.IPWhitelist.IPcode.mask = 32;
    } else if ($scope.IPWhitelist.IPcode.mask == 0) {
      $scope.IPWhitelist.IPcode.mask = '';
    }
  };

  $scope.toggleRequestSdsByIp = function () {
    var data = {
      requestSdsByIpEnabled: $scope.commonSettings.requestSdsByIpEnabled
    };

    upsertCompanySetting(data);
  };

  $scope.toggleRequestLocationByIp = function () {
    var data = {
      requestLocationByIpEnabled: $scope.commonSettings.requestLocationByIpEnabled
    };

    upsertCompanySetting(data);
  };

  $scope.toggleGroupTagEnabled = function () {
    var data = {
      groupTagEnabled: $scope.commonSettings.groupTagEnabled
    };

    upsertCompanySetting(data);
  };

  $scope.toggleBroaderSearch = function () {
    var data = {
      id: vm.selectedCompany.id,
      broaderSearch: $scope.commonSettings.broaderSearch
    };

    upsertCompanySetting(data);
  };

  vm.toggleOption = $scope.toggleOption = function (optionName) {
    if (!optionName) return;
    if (!checkSettingsForRegion(optionName, $scope.commonSettings[optionName])) return;

    if (optionName === 'labelBatch' && isLabelBatchUndefined()) {
      $scope.commonSettings.labelBatchAccess = vm.roles;
      isLabelBatchUndefined(false);
    }

    const payload = { id: vm.selectedCompany.id };

    if (optionName === 'allowAccessUrl') {
      payload['singleAccessUrl'] =  $scope.commonSettings[optionName] ?
        `https://${$rootScope.schoolInfo.getSchoolDomain(vm.selectedCompany.name)}/?waToken=${crypto.createHmac('sha256', vm.selectedCompany.id + Date.now()).digest('hex')}` :
        '';

      $scope.commonSettings['singleAccessUrl'] = payload['singleAccessUrl'];
    }

    if (optionName === 'password_change') {
      let months = $scope.commonSettings[optionName] ? 1 /* 1 month as default */ : 0;
      payload['password_change_months'] = $scope.commonSettings['password_change_months'] = months;
    }
    if (optionName === 'addSdsFormEnabled' && !$scope.commonSettings[optionName]) {
      payload['requesterFormEnabled'] = $scope.commonSettings['requesterFormEnabled'] = $scope.commonSettings[optionName];
    }
    if (optionName === 'nonRegulatedMessage') {
      $rootScope.languages.forEach(lang => {
        let propName =
          'nonRegulatedMessage' + (lang === $rootScope.majorLanguage ? '' : '_' + lang);
        payload[propName] = $scope.commonSettings[propName];
      });
    }

    payload[optionName] = $scope.commonSettings[optionName];
    return upsertCompanySetting(payload);
  };

  $scope.labelTemplateIsSelected = function (labelTemplate) {
    return (
      $scope.commonSettings.labelTemplates == null ||
      ~$scope.commonSettings.labelTemplates.indexOf(labelTemplate.name)
    );
  };
  $scope.toggleLabelTemplate = function (event, labelTemplate) {
    const name = labelTemplate.name;
    if ($scope.commonSettings.labelTemplates == null) {
      $scope.commonSettings.labelTemplates = [];
    }
    const idx = $scope.commonSettings.labelTemplates.indexOf(name);
    if (~idx) {
      if ($scope.commonSettings.labelTemplates.length == 1) {
        MessagesService.error('There must be at least 1 label template selected');
        return event.preventDefault();
      }
      $scope.commonSettings.labelTemplates.splice(idx, 1);
    } else {
      $scope.commonSettings.labelTemplates.push(name);
    }
    upsertCompanySetting({
      id: vm.selectedCompany.id,
      labelTemplates: $scope.commonSettings.labelTemplates
    });
  };

  $scope.toggleLabelStyle = function (keys, value) {
    const payload = {};

    angular.forEach(angular.isArray(keys) ? keys : [keys], function (key, index) {
      payload[key] = angular.isArray(value) ? value[index] : value;
    });

    return upsertCompanySetting(payload);
  };

  $scope.toggleModule = function (moduleKey = null) {
    const data = {
      modules: deObjectify($scope.commonSettings.modules)
    };

    if (!checkSettingsForRegion('modules', data.modules)) return;

    //delete scheduled jobs due to module disabling
    if (moduleKey && scheduledModules[moduleKey] && !$scope.commonSettings.modules[moduleKey]) {
      vm.deleteModuleScheduler(moduleKey);
      return;
    }

    if (!$scope.commonSettings.modules[moduleNames.hse]) {
      data['inventoryEquipmentEnabled'] = false;
    }

    if (!$scope.commonSettings.modules[moduleNames.inv]) {
      data['inventoryInSearchEnabled'] = false;
    } else {
      $scope.commonSettings.linkSdsToProducts = data['linkSdsToProducts'] = ["teamAdmin"];
    }

    upsertCompanySetting(data).then(function (updatedCompanySetting) {
      $rootScope.companySettings.modules = updatedCompanySetting.modules;

      if (!$scope.commonSettings.modules[moduleNames.hse]) {
        $scope.commonSettings.inventoryEquipmentEnabled = false;
      }
      if (!$scope.commonSettings.modules[moduleNames.inv]) {
        $scope.commonSettings.inventoryInSearchEnabled = false;
      }
    });
  };

  $scope.toggleAvailableLanguage = function (lang) {
    const payload = {};
    if ($scope.commonSettings.companyLanguages.includes(lang)) {
      payload.companyLanguages = $scope.commonSettings.companyLanguages.filter(
        langEl => langEl !== lang
      );
    } else {
      payload.companyLanguages = [...$scope.commonSettings.companyLanguages, lang];
    }
    upsertCompanySetting(payload).then(function (updatedCompanySetting) {
      $rootScope.companySettings.companyLanguages = updatedCompanySetting.companyLanguages;
      $scope.commonSettings.companyLanguages = updatedCompanySetting.companyLanguages;
    });
  };

  /**
   * Delete scheduler jobs for module
   * @param {*} moduleKey Modile key
   */
  vm.deleteModuleScheduler = function (moduleKey) {
    $scope.commonSettings.modules[moduleKey] = !$scope.commonSettings.modules[moduleKey];

    let jobKeys = [];
    (scheduledModules[moduleKey] || []).forEach(scheduledReport => {
      let jobName = get(SchedulerService, `jobs.report.${scheduledReport}`);
      if (jobName) {
        jobKeys.push(jobName);
      }
    });

    const continueUpdate = () => {
      $scope.commonSettings.modules[moduleKey] = !$scope.commonSettings.modules[moduleKey];
      $scope.toggleModule();
    };

    SchedulerService.getReports(vm.selectedCompany.id).then(async res => {
      const moduleJobs = SchedulerService.records.filter(el => jobKeys.includes(el.name));
      if (moduleJobs.length > 0) {
        ModalService.open(confirmMessage('SETTINGS.CONFIRM_SHCEDULER_JOBS_DELETING')).then(() => {
          Promise.all(
            moduleJobs.map(record => SchedulerService.removeReport(record.name, record.id))
          ).then(() => continueUpdate());
        });
      } else {
        continueUpdate();
      }
    });
  };

  vm.toggleFeature = function (featureName) {
    var data = {};

    data[featureName + 'Enabled'] = $scope.commonSettings[featureName + 'Enabled'];
    if (featureName === 'inventoryInSearch' && !data[featureName + 'Enabled']) {
      data['hideTagsWorkflowEnabled'] = $scope.commonSettings['hideTagsWorkflowEnabled'] = false;
    }
    upsertCompanySetting(data);
  };

  vm.updateSettings = function (settingsName) {
    const data = { id: vm.selectedCompany.id };

    data[settingsName] = $scope.commonSettings[settingsName];

    let disableShowOnlyCompanyPPE = false;
    if (
      settingsName === 'enablePPE' &&
      !$scope.commonSettings[settingsName] &&
      !!$scope.commonSettings['showOnlyCompanyPPE']
    ) {
      $scope.commonSettings['showOnlyCompanyPPE'] = false;
      data['showOnlyCompanyPPE'] = false;
      disableShowOnlyCompanyPPE = true;
    }

    if (checkSettingsForRegion(settingsName, data[settingsName])) {
      upsertCompanySetting(data).then(updatedData => {
        //apply changes for client's settings
        if (['bannedListType'].includes(settingsName)) {
          $rootScope.companySettings[settingsName] = updatedData[settingsName];
        }
        if (settingsName === 'showOnlyCompanyPPE' || disableShowOnlyCompanyPPE) {
          SpinnerService.wrap(function () {
            return SolrDocument.applyShowOnlyCompanyPpeSetting({
              companyId: vm.selectedCompany.id,
              showOnlyCompanyPPE: $scope.commonSettings['showOnlyCompanyPPE']
            }).$promise;
          })();
        }
      });
    }
  };

  vm.setReviewPeriod = function (period) {
    var data = {
      id: vm.selectedCompany.id,
      inventoryReviewsPeriod: period
    };

    upsertCompanySetting(data);
  };

  vm.updateSelectedCompany = SpinnerService.wrap(function (data) {
    return updateCompany(vm.selectedCompany.id, data);
  });

  $scope.setFirstPage = function (page) {
    var data = {
      firstPage: page
    };

    // unless we have only portal and search for common users [2/3]
    if (page === true) data.firstPage = 'portal';
    if (page === false) data.firstPage = 'search';

    upsertCompanySetting(data);
  };

  function refreshCompany(companyId) {
    const filter = {
      include: [
        { relation: 'companyType' },
        { relation: 'childSdsCompanies', scope: { fields: ['id', 'name'] } }
      ]
    };

    return Company.findById({ id: companyId, filter }).$promise.then(company => {
      let index = -1;

      vm.companies.some((existedCompany, i) => {
        if (existedCompany.id === company.id) return (index = i);
      });

      if (~index) {
        vm.companies.splice(index, 1, company);
      }

      return company;
    });
  }

  function updateCompany(companyId, data = {}) {
    return Company.prototype$updateAttributes({ id: companyId }, data)
      .$promise.then(function () {
        MessagesService.success('COMMON.MESSAGES.UPDATED');
      })
      .catch(simpleCatch);
  }

  /**
   * Upsert CompanySetting model with data
   *
   * @param {Object} data an object with fields to update
   * @param {boolean} quietUpdate either make the update without success notification or not
   * @returns {Promise} returns a Promise that resolves with updated data
   */
  function upsertCompanySetting(data, quietUpdate) {
    data.id = vm.selectedCompany.id;
    quietUpdate = quietUpdate || false;

    return CompanySetting.upsert(data)
      .$promise.then(function (updatedCompanySetting) {
        if (!quietUpdate) MessagesService.success('COMMON.MESSAGES.UPDATED');

        vm.companyCustomFields = updatedCompanySetting.enabledAttrs || [];

        return updatedCompanySetting;
      })
      .catch(simpleCatch);
  }

  function UpdateLayout(sdsLayout) {
    CompanySetting.upsert(
      sdsLayout,
      function (updatedCompanySetting) {
        console.log('success');
        $translate('COMMON.MESSAGES.SAVE_COMPLETE').then(
          function (message) {
            $scope.CompanySetting.layoutSaved = message;
          },
          function (translationId) {
            $scope.CompanySetting.layoutSaved = translationId;
          }
        );
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err));
        $translate('COMMON.MESSAGES.ERR_SAVING').then(
          function (message) {
            $scope.CompanySetting.layoutSaved = message;
          },
          function (translationId) {
            $scope.CompanySetting.layoutSaved = translationId;
          }
        );
      }
    );
  }

  function GetCompanySettings(companyId, callback) {
    return CompanySetting.findById({
      id: companyId,
      filter: { include: ['storageCodes', 'disposalCodes'] }
    })
      .$promise.then(function (data) {
        $scope.CompanySetting.sdsLayout.id = data.id;
        $scope.CompanySetting.distributionList = data.distributionList || [];
        $scope.CompanySetting.storageCodes = data.storageCodes || [];
        $scope.CompanySetting.disposalCodes = data.disposalCodes || [];

        if (data.sections) {
          $scope.CompanySetting.sdsLayout.sections = data.sections;
        } else {
          initialSections();
        }

        $scope.CompanySetting.borderStyles = data.borderStyles || $scope.borderStyles;

        $rootScope.languages.forEach(lang => {
          let propName =
            'bannedTemplateMessage' + (lang === $rootScope.majorLanguage ? '' : '_' + lang);
          $scope.CompanySetting[propName] = data[propName] || '';
        });

        $scope.commonSettings.nonRegulatedMessage = data.nonRegulatedMessage || '';

        $scope.commonSettings = data;
        $scope.commonSettings.enabledAttrs = objectify(data.enabledAttrs);
        $scope.commonSettings.customFieldsReportPAHSSF = data.customFieldsReportPAHSSF;
        vm.companyCustomFields = Object.keys($scope.commonSettings.enabledAttrs);

        $scope.commonSettings.searchableAttrs = objectify(data.searchableAttrs);
        $scope.commonSettings.uniqueAttrs = objectify(data.uniqueAttrs);
        $scope.commonSettings.printableAttrs = objectify(data.printableAttrs);
        $scope.commonSettings.modules = objectify(data.modules);
        $scope.commonSettings.broaderSearch = data.broaderSearch;
        $scope.commonSettings.azureLogin = data.azureLogin;
        $scope.commonSettings.labelTemplates = data.labelTemplates || [];
        $scope.commonSettings.labelStyle = angular.extend(
          {},
          LabelTemplatesService.defaultLabelStyle,
          data.labelStyle
        );
        $scope.commonSettings.attrs = data.attrs || {};
        $scope.commonSettings.attrsLang = data.attrsLang || {};

        // unless we have only portal and search for common users [3/3]
        $scope.commonSettings.portalFirst = data.firstPage === 'portal';
        $scope.commonSettings.firstPage = data.firstPage;
        $scope.commonSettings.searchPageLinks = data.searchPageLinks || [];
         $scope.addSdsFormSet.forEach(formName => {
          $scope.commonSettings[formName] = data[formName] || [];
        });
        $scope.commonSettings.companyLanguages = data.companyLanguages || ['en'];

        isLabelBatchUndefined = isOptionUndefined(data.labelBatch);
      })
      .catch(function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
        initialSections();
        $scope.CompanySetting.distributionList = [];
        $scope.CompanySetting.storageCodes = [];
        $scope.CompanySetting.disposalCodes = [];
        $scope.CompanySetting.sdsLayout.id = companyId;
        $scope.commonSettings.labelTemplates = [];
      });
  }

  function isOptionUndefined(optionStatus) {
    let status = optionStatus;

    return function (newStatus) {
      if (newStatus != undefined) {
        status = newStatus;
      }

      return status == undefined;
    };
  }

  function AddIPToWhitelist(IPobj) {
    var filterStr = '{ "filter": { "where" : {"companyId":"' + vm.selectedCompany.id + '"} } }';
    var filterObj = JSON.parse(filterStr);

    IPWhitelist.create(
      IPobj,
      function () {
        console.log('success');
        $scope.IPWhitelist.IPcode = {};
        GetWhitelistedIPs(filterObj);
        $translate('COMMON.MESSAGES.SAVE_COMPLETE').then(
          function (message) {
            $scope.IPWhitelist.IPadded = message;
          },
          function (translationId) {
            $scope.IPWhitelist.IPadded = translationId;
          }
        );
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
        $translate('COMMON.MESSAGES.ERR_SAVING').then(
          function (message) {
            $scope.IPWhitelist.IPadded = message;
          },
          function (translationId) {
            $scope.IPWhitelist.IPadded = translationId;
          }
        );
      }
    );
  }

  function GetWhitelistedIPs(obj) {
    $scope.IPWhitelist.IPadded = '';

    return IPWhitelist.find(obj)
      .$promise.then(function (IPlist) {
        $scope.IPWhitelist.whitelistedIPs = IPlist;
      })
      .catch(simpleCatch);
  }

  function RemoveIPFromWhitelist(ip) {
    var filterStr = '{ "filter": { "where" : {"companyId":"' + vm.selectedCompany.id + '"} } }';
    var filterObj = JSON.parse(filterStr);

    IPWhitelist.deleteById(
      ip,
      function (value) {
        console.log('success');
        GetWhitelistedIPs(filterObj);
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
      }
    );
  }

  function GetBannedList(obj) {
    return BannedList.find(obj)
      .$promise.then(function (bannedList) {
        $scope.BannedList.bannedList = bannedList;
      })
      .catch(simpleCatch);
  }

  function RemoveFromBannedList(banned) {
    var companyId = banned.companyId;

    BannedList.deleteById(
      banned,
      function (value) {
        var filterObj = {
          filter: {
            where: {
              companyId: companyId
            }
          }
        };

        console.log('success');
        GetBannedList(filterObj);
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
      }
    );
  }

  function initCompanySettings() {
    const filter = { filter: { where: { companyId: vm.selectedCompany.id } } };

    return Promise.all([
      GetBannedList(filter),
      GetCompanySettings(vm.selectedCompany.id),
      GetWhitelistedIPs(filter)
    ]);
  }

  function getAllCompanies() {
    const filter = {
      filter: {
        include: [
          { relation: 'companyType' },
          { relation: 'childSdsCompanies', scope: { fields: ['id', 'name'] } }
        ]
      }
    };

    return Company.find(filter).$promise.then(companies => {
      vm.companies = companies;
    });
  }

  function getCompanyReminder(companyId) {
    return SchedulerApi.getSdsRequestReminder({ companyId }).$promise.then(reminder => {
      vm.scheduler.schedulerApiStatus = true;

      if (reminder && reminder.repeatInterval) {
        vm.scheduler.openSdsRequestsReminder = true;

        const period = +reminder.repeatInterval.match(/^\d*/)[0];
        vm.scheduler.openSdsRequestsReminderPeriod = period;
      } else {
        vm.scheduler.openSdsRequestsReminder = false;
        vm.scheduler.openSdsRequestsReminderPeriod = 1;
      }
    });
  }

  function checkSettingsForRegion(settingsName, values = []) {
    values = Array.isArray(values) ? values : [values];
    const settings = localeRestrictedSettings[settingsName];

    if (!settings) return true;

    const localeAllowedValues = settings[$rootScope.localVersion.toLowerCase()] || [];

    return values.every(value => {
      if (localeAllowedValues.includes(value)) {
        return true;
      } else {
        const warnMsg = `the settings: ${settingsName} with value: ${value} is restricted in ${$rootScope.localVersion}`;
        console.warn(warnMsg);
        MessagesService.warning(warnMsg);

        return false;
      }
    });
  }

  function init() {
    if ($rootScope.checkIfUserIs(['admin'])) {
      getAllCompanies();
    } else if ($rootScope.checkIfUserIs(['teamAdmin'])) {
      CompanyService.getCurrentCompanyPromise().$promise.then(function () {
        vm.selectedCompany = { id: $rootScope.companyId };

        getCompanyReminder(vm.selectedCompany.id);
        initCompanySettings();
      });
    }
    translateLabels();
  }

  init();

  vm.editBannedEntry = vm.addBannedEntry = function (entry) {
    return ModalService.open({
      keyboard: true,
      backdrop: true,
      controller: 'editBannedEntryCtrl',
      controllerAs: 'ctrl',
      templateUrl: require('../editBannedEntryCtrl/editBannedEntry.html'),
      resolve: {
        entry: function () {
          return angular.extend({ companyId: vm.selectedCompany.id }, entry);
        },
        known: function () {
          return $scope.BannedList.bannedList.map(function (itm) {
            return itm.name;
          });
        },
        companySettings: $scope.commonSettings
      }
    }).then(function (data) {
      return entry ? angular.extend(entry, data) : $scope.BannedList.bannedList.unshift(data);
    });
  };

  vm.removeBannedEntry = function (entry) {
    return ModalService.open({
      templateUrl: 'areYouSure.html'
    }).then(function () {
      RemoveFromBannedList(entry);
    });
  };

  vm.hasAttrs = function (attrs) {
    return !!Object.keys(attrs || {}).length;
  };

  vm.addAttr = function () {
    const attrsByLang = $scope.languages.reduce((all, lang) => {
      all[lang] = { name: '', value: '' };
      return all;
    }, {});

    return ModalService.open({
      keyboard: true,
      backdrop: true,
      controller: 'editAttrCtrl',
      controllerAs: 'ctrl',
      templateUrl: require('../editAttrCtrl/editAttr.html'),
      resolve: {
        isEdit: function () {
          return false;
        },
        attrsByLang: function () {
          return attrsByLang;
        },
        known: function () {
          return Object.keys($scope.commonSettings.attrs);
        }
      }
    }).then(function (data) {
      const attrName = data[$scope.majorLanguage].name;
      const attrValue = data[$scope.majorLanguage].value;

      $scope.commonSettings.enabledAttrs[attrName] = true;
      $scope.commonSettings.searchableAttrs[attrName] = true;
      $scope.commonSettings.attrs[attrName] = attrValue || '';
      $scope.commonSettings.attrsLang[attrName] = data || '';

      return $scope
        .toggleLabelStyle(
          ['attrs.' + attrName, 'attrsLang.' + attrName, 'enabledAttrs', 'searchableAttrs'],
          [
            attrValue,
            data,
            deObjectify($scope.commonSettings.enabledAttrs),
            deObjectify($scope.commonSettings.searchableAttrs)
          ]
        )
        .then(() => {
          $rootScope.companySettings.attrsLang = clone($scope.commonSettings.attrsLang);
        });
    });
  };

  /**
   * Sort attributes data by language
   *
   * @param {String} attrName
   * @returns {Object}
   */
  vm.getAttrsByLang = function (attrName) {
    let attrs = {
      [$scope.majorLanguage]: {
        name: attrName,
        value: $scope.commonSettings.attrs[attrName]
      }
    };
    $scope.languages.forEach(lang => {
      if (lang !== $scope.majorLanguage) {
        const attrsLang = get($scope.commonSettings, `attrsLang.${attrName}.${lang}`, null);
        if (attrsLang) {
          attrs[lang] = attrsLang;
        } else {
          attrs[lang] = { name: '', value: '' };
        }
      }
    });
    return attrs;
  };

  vm.editAttr = function (originalName) {
    const attrsByLang = vm.getAttrsByLang(originalName);
    return ModalService.open({
      keyboard: true,
      backdrop: true,
      controller: 'editAttrCtrl',
      controllerAs: 'ctrl',
      templateUrl: require('../editAttrCtrl/editAttr.html'),
      resolve: {
        isEdit: function () {
          return true;
        },
        attrsByLang: function () {
          return attrsByLang;
        },
        known: function () {
          return Object.keys($scope.commonSettings.attrs).filter(value => value !== originalName);
        }
      }
    }).then(function (data) {
      const attrName = data[$scope.majorLanguage].name;
      const attrValue = data[$scope.majorLanguage].value;

      const enabledAttrs = angular.copy($scope.commonSettings.enabledAttrs);
      const searchableAttrs = angular.copy($scope.commonSettings.searchableAttrs);
      const uniqueAttrs = angular.copy($scope.commonSettings.uniqueAttrs);
      const printableAttrs = angular.copy($scope.commonSettings.printableAttrs);

      if (attrName !== originalName) {
        enabledAttrs[attrName] = enabledAttrs[originalName] || false;
        searchableAttrs[attrName] = searchableAttrs[originalName] || false;
        uniqueAttrs[attrName] = uniqueAttrs[originalName] || false;
        printableAttrs[attrName] = printableAttrs[originalName] || false;

        delete enabledAttrs[originalName];
        delete searchableAttrs[originalName];
        delete uniqueAttrs[originalName];
        delete printableAttrs[originalName];
      }

      const updatedAttrs = Object.keys($scope.commonSettings.attrs).reduce(function (obj, name) {
        if (name !== originalName) {
          obj[name] = $scope.commonSettings.attrs[name];
        } else {
          obj[attrName] = attrValue;
        }

        return obj;
      }, {});

      let promise = $q.resolve();

      if (attrName !== originalName) {
        promise = CompanySetting.renameAttrs(
          {
            oldAttrName: originalName,
            newAttrName: attrName,
            id: vm.selectedCompany.id
          },
          {}
        ).$promise;
      }

      const attrsLang = angular.copy($scope.commonSettings.attrsLang);

      delete attrsLang[originalName];

      attrsLang[attrName] = data;

      return promise.then(function () {
        return $scope
          .toggleLabelStyle(
            [
              'attrs',
              'attrsLang',
              'enabledAttrs',
              'searchableAttrs',
              'uniqueAttrs',
              'printableAttrs'
            ],
            [
              updatedAttrs,
              attrsLang,
              deObjectify(enabledAttrs),
              deObjectify(searchableAttrs),
              deObjectify(uniqueAttrs),
              deObjectify(printableAttrs)
            ]
          )
          .then(function () {
            $scope.commonSettings.attrs = updatedAttrs;
            $rootScope.companySettings.attrsLang = $scope.commonSettings.attrsLang = attrsLang;
            $scope.commonSettings.enabledAttrs = enabledAttrs;
            $scope.commonSettings.searchableAttrs = searchableAttrs;
            $scope.commonSettings.uniqueAttrs = uniqueAttrs;
            $scope.commonSettings.printableAttrs = printableAttrs;
          });
      });
    });
  };

  vm.removeAttr = function (name) {
    return ModalService.open({
      templateUrl: 'areYouSure.html'
    })
      .then(function () {
        delete $scope.commonSettings.enabledAttrs[name];
        delete $scope.commonSettings.searchableAttrs[name];
        delete $scope.commonSettings.uniqueAttrs[name];
        delete $scope.commonSettings.printableAttrs[name];
        delete $scope.commonSettings.printableAttrs[name];
        delete $scope.commonSettings.attrs[name];
        delete $scope.commonSettings.attrsLang[name];

        return $scope.toggleLabelStyle(
          [
            'attrs',
            'attrsLang',
            'enabledAttrs',
            'searchableAttrs',
            'uniqueAttrs',
            'printableAttrs'
          ],
          [
            $scope.commonSettings.attrs,
            $scope.commonSettings.attrsLang,
            deObjectify($scope.commonSettings.enabledAttrs),
            deObjectify($scope.commonSettings.searchableAttrs),
            deObjectify($scope.commonSettings.uniqueAttrs),
            deObjectify($scope.commonSettings.printableAttrs)
          ]
        );
      })
      .then(
        SpinnerService.wrap(function () {
          return SolrDocument.removeCompanyAttr({
            companyId: vm.selectedCompany.id,
            name: name
          }).$promise;
        })
      );
  };

  vm.toggleAttr = function (type) {
    $scope.toggleLabelStyle([type], [deObjectify($scope.commonSettings[type])]);
  };

  vm.addEmailToNotifyList = function (email, listName = 'notifyOnSdsUpdatesList') {
    if (isInArray($scope.commonSettings[listName], email)) {
      return MessagesService.error('SETTINGS.EMAIL_EXISTS');
    }

    $scope.commonSettings[listName] = $scope.commonSettings[listName] || [];

    $scope.commonSettings[listName].push(email);
    $scope.toggleOption(listName).then(function () {
      vm[listName] = '';
    });
  };

  vm.removeEmailFromNotifyList = function (email, listName = 'notifyOnSdsUpdatesList') {
    $scope.commonSettings[listName] = $scope.commonSettings[listName].filter(function (el) {
      return el !== email;
    });

    $scope.toggleOption(listName);
  };

  vm.addSearchPageLink = function (name, url) {
    $scope.commonSettings.searchPageLinks.push({ name, url });
    vm.searchPageLinksChanged = true;
  };

  vm.removeSearchPageLink = function (index) {
    $scope.commonSettings.searchPageLinks.splice(index, 1);
    vm.searchPageLinksChanged = true;
  };

  vm.updateSearchPageLink = function () {
    const data = {
      searchPageLinks: $scope.commonSettings.searchPageLinks
    };

    upsertCompanySetting(data);

    //update in global settings
    $rootScope.companySettings.searchPageLinks = $scope.commonSettings.searchPageLinks;
  };

  vm.addRequestField = function (formType, type, values = []) {
    if (type === 'dropdown' && !vm[`${formType}Field`].tagsWorkflow
      && !vm[`${formType}Field`].inventoryWorkflow) {
      vm[`${formType}Field`].values = [...values];
    }

    if (
      type === 'dropdown' &&
      vm[`${formType}Field`].tagsWorkflow &&
      $scope.commonSettings[formType].some(field => field.tagsWorkflow)
    ) {
      return MessagesService.warning(`There can only be one dropdown with tags`);
    }

    if (
      type === 'dropdown' &&
      vm[`${formType}Field`].inventoryWorkflow &&
      $scope.commonSettings[formType].some(field => field.inventoryWorkflow)
    ) {
      return MessagesService.warning(`There can only be one dropdown with inventory`);
    }

    $scope.commonSettings[formType].push({ ...vm[`${formType}Field`] });

    vm[`${formType}Field`] = { ...defaultAddSdsFormField };
  };

  vm.removeRequestField = function (formType, index) {
    $scope.commonSettings[formType].splice(index, 1)
  };

  vm.updateAddSdsForm = function (formType) {
    const data = {
      [formType]: $scope.commonSettings[formType]
    };

    upsertCompanySetting(data);
  };

  vm.updateOpenSdsRequestsReminder = SpinnerService.wrap(function (isEnabled, repeatEvery) {
    if (isEnabled == null || repeatEvery == null) return Promise.resolve();

    const companyId = vm.selectedCompany.id;
    const period = 'days';
    let promise = Promise.resolve();

    if (isEnabled) {
      promise = SchedulerApi.upsertSdsRequestReminder(
        { companyId, repeatEvery, period },
        {}
      ).$promise;
    } else {
      promise = SchedulerApi.removeSdsRequestReminder({ companyId }).$promise;
    }

    return promise
      .then(() => MessagesService.success('COMMON.MESSAGES.UPDATED'))
      .catch(err => {
        if (isEnabled) {
          vm.scheduler.openSdsRequestsReminder = false;
        } else {
          vm.scheduler.openSdsRequestsReminder = true;
        }

        if (err.data.error.code === 'ECONNREFUSED') {
          console.error(err);
          return MessagesService.error('COMMON.ERROR.ECONNREFUSED', { service: 'Scheduler' });
        }

        return simpleCatch(err);
      });
  });

  vm.updateOpenSdsRequestsReminderPeriod = SpinnerService.wrap(function (
    repeatEvery,
    prevRepeatEvery
  ) {
    if (repeatEvery == null) return Promise.resolve();

    const companyId = vm.selectedCompany.id;
    const period = 'days';

    return SchedulerApi.upsertSdsRequestReminder({ companyId, repeatEvery, period }, {})
      .$promise.then(() => MessagesService.success('COMMON.MESSAGES.UPDATED'))
      .catch(err => {
        vm.scheduler.openSdsRequestsReminderPeriod = prevRepeatEvery;

        if (err.data.error.code === 'ECONNREFUSED') {
          console.error(err);
          return MessagesService.error('COMMON.ERROR.ECONNREFUSED', { service: 'scheduler' });
        }

        return simpleCatch(err);
      });
  });

  vm.changeCompanyDefaultLanguage = function () {
    if (!$scope.commonSettings.defaultLanguage) {
      $scope.commonSettings.defaultLanguage = $rootScope.defaultLanguage;
    }
    const data = {
      defaultLanguage: $scope.commonSettings.defaultLanguage
    };

    upsertCompanySetting(data);
  };
}
