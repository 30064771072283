// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' &&
  module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = "lbServices";
}

(function(window, angular, undefined) {
  'use strict';

  var urlBase = "/api";
  var authHeader = 'authorization';

  function getHost(url) {
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }

  // need to use the urlBase as the base to handle multiple
  // loopback servers behind a proxy/gateway where the host
  // would be the same.
  var urlBaseHost = getHost(urlBase) ? urlBase : location.host;

/**
 * @ngdoc overview
 * @name lbServices
 * @module
 * @description
 *
 * The `lbServices` module provides services for interacting with
 * the models exposed by the LoopBack server via the REST API.
 *
 */
  var module = angular.module("lbServices", ['ngResource']);

/**
 * @ngdoc object
 * @name lbServices.RoleMapping
 * @header lbServices.RoleMapping
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RoleMapping` model.
 *
 * **Details**
 *
 * Map principals to roles
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RoleMapping",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/RoleMappings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use RoleMapping.role() instead.
            "prototype$__get__role": {
              url: urlBase + "/RoleMappings/:id/role",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#create
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/RoleMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#createMany
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/RoleMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#upsert
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/RoleMappings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#exists
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/RoleMappings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#findById
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/RoleMappings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#find
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/RoleMappings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#findOne
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/RoleMappings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#updateAll
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/RoleMappings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#deleteById
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/RoleMappings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#count
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/RoleMappings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#prototype$updateAttributes
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/RoleMappings/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#createChangeStream
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/RoleMappings/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Role.principals.findById() instead.
            "::findById::Role::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.destroyById() instead.
            "::destroyById::Role::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Role.principals.updateById() instead.
            "::updateById::Role::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Role.principals() instead.
            "::get::Role::principals": {
              isArray: true,
              url: urlBase + "/Roles/:id/principals",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.create() instead.
            "::create::Role::principals": {
              url: urlBase + "/Roles/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Role.principals.createMany() instead.
            "::createMany::Role::principals": {
              isArray: true,
              url: urlBase + "/Roles/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Role.principals.destroyAll() instead.
            "::delete::Role::principals": {
              url: urlBase + "/Roles/:id/principals",
              method: "DELETE",
            },

            // INTERNAL. Use Role.principals.count() instead.
            "::count::Role::principals": {
              url: urlBase + "/Roles/:id/principals/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#updateOrCreate
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#update
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#destroyById
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#removeById
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.RoleMapping#modelName
        * @propertyOf lbServices.RoleMapping
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RoleMapping`.
        */
        R.modelName = "RoleMapping";


            /**
             * @ngdoc method
             * @name lbServices.RoleMapping#role
             * @methodOf lbServices.RoleMapping
             *
             * @description
             *
             * Fetches belongsTo relation role.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R.role = function() {
          var TargetResource = $injector.get("Role");
          var action = TargetResource["::get::RoleMapping::role"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Role
 * @header lbServices.Role
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Role` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Role",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Roles/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Role.principals.findById() instead.
            "prototype$__findById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.destroyById() instead.
            "prototype$__destroyById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Role.principals.updateById() instead.
            "prototype$__updateById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Role.principals() instead.
            "prototype$__get__principals": {
              isArray: true,
              url: urlBase + "/Roles/:id/principals",
              method: "GET",
            },

            // INTERNAL. Use Role.principals.create() instead.
            "prototype$__create__principals": {
              url: urlBase + "/Roles/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Role.principals.destroyAll() instead.
            "prototype$__delete__principals": {
              url: urlBase + "/Roles/:id/principals",
              method: "DELETE",
            },

            // INTERNAL. Use Role.principals.count() instead.
            "prototype$__count__principals": {
              url: urlBase + "/Roles/:id/principals/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#create
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Roles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#createMany
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Roles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#upsert
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Roles",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#exists
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Roles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#findById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Roles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#find
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Roles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#findOne
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Roles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#updateAll
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Roles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#deleteById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Roles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#count
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Roles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#prototype$updateAttributes
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Roles/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#createChangeStream
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Roles/change-stream",
              method: "POST",
            },

            // INTERNAL. Use RoleMapping.role() instead.
            "::get::RoleMapping::role": {
              url: urlBase + "/RoleMappings/:id/role",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Role#updateOrCreate
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Role#update
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Role#destroyById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Role#removeById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Role#modelName
        * @propertyOf lbServices.Role
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Role`.
        */
        R.modelName = "Role";

    /**
     * @ngdoc object
     * @name lbServices.Role.principals
     * @header lbServices.Role.principals
     * @object
     * @description
     *
     * The object `Role.principals` groups methods
     * manipulating `RoleMapping` instances related to `Role`.
     *
     * Call {@link lbServices.Role#principals Role.principals()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Role#principals
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Queries principals of Role.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
        R.principals = function() {
          var TargetResource = $injector.get("RoleMapping");
          var action = TargetResource["::get::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#count
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Counts principals of Role.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.principals.count = function() {
          var TargetResource = $injector.get("RoleMapping");
          var action = TargetResource["::count::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#create
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Creates a new instance in principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
        R.principals.create = function() {
          var TargetResource = $injector.get("RoleMapping");
          var action = TargetResource["::create::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#createMany
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Creates a new instance in principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
        R.principals.createMany = function() {
          var TargetResource = $injector.get("RoleMapping");
          var action = TargetResource["::createMany::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#destroyAll
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Deletes all principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.principals.destroyAll = function() {
          var TargetResource = $injector.get("RoleMapping");
          var action = TargetResource["::delete::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#destroyById
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Delete a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.principals.destroyById = function() {
          var TargetResource = $injector.get("RoleMapping");
          var action = TargetResource["::destroyById::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#findById
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Find a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
        R.principals.findById = function() {
          var TargetResource = $injector.get("RoleMapping");
          var action = TargetResource["::findById::Role::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Role.principals#updateById
             * @methodOf lbServices.Role.principals
             *
             * @description
             *
             * Update a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RoleMapping` object.)
             * </em>
             */
        R.principals.updateById = function() {
          var TargetResource = $injector.get("RoleMapping");
          var action = TargetResource["::updateById::Role::principals"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Container
 * @header lbServices.Container
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Container` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Container",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/containers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Container#getContainers
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getContainers": {
              isArray: true,
              url: urlBase + "/containers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#createContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "createContainer": {
              url: urlBase + "/containers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#destroyContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "destroyContainer": {
              url: urlBase + "/containers/:container",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getContainer
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getContainer": {
              url: urlBase + "/containers/:container",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getFiles
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getFiles": {
              isArray: true,
              url: urlBase + "/containers/:container/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#getFile
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Container` object.)
             * </em>
             */
            "getFile": {
              url: urlBase + "/containers/:container/files/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#removeFile
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "removeFile": {
              url: urlBase + "/containers/:container/files/:file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#upload
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `result` – `{object=}` -
             */
            "upload": {
              url: urlBase + "/containers/:container/upload",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#download
             * @methodOf lbServices.Container
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "download": {
              url: urlBase + "/containers/:container/download/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#fileExists
             * @methodOf lbServices.Container
             *
             * @description
             *
             * Check whether file exists
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` -
             *
             *  - `file` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "fileExists": {
              url: urlBase + "/containers/:container/:file/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#uploadFile
             * @methodOf lbServices.Container
             *
             * @description
             *
             * Upload a file to S3 and local file system by container name and request parameter
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `containerName` – `{string}` - container name
             *
             *  - `uniqName` – `{boolean=}` - whether to make the file name unique
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * the uploaded file info
             */
            "uploadFile": {
              url: urlBase + "/containers/:containerName/uploadFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Container#uploadContainerToS3
             * @methodOf lbServices.Container
             *
             * @description
             *
             * Upload all files from given container to S3 bucket defined by the environment variable
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `containerName` – `{string}` - container name
             *
             *  - `perTime` – `{number=}` - number of files to split uploading to (by default is 50)
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * number of files were uploaded
             */
            "uploadContainerToS3": {
              url: urlBase + "/containers/:containerName/uploadAllFiles",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Container#modelName
        * @propertyOf lbServices.Container
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Container`.
        */
        R.modelName = "Container";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SolrDocument
 * @header lbServices.SolrDocument
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SolrDocument` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SolrDocument",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SolrDocuments/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#_select
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `queryStr` – `{string=}` -
             *
             *  - `filters` – `{string=}` -
             *
             *  - `limit` – `{number=}` -
             *
             *  - `offset` – `{number=}` -
             *
             *  - `fieldList` – `{string=}` -
             *
             *  - `sort` – `{string=}` -
             *
             *  - `facet` – `{boolean=}` -
             *
             *  - `facetField` – `{string=}` -
             *
             *  - `facetMincount` – `{number=}` -
             *
             *  - `facetLimit` – `{number=}` -
             *
             *  - `frVersionQ` – `{string=}` -
             *
             *  - `enVersionQ` – `{string=}` -
             *
             *  - `esVersionQ` – `{string=}` -
             *
             *  - `synonymsMatchQ` – `{string=}` -
             *
             *  - `legislationQ` – `{string=}` -
             *
             *  - `hl` – `{boolean=}` -
             *
             *  - `hlFl` – `{string=}` -
             *
             *  - `facetQuery` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "_select": {
              url: urlBase + "/SolrDocuments/_select",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#_findProducts
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `q` – `{string=}` -
             *
             *  - `fq` – `{string=}` -
             *
             *  - `fl` – `{string=}` -
             *
             *  - `start` – `{string=}` -
             *
             *  - `rows` – `{string=}` -
             *
             *  - `sort` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "_findProducts": {
              url: urlBase + "/SolrDocuments/_findProducts",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#find
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SolrDocuments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#deleteById
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SolrDocuments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#createChangeStream
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SolrDocuments/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#parseUpload
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * parse pdf
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` - multipart/form-data with file
             *
             *  - `template` – `{string=}` - Template to use for parsing
             *
             *  - `container` – `{string=}` - Name of the container where to upload file
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `doc` – `{object=}` -
             */
            "parseUpload": {
              url: urlBase + "/SolrDocuments/parse",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#changeById
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * update with extra logic
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` - id of the document to update
             *
             *  - `data` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `doc` – `{object=}` -
             */
            "changeById": {
              url: urlBase + "/SolrDocuments/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#export
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Export search results
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `params` – `{object=}` - search params
             *
             *  - `hostname` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `filename` – `{string=}` -
             */
            "export": {
              url: urlBase + "/SolrDocuments/export",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#printQRCodes
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Generate PDF file with SDS QR codes
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `params` – `{object=}` - search params
             *
             *  - `hostname` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `filename` – `{string=}` -
             */
            "printQRCodes": {
              url: urlBase + "/SolrDocuments/printQRCodes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#print
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Combine PDFs from search results
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `params` – `{object=}` - search params
             *
             *  - `hostname` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `filename` – `{string=}` -
             */
            "print": {
              url: urlBase + "/SolrDocuments/print",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#printSds
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * opens a page with SDS PDF prepared for printing
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - SDS id
             *
             *  - `companyId` – `{string}` - Company id
             *
             *  - `res` – `{object}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "printSds": {
              url: urlBase + "/SolrDocuments/printSds",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#addLink
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Add document(s) to list of previous versions of another one
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` - id of a document to add link for
             *
             *  - `previousVersionId` – `{string}` - id of a document to add as a link
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `versions` – `{*=}` - All versions of the document
             */
            "addLink": {
              url: urlBase + "/SolrDocuments/:id/link",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#unlink
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Remove document from link chain
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - document to unlink
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `previousVersionId` – `{string=}` - id of a new previous version, if any
             */
            "unlink": {
              url: urlBase + "/SolrDocuments/:id/unlink",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#findByCheckedDate
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Find documents by checked date
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` - Company id
             *
             *  - `day` – `{string}` - Date of the day
             *
             *  - `span` – `{string=}` - either "before" or "after"
             *
             *  - `msdsFormat` – `{*=}` - Only documents in MSDS format, true/false/null
             *
             *  - `tag` – `{*=}` - Universal tags
             *
             *  - `limit` – `{number=}` - limit
             *
             *  - `offset` – `{number=}` - offset
             *
             *  - `availableLanguages` – `{*=}` - to filter SDSs by available languages
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "findByCheckedDate": {
              isArray: true,
              url: urlBase + "/SolrDocuments/findByCheckedDate",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#countByCheckedDate
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Count documents by checked date
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` - Company id
             *
             *  - `day` – `{string}` - Date of the day
             *
             *  - `span` – `{string=}` - either "before" or "after"
             *
             *  - `msdsFormat` – `{*=}` - Only documents in MSDS format, true/false/null
             *
             *  - `tag` – `{*=}` - Universal tags
             *
             *  - `availableLanguages` – `{*=}` - to filter SDSs by available languages
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "countByCheckedDate": {
              url: urlBase + "/SolrDocuments/countByCheckedDate",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#setTags
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Set tags
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` - Document id
             *
             *  - `companyId` – `{string}` - Company id
             *
             *  - `ids` – `{*}` - array of tag ids
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Set ids
             */
            "setTags": {
              isArray: true,
              url: urlBase + "/SolrDocuments/:id/tags",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#archiveFile
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Upload new file to be archived
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` - Document to link to
             *
             *  - `req` – `{object=}` - multipart/form-data with file
             *
             *  - `template` – `{string=}` - Template to use for parsing
             *
             *  - `container` – `{string=}` - Name of the container where to upload file
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `doc` – `{object=}` -
             */
            "archiveFile": {
              url: urlBase + "/SolrDocuments/:id/archiveFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getPreviousVersions
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * List previous versions
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - document id
             *
             *  - `fields` – `{string=}` - fields to retrieve
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `previousVersions` – `{*=}` -
             */
            "getPreviousVersions": {
              url: urlBase + "/SolrDocuments/:id/previousVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getVersions
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * List all versions
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - document id
             *
             *  - `fields` – `{string=}` - fields to retrieve
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "getVersions": {
              isArray: true,
              url: urlBase + "/SolrDocuments/:id/versions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#setNewVersion
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Kinda replace document with new version
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `currentId` – `{string}` - id of current document
             *
             *  - `newId` – `{string}` - id of replacement
             *
             *  - `copy` – `{boolean=}` - copy data from current version
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "setNewVersion": {
              url: urlBase + "/SolrDocuments/newVersion",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#findByIdList
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * get a list of documents by ids
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `idList` – `{string}` - document id list ex//id:doc1 OR id:doc3
             *
             *  - `fieldList` – `{string=}` - field list ex//id,chemicalFriendlyName (can be comma or space seperated)
             *
             *  - `start` – `{number=}` - record to start from
             *
             *  - `rows` – `{number=}` - number of records to return
             *
             *  - `filter` – `{string=}` - filter query ex//isVerified:true,another:foo
             *
             *  - `sort` – `{string=}` - sort query ex//score desc, id asc
             *
             *  - `details` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "findByIdList": {
              url: urlBase + "/SolrDocuments/findByIdList",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#CERS
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * get data for CERS report
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "CERS": {
              isArray: true,
              url: urlBase + "/SolrDocuments/CERS",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#beforeGenerateCERS
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * before generate CERS
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `option` – `{object}` - options for CERS
             *
             *  - `hostname` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "beforeGenerateCERS": {
              isArray: true,
              url: urlBase + "/SolrDocuments/beforeGenerateCERS",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#filterDocuments
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * filter documents
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `filter` – `{*}` - filter for documents
             *
             *  - `fields` – `{string=}` - fields
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "filterDocuments": {
              url: urlBase + "/SolrDocuments/filterDocuments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#mappedDocumentsToQuery
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * returns a query with mapped documents
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `names` – `{*}` - names for filter for documents
             *
             *  - `filter` – `{*}` - filter for documents
             *
             *  - `fields` – `{string=}` - fields
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "mappedDocumentsToQuery": {
              isArray: true,
              url: urlBase + "/SolrDocuments/mappedDocumentsToQuery",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#removeCompanyTag
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * remove company tag from all documents
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - company id ex// 578519337c1135ec1e4a00c7
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "removeCompanyTag": {
              url: urlBase + "/SolrDocuments/removeCompanyTag",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#search
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * get a list of documents
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `searchParams` – `{[Object]}` - an array of objects with search parameters
             *   e.g. [{"searchKey": "chemicalFriendlyName", "searchTerms": "Acetic Acid"}]
             *
             *  - `companyId` – `{String=}` - a company Id
             *
             *  - `fieldList` – `{String=}` - a field list e.g. "id, chemicalFriendlyName" (can be comma or space seperated)
             *
             *  - `start` – `{number=}` - record to start from
             *
             *  - `rows` – `{number=}` - number of records to return
             *
             *  - `filter` – `{string=}` - filter query e.g. "isVerified:true, another:foo"
             *
             *  - `sort` – `{string=}` - sort response e.g. "chemicalFriendlyName asc, id desc"
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of SDS documents
             */
            "search": {
              url: urlBase + "/SolrDocuments/search",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#advancedSearch
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * get a list of documents by advancedSearch search
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `searchParams` – `{string}` - document id list ex//[{"searchKey": "content","searchTerms": "acetic acid, wd-40"}, {     "searchKey": "language",    "searchTerms": "en" }]
             *
             *  - `companyId` – `{string=}` - company Id ex//55a676db6c393cf021e722c8
             *
             *  - `returnFields` – `{string=}` - field list ex//id,chemicalFriendlyName (can be comma or space seperated)
             *
             *  - `start` – `{number=}` - record to start from
             *
             *  - `rows` – `{number=}` - number of records to return
             *
             *  - `filter` – `{string=}` - filter query ex//isVerified:true,another:foo
             *
             *  - `hlFl` – `{string=}` - specifies a list of fields to highlight
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "advancedSearch": {
              url: urlBase + "/SolrDocuments/advancedSearch",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#removeDuplicates
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * remove duplicates
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `searchParams` – `{string=}` - search string, by default it is "chemicalFriendlyName:*"
             *
             *  - `start` – `{number=}` - record to start from, by default it is 0
             *
             *  - `rows` – `{number}` - number of records to work on
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "removeDuplicates": {
              url: urlBase + "/SolrDocuments/removeDuplicates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#setSynonyms
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * set synonyms from product_and_company_identification field
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `searchParams` – `{string=}` - search string, by default it is "*:*"
             *
             *  - `start` – `{number=}` - record to start from, by default it is 0
             *
             *  - `rows` – `{number}` - number of records to work on
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "setSynonyms": {
              url: urlBase + "/SolrDocuments/setSynonyms",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#addDocument
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Add a document to solr and get the content.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` - the container
             *
             *  - `file` – `{string}` - name of the file
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "addDocument": {
              url: urlBase + "/SolrDocuments/addDocument",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#checkDocument
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Check if document already exists.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` - the container
             *
             *  - `file` – `{string}` - name of the file
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "checkDocument": {
              url: urlBase + "/SolrDocuments/checkDocument",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#extractDocumentContent
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * extract document content using solr.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` - the container
             *
             *  - `file` – `{string}` - name of the file
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "extractDocumentContent": {
              url: urlBase + "/SolrDocuments/extractDocumentContent",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#extractContent
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * extract document content without using solr.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string}` - the container
             *
             *  - `file` – `{string}` - name of the file
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "extractContent": {
              url: urlBase + "/SolrDocuments/extractContent",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#update
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Update a document in solr.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{string}` - the data ex// [{"id":"doc1","content":"maybe"}]
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `resp` – `{object=}` -
             */
            "update": {
              url: urlBase + "/SolrDocuments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getDocumentsByQRCode
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * get a list of solr documents by QRCode id
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `qrcode` – `{string}` - qrcode id ex//55c9160291a062d823c37646
             *
             *  - `fieldList` – `{string=}` - field list ex//id,chemicalFriendlyName (can be comma or space seperated)
             *
             *  - `showHiddenEquipmentDocuments` – `{boolean=}` - determines whether to filter the equipment hidden documents, it's false by default
             *
             *  - `availableLanguages` – `{*=}` - to filter SDSs by available languages
             *
             *  - `preferredLanguage` – `{string=}` - to filter SDSs by language
             *
             *  - `skipEncodingNames` – `{boolean=}` - encode Sds name in output
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "getDocumentsByQRCode": {
              url: urlBase + "/SolrDocuments/getDocumentsByQRCode",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#findSds
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Generic search enpoint
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `findSdsById` – `{boolean=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `params` – `{object=}` - search params
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "findSds": {
              url: urlBase + "/SolrDocuments/findSds",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#recheckMirrorSDS
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * recheck mirror SDSs
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "recheckMirrorSDS": {
              url: urlBase + "/SolrDocuments/recheckMirrorSDS",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#recheckChemicalName
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * recheck chemical name for mirror SDSs
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "recheckChemicalName": {
              url: urlBase + "/SolrDocuments/recheckChemicalName",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#addLangLink
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `langVerId` – `{string}` -
             *
             *  - `lang` – `{string}` - en/fr
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `doc` – `{object=}` -
             */
            "addLangLink": {
              url: urlBase + "/SolrDocuments/:id/lang",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#removeLangLink
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `lang` – `{string}` - Remove language link
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `doc` – `{object=}` -
             */
            "removeLangLink": {
              url: urlBase + "/SolrDocuments/:id/lang/:lang",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#propagateToLinked
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `copyFields` – `{*=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `doc` – `{object=}` -
             */
            "propagateToLinked": {
              url: urlBase + "/SolrDocuments/propagate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#removeCompanyAttr
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `name` – `{string}` - attr name
             *
             *  - `value` – `{string=}` - attr value
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "removeCompanyAttr": {
              url: urlBase + "/SolrDocuments/removeCompanyAttr",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#setCompanyAttrs
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `companyId` – `{string}` -
             *
             *  - `attrs` – `{object}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `attrs` – `{object=}` -
             */
            "setCompanyAttrs": {
              url: urlBase + "/SolrDocuments/:id/companyAttrs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getCompanyAttrs
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `companyId` – `{string}` -
             *
             *  - `includeAllAtrrs` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `attrs` – `{object=}` -
             */
            "getCompanyAttrs": {
              url: urlBase + "/SolrDocuments/:id/companyAttrs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#setAttrs
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `attrs` – `{object}` -
             *
             *  - `withPropagation` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `attrs` – `{object=}` -
             */
            "setAttrs": {
              url: urlBase + "/SolrDocuments/:id/attrs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getAttrs
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `attrs` – `{object=}` -
             */
            "getAttrs": {
              url: urlBase + "/SolrDocuments/:id/attrs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getKit
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `fields` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `docs` – `{*=}` -
             */
            "getKit": {
              url: urlBase + "/SolrDocuments/:id/kit",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#mergeData
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * combine companies/tags from both (parent & child) and set to parent
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id1` – `{string}` - ID of parent SDS
             *
             *  - `id2` – `{string}` - ID of child SDS
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{object=}` -
             */
            "mergeData": {
              url: urlBase + "/SolrDocuments/merge/:id1/:id2",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#notify
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * send emails to recipients to notify about updates with SDSs
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `actionName` – `{string}` - see "SolrDocument.notify.templates"
             *
             * @param {Object} postData Request data.
             *
             *  - `companyIds` – `{*}` - companies IDs whose recipient must be notified (if they have the option enabled
             *             in settings and have email to notify)
             *
             *  - `sdsIds` – `{*}` - sds IDs that has been affected
             *
             *  - `notifyListName` – `{string=}` -
             *
             *  - `additionalOptions` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `data` – `{*=}` -
             */
            "notify": {
              url: urlBase + "/SolrDocuments/notify",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#updateRestrictedNote
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `text` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `doc` – `{object=}` -
             */
            "updateRestrictedNote": {
              url: urlBase + "/SolrDocuments/:id/restrictedNote",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getNotUniqueAttrs
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Returns an array of not unique attribute name from the given attrs
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `attrs` – `{object}` -
             *
             *  - `companyId` – `{string}` -
             *
             *  - `sdsId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `notUniqueAttrs` – `{string=}` -
             */
            "getNotUniqueAttrs": {
              url: urlBase + "/SolrDocuments/notUniqueAttrs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#filterSdsBy
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Search SDSs by some filter like "tag" or "inventory"
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `filterBy` – `{string=}` -
             *
             *  - `filterId` – `{string=}` -
             *
             *  - `offset` – `{number=}` - offset
             *
             *  - `limit` – `{number=}` - limit
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `companyId` – `{string}` -
             *
             *  - `withoutTags` – `{*=}` - filter of tag ids which need to skip
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "filterSdsBy": {
              url: urlBase + "/SolrDocuments/filterSdsBy",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getDiscontinuedSdss
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Get SDSs which are marked with the "Discontinued" tag
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` -
             *
             *  - `offset` – `{number=}` - offset
             *
             *  - `limit` – `{number=}` - limit
             *
             *  - `from` – `{number=}` - Unix timestamp in seconds
             *
             *  - `to` – `{number=}` - Unix timestamp in seconds
             *
             *  - `availableLanguages` – `{*=}` - to filter SDSs by available languages
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "getDiscontinuedSdss": {
              url: urlBase + "/SolrDocuments/discontinued",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#applyShowOnlyCompanyPpeSetting
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `showOnlyCompanyPPE` – `{boolean}` - show only company PPE setting
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `apply` – `{boolean=}` -
             */
            "applyShowOnlyCompanyPpeSetting": {
              url: urlBase + "/SolrDocuments/applyShowOnlyCompanyPpeSetting",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#getSDSsByLegislationGroup
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Get SDSs by legislation group ID
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `legislationGroupId` – `{string}` -
             *
             *  - `includeArchived` – `{boolean=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "getSDSsByLegislationGroup": {
              isArray: true,
              url: urlBase + "/SolrDocuments/legislations/:legislationGroupId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#canAddLegislationCodeToSDSLegislationGroup
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Check if the legislation code can be added to the SDS legislation group
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `sdsId` – `{string}` -
             *
             *  - `legislationCode` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "canAddLegislationCodeToSDSLegislationGroup": {
              url: urlBase + "/SolrDocuments/legislations/can-add-code",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#combineSDSsIntoLegislationGroup
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Combine SDSs to the same legislation group
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `currentSdsId` – `{string}` -
             *
             *  - `targetSdsId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "combineSDSsIntoLegislationGroup": {
              url: urlBase + "/SolrDocuments/legislations/combine",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#removeSDSfromLegislationGroup
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Remove the SDS from its legislation group
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `sdsId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
            "removeSDSfromLegislationGroup": {
              url: urlBase + "/SolrDocuments/legislations",
              method: "DELETE",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#destroyById
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SolrDocument#removeById
             * @methodOf lbServices.SolrDocument
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SolrDocument` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.SolrDocument#modelName
        * @propertyOf lbServices.SolrDocument
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SolrDocument`.
        */
        R.modelName = "SolrDocument";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SdsUser
 * @header lbServices.SdsUser
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SdsUser` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SdsUser",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SdsUsers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SdsUser.allowedCompanies.findById() instead.
            "prototype$__findById__allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/:fk",
              method: "GET",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.destroyById() instead.
            "prototype$__destroyById__allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.updateById() instead.
            "prototype$__updateById__allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.link() instead.
            "prototype$__link__allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.unlink() instead.
            "prototype$__unlink__allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.exists() instead.
            "prototype$__exists__allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SdsUser.allowedCompanies() instead.
            "prototype$__get__allowedCompanies": {
              isArray: true,
              url: urlBase + "/SdsUsers/:id/allowedCompanies",
              method: "GET",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.create() instead.
            "prototype$__create__allowedCompanies": {
              url: urlBase + "/SdsUsers/:id/allowedCompanies",
              method: "POST",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.destroyAll() instead.
            "prototype$__delete__allowedCompanies": {
              url: urlBase + "/SdsUsers/:id/allowedCompanies",
              method: "DELETE",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.count() instead.
            "prototype$__count__allowedCompanies": {
              url: urlBase + "/SdsUsers/:id/allowedCompanies/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#exists
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SdsUsers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#findById
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SdsUsers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#find
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SdsUsers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#findOne
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SdsUsers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#updateAll
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/SdsUsers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#deleteById
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SdsUsers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#count
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SdsUsers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#prototype$updateAttributes
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SdsUsers/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#createChangeStream
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SdsUsers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#login
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `{User}` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  return response.resource;
                },
              },
              url: urlBase + "/SdsUsers/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#logout
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/SdsUsers/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#confirm
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Confirm a user registration with email verification token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `uid` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `redirect` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "confirm": {
              url: urlBase + "/SdsUsers/confirm",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#resetPassword
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Reset password for a user with email.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "resetPassword": {
              url: urlBase + "/SdsUsers/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#saveSystemUser
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Upsert system user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "saveSystemUser": {
              url: urlBase + "/SdsUsers/saveSystemUser",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#systemLogin
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Login a system user
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *             Default value: 'user'.
             *
             *  - `rememberMe` – `{boolean=}` - Whether the authentication credentials should be remembered in localStorage across app/browser restarts.
             *
             * @param {Object} postData Request data.
             *
             *  - `username` – `{string}` -
             *
             *  - `password` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "systemLogin": {
              url: urlBase + "/SdsUsers/systemLogin",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#findByCompanyId
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * get a list users by company Id
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - company id
             *
             *  - `limit` – `{number=}` -
             *
             *  - `offset` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "findByCompanyId": {
              url: urlBase + "/SdsUsers/findByCompanyId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#saveUser
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "saveUser": {
              url: urlBase + "/SdsUsers/saveUser",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#resetPasswordByToken
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Update user password
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "resetPasswordByToken": {
              url: urlBase + "/SdsUsers/resetPasswordByToken",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#identifyByToken
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Identify user by token
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `token` – `{string}` - Token
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Current users
             */
            "identifyByToken": {
              url: urlBase + "/SdsUsers/identifyByToken",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#loginByToken
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Log in user by token
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `token` – `{string}` - Token
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "loginByToken": {
              url: urlBase + "/SdsUsers/loginByToken",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#deleteUserById
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "deleteUserById": {
              url: urlBase + "/SdsUsers/deleteUserById",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#forgotPassword
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Recover password by login or email
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `login` – `{string}` - login or email
             *
             *  - `hostname` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * recovery link
             */
            "forgotPassword": {
              url: urlBase + "/SdsUsers/forgotPassword",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#getCompanyUsersByRoleNames
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Get the company users by role names
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `roleNames` – `{string}` - an array of the role names
             *
             *  - `companyId` – `{string}` - the ID of the company whose users should be returned
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of the users
             */
            "getCompanyUsersByRoleNames": {
              isArray: true,
              url: urlBase + "/SdsUsers/getCompanyUsersByRoleNames",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#search
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * search for a user
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `searchString` – `{string}` - a text string to search for
             *
             *  - `companyId` – `{string}` - a company ID to filter by
             *
             *  - `filter` – `{Object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
            "search": {
              url: urlBase + "/SdsUsers/search",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#getCurrent
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/SdsUsers" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SdsUser#update
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#destroyById
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SdsUser#removeById
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsUser` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

        /**
         * @ngdoc method
         * @name lbServices.SdsUser#getCachedCurrent
         * @methodOf lbServices.SdsUser
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.SdsUser#login} or
         * {@link lbServices.SdsUser#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A SdsUser instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.SdsUser#isAuthenticated
         * @methodOf lbServices.SdsUser
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.SdsUser#getCurrentId
         * @methodOf lbServices.SdsUser
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.SdsUser#modelName
        * @propertyOf lbServices.SdsUser
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SdsUser`.
        */
        R.modelName = "SdsUser";

    /**
     * @ngdoc object
     * @name lbServices.SdsUser.allowedCompanies
     * @header lbServices.SdsUser.allowedCompanies
     * @object
     * @description
     *
     * The object `SdsUser.allowedCompanies` groups methods
     * manipulating `Company` instances related to `SdsUser`.
     *
     * Call {@link lbServices.SdsUser#allowedCompanies SdsUser.allowedCompanies()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SdsUser#allowedCompanies
             * @methodOf lbServices.SdsUser
             *
             * @description
             *
             * Queries allowedCompanies of SdsUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.allowedCompanies = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#count
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Counts allowedCompanies of SdsUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.allowedCompanies.count = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::count::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#create
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Creates a new instance in allowedCompanies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.allowedCompanies.create = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::create::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#createMany
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Creates a new instance in allowedCompanies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.allowedCompanies.createMany = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::createMany::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#destroyAll
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Deletes all allowedCompanies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.allowedCompanies.destroyAll = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::delete::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#destroyById
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Delete a related item by id for allowedCompanies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             *  - `fk` – `{*}` - Foreign key for allowedCompanies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.allowedCompanies.destroyById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::destroyById::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#exists
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Check the existence of allowedCompanies relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             *  - `fk` – `{*}` - Foreign key for allowedCompanies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.allowedCompanies.exists = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::exists::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#findById
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Find a related item by id for allowedCompanies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             *  - `fk` – `{*}` - Foreign key for allowedCompanies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.allowedCompanies.findById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::findById::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#link
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Add a related item by id for allowedCompanies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             *  - `fk` – `{*}` - Foreign key for allowedCompanies
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.allowedCompanies.link = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::link::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#unlink
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Remove the allowedCompanies relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             *  - `fk` – `{*}` - Foreign key for allowedCompanies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.allowedCompanies.unlink = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::unlink::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SdsUser.allowedCompanies#updateById
             * @methodOf lbServices.SdsUser.allowedCompanies
             *
             * @description
             *
             * Update a related item by id for allowedCompanies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SdsUser id
             *
             *  - `fk` – `{*}` - Foreign key for allowedCompanies
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.allowedCompanies.updateById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::updateById::SdsUser::allowedCompanies"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Company
 * @header lbServices.Company
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Company` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Company",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Companies/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Company.childSdsCompanies.findById() instead.
            "prototype$__findById__childSdsCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/childSdsCompanies/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.childSdsCompanies.destroyById() instead.
            "prototype$__destroyById__childSdsCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/childSdsCompanies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.childSdsCompanies.updateById() instead.
            "prototype$__updateById__childSdsCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/childSdsCompanies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.parentSdsCompany() instead.
            "prototype$__get__parentSdsCompany": {
              url: urlBase + "/Companies/:id/parentSdsCompany",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__findById__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Find a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "prototype$__findById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/users/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__destroyById__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Delete a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/users/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__updateById__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Update a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "prototype$__updateById__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__link__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Add a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "prototype$__link__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/users/rel/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__unlink__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Remove the users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__unlink__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/users/rel/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__exists__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Check the existence of users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "prototype$__exists__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Company.principals.findById() instead.
            "prototype$__findById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/principals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.principals.destroyById() instead.
            "prototype$__destroyById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/principals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.principals.updateById() instead.
            "prototype$__updateById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/principals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.settings() instead.
            "prototype$__get__settings": {
              url: urlBase + "/Companies/:id/settings",
              method: "GET",
            },

            // INTERNAL. Use Company.settings.create() instead.
            "prototype$__create__settings": {
              url: urlBase + "/Companies/:id/settings",
              method: "POST",
            },

            // INTERNAL. Use Company.settings.update() instead.
            "prototype$__update__settings": {
              url: urlBase + "/Companies/:id/settings",
              method: "PUT",
            },

            // INTERNAL. Use Company.settings.destroy() instead.
            "prototype$__destroy__settings": {
              url: urlBase + "/Companies/:id/settings",
              method: "DELETE",
            },

            // INTERNAL. Use Company.quizzes.findById() instead.
            "prototype$__findById__quizzes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/quizzes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.quizzes.destroyById() instead.
            "prototype$__destroyById__quizzes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/quizzes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.quizzes.updateById() instead.
            "prototype$__updateById__quizzes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/quizzes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.products.findById() instead.
            "prototype$__findById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.products.destroyById() instead.
            "prototype$__destroyById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.products.updateById() instead.
            "prototype$__updateById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.products.link() instead.
            "prototype$__link__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.products.unlink() instead.
            "prototype$__unlink__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.products.exists() instead.
            "prototype$__exists__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Company.inventories.findById() instead.
            "prototype$__findById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.inventories.destroyById() instead.
            "prototype$__destroyById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventories.updateById() instead.
            "prototype$__updateById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.inventoryContainers.findById() instead.
            "prototype$__findById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.inventoryContainers.destroyById() instead.
            "prototype$__destroyById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventoryContainers.updateById() instead.
            "prototype$__updateById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.inventoryContainers.link() instead.
            "prototype$__link__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.inventoryContainers.unlink() instead.
            "prototype$__unlink__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventoryContainers.exists() instead.
            "prototype$__exists__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Company.companyType() instead.
            "prototype$__get__companyType": {
              url: urlBase + "/Companies/:id/companyType",
              method: "GET",
            },

            // INTERNAL. Use Company.childSdsCompanies() instead.
            "prototype$__get__childSdsCompanies": {
              isArray: true,
              url: urlBase + "/Companies/:id/childSdsCompanies",
              method: "GET",
            },

            // INTERNAL. Use Company.childSdsCompanies.create() instead.
            "prototype$__create__childSdsCompanies": {
              url: urlBase + "/Companies/:id/childSdsCompanies",
              method: "POST",
            },

            // INTERNAL. Use Company.childSdsCompanies.destroyAll() instead.
            "prototype$__delete__childSdsCompanies": {
              url: urlBase + "/Companies/:id/childSdsCompanies",
              method: "DELETE",
            },

            // INTERNAL. Use Company.childSdsCompanies.count() instead.
            "prototype$__count__childSdsCompanies": {
              url: urlBase + "/Companies/:id/childSdsCompanies/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__get__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Queries users of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "prototype$__get__users": {
              isArray: true,
              url: urlBase + "/Companies/:id/users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__create__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Creates a new instance in users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "prototype$__create__users": {
              url: urlBase + "/Companies/:id/users",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__delete__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Deletes all users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__users": {
              url: urlBase + "/Companies/:id/users",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$__count__users
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Counts users of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__users": {
              url: urlBase + "/Companies/:id/users/count",
              method: "GET",
            },

            // INTERNAL. Use Company.principals() instead.
            "prototype$__get__principals": {
              isArray: true,
              url: urlBase + "/Companies/:id/principals",
              method: "GET",
            },

            // INTERNAL. Use Company.principals.create() instead.
            "prototype$__create__principals": {
              url: urlBase + "/Companies/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Company.principals.destroyAll() instead.
            "prototype$__delete__principals": {
              url: urlBase + "/Companies/:id/principals",
              method: "DELETE",
            },

            // INTERNAL. Use Company.principals.count() instead.
            "prototype$__count__principals": {
              url: urlBase + "/Companies/:id/principals/count",
              method: "GET",
            },

            // INTERNAL. Use Company.quizzes() instead.
            "prototype$__get__quizzes": {
              isArray: true,
              url: urlBase + "/Companies/:id/quizzes",
              method: "GET",
            },

            // INTERNAL. Use Company.quizzes.create() instead.
            "prototype$__create__quizzes": {
              url: urlBase + "/Companies/:id/quizzes",
              method: "POST",
            },

            // INTERNAL. Use Company.quizzes.destroyAll() instead.
            "prototype$__delete__quizzes": {
              url: urlBase + "/Companies/:id/quizzes",
              method: "DELETE",
            },

            // INTERNAL. Use Company.quizzes.count() instead.
            "prototype$__count__quizzes": {
              url: urlBase + "/Companies/:id/quizzes/count",
              method: "GET",
            },

            // INTERNAL. Use Company.products() instead.
            "prototype$__get__products": {
              isArray: true,
              url: urlBase + "/Companies/:id/products",
              method: "GET",
            },

            // INTERNAL. Use Company.products.create() instead.
            "prototype$__create__products": {
              url: urlBase + "/Companies/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Company.products.destroyAll() instead.
            "prototype$__delete__products": {
              url: urlBase + "/Companies/:id/products",
              method: "DELETE",
            },

            // INTERNAL. Use Company.products.count() instead.
            "prototype$__count__products": {
              url: urlBase + "/Companies/:id/products/count",
              method: "GET",
            },

            // INTERNAL. Use Company.inventories() instead.
            "prototype$__get__inventories": {
              isArray: true,
              url: urlBase + "/Companies/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use Company.inventories.create() instead.
            "prototype$__create__inventories": {
              url: urlBase + "/Companies/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Company.inventories.destroyAll() instead.
            "prototype$__delete__inventories": {
              url: urlBase + "/Companies/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventories.count() instead.
            "prototype$__count__inventories": {
              url: urlBase + "/Companies/:id/inventories/count",
              method: "GET",
            },

            // INTERNAL. Use Company.inventoryContainers() instead.
            "prototype$__get__inventoryContainers": {
              isArray: true,
              url: urlBase + "/Companies/:id/inventoryContainers",
              method: "GET",
            },

            // INTERNAL. Use Company.inventoryContainers.create() instead.
            "prototype$__create__inventoryContainers": {
              url: urlBase + "/Companies/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Company.inventoryContainers.destroyAll() instead.
            "prototype$__delete__inventoryContainers": {
              url: urlBase + "/Companies/:id/inventoryContainers",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventoryContainers.count() instead.
            "prototype$__count__inventoryContainers": {
              url: urlBase + "/Companies/:id/inventoryContainers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#create
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Companies",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#createMany
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Companies",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#upsert
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Companies",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#exists
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Companies/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#findById
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Companies/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#find
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Companies",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#findOne
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Companies/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#updateAll
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Companies/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#deleteById
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Companies/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#count
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Companies/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#prototype$updateAttributes
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Companies/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Company#createChangeStream
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Companies/change-stream",
              method: "POST",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.findById() instead.
            "::findById::SdsUser::allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/:fk",
              method: "GET",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.destroyById() instead.
            "::destroyById::SdsUser::allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.updateById() instead.
            "::updateById::SdsUser::allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.link() instead.
            "::link::SdsUser::allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.unlink() instead.
            "::unlink::SdsUser::allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.exists() instead.
            "::exists::SdsUser::allowedCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsUsers/:id/allowedCompanies/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SdsUser.allowedCompanies() instead.
            "::get::SdsUser::allowedCompanies": {
              isArray: true,
              url: urlBase + "/SdsUsers/:id/allowedCompanies",
              method: "GET",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.create() instead.
            "::create::SdsUser::allowedCompanies": {
              url: urlBase + "/SdsUsers/:id/allowedCompanies",
              method: "POST",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.createMany() instead.
            "::createMany::SdsUser::allowedCompanies": {
              isArray: true,
              url: urlBase + "/SdsUsers/:id/allowedCompanies",
              method: "POST",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.destroyAll() instead.
            "::delete::SdsUser::allowedCompanies": {
              url: urlBase + "/SdsUsers/:id/allowedCompanies",
              method: "DELETE",
            },

            // INTERNAL. Use SdsUser.allowedCompanies.count() instead.
            "::count::SdsUser::allowedCompanies": {
              url: urlBase + "/SdsUsers/:id/allowedCompanies/count",
              method: "GET",
            },

            // INTERNAL. Use Company.childSdsCompanies.findById() instead.
            "::findById::Company::childSdsCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/childSdsCompanies/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.childSdsCompanies.destroyById() instead.
            "::destroyById::Company::childSdsCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/childSdsCompanies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.childSdsCompanies.updateById() instead.
            "::updateById::Company::childSdsCompanies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/childSdsCompanies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.parentSdsCompany() instead.
            "::get::Company::parentSdsCompany": {
              url: urlBase + "/Companies/:id/parentSdsCompany",
              method: "GET",
            },

            // INTERNAL. Use Company.childSdsCompanies() instead.
            "::get::Company::childSdsCompanies": {
              isArray: true,
              url: urlBase + "/Companies/:id/childSdsCompanies",
              method: "GET",
            },

            // INTERNAL. Use Company.childSdsCompanies.create() instead.
            "::create::Company::childSdsCompanies": {
              url: urlBase + "/Companies/:id/childSdsCompanies",
              method: "POST",
            },

            // INTERNAL. Use Company.childSdsCompanies.createMany() instead.
            "::createMany::Company::childSdsCompanies": {
              isArray: true,
              url: urlBase + "/Companies/:id/childSdsCompanies",
              method: "POST",
            },

            // INTERNAL. Use Company.childSdsCompanies.destroyAll() instead.
            "::delete::Company::childSdsCompanies": {
              url: urlBase + "/Companies/:id/childSdsCompanies",
              method: "DELETE",
            },

            // INTERNAL. Use Company.childSdsCompanies.count() instead.
            "::count::Company::childSdsCompanies": {
              url: urlBase + "/Companies/:id/childSdsCompanies/count",
              method: "GET",
            },

            // INTERNAL. Use CompanyMapping.company() instead.
            "::get::CompanyMapping::company": {
              url: urlBase + "/CompanyMappings/:id/company",
              method: "GET",
            },

            // INTERNAL. Use QRCode.companies() instead.
            "::get::QRCode::companies": {
              url: urlBase + "/QRCodes/:id/companies",
              method: "GET",
            },

            // INTERNAL. Use QRCode.companies.create() instead.
            "::create::QRCode::companies": {
              url: urlBase + "/QRCodes/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use QRCode.companies.createMany() instead.
            "::createMany::QRCode::companies": {
              isArray: true,
              url: urlBase + "/QRCodes/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use QRCode.companies.update() instead.
            "::update::QRCode::companies": {
              url: urlBase + "/QRCodes/:id/companies",
              method: "PUT",
            },

            // INTERNAL. Use QRCode.companies.destroy() instead.
            "::destroy::QRCode::companies": {
              url: urlBase + "/QRCodes/:id/companies",
              method: "DELETE",
            },

            // INTERNAL. Use SdsRequest.company() instead.
            "::get::SdsRequest::company": {
              url: urlBase + "/SdsRequests/:id/company",
              method: "GET",
            },

            // INTERNAL. Use LocationRequest.company() instead.
            "::get::LocationRequest::company": {
              url: urlBase + "/LocationRequests/:id/company",
              method: "GET",
            },

            // INTERNAL. Use File.company() instead.
            "::get::File::company": {
              url: urlBase + "/Files/:id/company",
              method: "GET",
            },

            // INTERNAL. Use Equipment.company() instead.
            "::get::Equipment::company": {
              url: urlBase + "/Equipment/:id/company",
              method: "GET",
            },

            // INTERNAL. Use Quiz.company() instead.
            "::get::Quiz::company": {
              url: urlBase + "/Quizzes/:id/company",
              method: "GET",
            },

            // INTERNAL. Use Product.companies.findById() instead.
            "::findById::Product::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/:fk",
              method: "GET",
            },

            // INTERNAL. Use Product.companies.destroyById() instead.
            "::destroyById::Product::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.companies.updateById() instead.
            "::updateById::Product::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.companies.link() instead.
            "::link::Product::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.companies.unlink() instead.
            "::unlink::Product::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.companies.exists() instead.
            "::exists::Product::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Product.companies() instead.
            "::get::Product::companies": {
              isArray: true,
              url: urlBase + "/Products/:id/companies",
              method: "GET",
            },

            // INTERNAL. Use Product.companies.create() instead.
            "::create::Product::companies": {
              url: urlBase + "/Products/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use Product.companies.createMany() instead.
            "::createMany::Product::companies": {
              isArray: true,
              url: urlBase + "/Products/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use Product.companies.destroyAll() instead.
            "::delete::Product::companies": {
              url: urlBase + "/Products/:id/companies",
              method: "DELETE",
            },

            // INTERNAL. Use Product.companies.count() instead.
            "::count::Product::companies": {
              url: urlBase + "/Products/:id/companies/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.company() instead.
            "::get::Inventory::company": {
              url: urlBase + "/Inventories/:id/company",
              method: "GET",
            },

            // INTERNAL. Use InventoryReview.company() instead.
            "::get::InventoryReview::company": {
              url: urlBase + "/InventoryReviews/:id/company",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.company() instead.
            "::get::InventoryContainer::company": {
              url: urlBase + "/InventoryContainers/:id/company",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductAlert.company() instead.
            "::get::InventoryProductAlert::company": {
              url: urlBase + "/InventoryProductAlerts/:id/company",
              method: "GET",
            },

            // INTERNAL. Use CompanyType.companies.findById() instead.
            "::findById::CompanyType::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanyTypes/:id/companies/:fk",
              method: "GET",
            },

            // INTERNAL. Use CompanyType.companies.destroyById() instead.
            "::destroyById::CompanyType::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanyTypes/:id/companies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CompanyType.companies.updateById() instead.
            "::updateById::CompanyType::companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanyTypes/:id/companies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CompanyType.companies() instead.
            "::get::CompanyType::companies": {
              isArray: true,
              url: urlBase + "/CompanyTypes/:id/companies",
              method: "GET",
            },

            // INTERNAL. Use CompanyType.companies.create() instead.
            "::create::CompanyType::companies": {
              url: urlBase + "/CompanyTypes/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use CompanyType.companies.createMany() instead.
            "::createMany::CompanyType::companies": {
              isArray: true,
              url: urlBase + "/CompanyTypes/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use CompanyType.companies.destroyAll() instead.
            "::delete::CompanyType::companies": {
              url: urlBase + "/CompanyTypes/:id/companies",
              method: "DELETE",
            },

            // INTERNAL. Use CompanyType.companies.count() instead.
            "::count::CompanyType::companies": {
              url: urlBase + "/CompanyTypes/:id/companies/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Company#updateOrCreate
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Company#update
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Company#destroyById
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Company#removeById
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Company#modelName
        * @propertyOf lbServices.Company
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Company`.
        */
        R.modelName = "Company";

    /**
     * @ngdoc object
     * @name lbServices.Company.childSdsCompanies
     * @header lbServices.Company.childSdsCompanies
     * @object
     * @description
     *
     * The object `Company.childSdsCompanies` groups methods
     * manipulating `Company` instances related to `Company`.
     *
     * Call {@link lbServices.Company#childSdsCompanies Company.childSdsCompanies()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Company#childSdsCompanies
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Queries childSdsCompanies of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.childSdsCompanies = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::Company::childSdsCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.childSdsCompanies#count
             * @methodOf lbServices.Company.childSdsCompanies
             *
             * @description
             *
             * Counts childSdsCompanies of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.childSdsCompanies.count = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::count::Company::childSdsCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.childSdsCompanies#create
             * @methodOf lbServices.Company.childSdsCompanies
             *
             * @description
             *
             * Creates a new instance in childSdsCompanies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.childSdsCompanies.create = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::create::Company::childSdsCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.childSdsCompanies#createMany
             * @methodOf lbServices.Company.childSdsCompanies
             *
             * @description
             *
             * Creates a new instance in childSdsCompanies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.childSdsCompanies.createMany = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::createMany::Company::childSdsCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.childSdsCompanies#destroyAll
             * @methodOf lbServices.Company.childSdsCompanies
             *
             * @description
             *
             * Deletes all childSdsCompanies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.childSdsCompanies.destroyAll = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::delete::Company::childSdsCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.childSdsCompanies#destroyById
             * @methodOf lbServices.Company.childSdsCompanies
             *
             * @description
             *
             * Delete a related item by id for childSdsCompanies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for childSdsCompanies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.childSdsCompanies.destroyById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::destroyById::Company::childSdsCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.childSdsCompanies#findById
             * @methodOf lbServices.Company.childSdsCompanies
             *
             * @description
             *
             * Find a related item by id for childSdsCompanies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for childSdsCompanies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.childSdsCompanies.findById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::findById::Company::childSdsCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.childSdsCompanies#updateById
             * @methodOf lbServices.Company.childSdsCompanies
             *
             * @description
             *
             * Update a related item by id for childSdsCompanies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for childSdsCompanies
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.childSdsCompanies.updateById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::updateById::Company::childSdsCompanies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company#parentSdsCompany
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Fetches belongsTo relation parentSdsCompany.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.parentSdsCompany = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::Company::parentSdsCompany"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Company.principals
     * @header lbServices.Company.principals
     * @object
     * @description
     *
     * The object `Company.principals` groups methods
     * manipulating `CompanyMapping` instances related to `Company`.
     *
     * Call {@link lbServices.Company#principals Company.principals()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Company#principals
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Queries principals of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
        R.principals = function() {
          var TargetResource = $injector.get("CompanyMapping");
          var action = TargetResource["::get::Company::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.principals#count
             * @methodOf lbServices.Company.principals
             *
             * @description
             *
             * Counts principals of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.principals.count = function() {
          var TargetResource = $injector.get("CompanyMapping");
          var action = TargetResource["::count::Company::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.principals#create
             * @methodOf lbServices.Company.principals
             *
             * @description
             *
             * Creates a new instance in principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
        R.principals.create = function() {
          var TargetResource = $injector.get("CompanyMapping");
          var action = TargetResource["::create::Company::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.principals#createMany
             * @methodOf lbServices.Company.principals
             *
             * @description
             *
             * Creates a new instance in principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
        R.principals.createMany = function() {
          var TargetResource = $injector.get("CompanyMapping");
          var action = TargetResource["::createMany::Company::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.principals#destroyAll
             * @methodOf lbServices.Company.principals
             *
             * @description
             *
             * Deletes all principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.principals.destroyAll = function() {
          var TargetResource = $injector.get("CompanyMapping");
          var action = TargetResource["::delete::Company::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.principals#destroyById
             * @methodOf lbServices.Company.principals
             *
             * @description
             *
             * Delete a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.principals.destroyById = function() {
          var TargetResource = $injector.get("CompanyMapping");
          var action = TargetResource["::destroyById::Company::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.principals#findById
             * @methodOf lbServices.Company.principals
             *
             * @description
             *
             * Find a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
        R.principals.findById = function() {
          var TargetResource = $injector.get("CompanyMapping");
          var action = TargetResource["::findById::Company::principals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.principals#updateById
             * @methodOf lbServices.Company.principals
             *
             * @description
             *
             * Update a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
        R.principals.updateById = function() {
          var TargetResource = $injector.get("CompanyMapping");
          var action = TargetResource["::updateById::Company::principals"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Company.settings
     * @header lbServices.Company.settings
     * @object
     * @description
     *
     * The object `Company.settings` groups methods
     * manipulating `CompanySetting` instances related to `Company`.
     *
     * Call {@link lbServices.Company#settings Company.settings()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Company#settings
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Fetches hasOne relation settings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R.settings = function() {
          var TargetResource = $injector.get("CompanySetting");
          var action = TargetResource["::get::Company::settings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.settings#create
             * @methodOf lbServices.Company.settings
             *
             * @description
             *
             * Creates a new instance in settings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R.settings.create = function() {
          var TargetResource = $injector.get("CompanySetting");
          var action = TargetResource["::create::Company::settings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.settings#createMany
             * @methodOf lbServices.Company.settings
             *
             * @description
             *
             * Creates a new instance in settings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R.settings.createMany = function() {
          var TargetResource = $injector.get("CompanySetting");
          var action = TargetResource["::createMany::Company::settings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.settings#destroy
             * @methodOf lbServices.Company.settings
             *
             * @description
             *
             * Deletes settings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.settings.destroy = function() {
          var TargetResource = $injector.get("CompanySetting");
          var action = TargetResource["::destroy::Company::settings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.settings#update
             * @methodOf lbServices.Company.settings
             *
             * @description
             *
             * Update settings of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R.settings.update = function() {
          var TargetResource = $injector.get("CompanySetting");
          var action = TargetResource["::update::Company::settings"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Company.quizzes
     * @header lbServices.Company.quizzes
     * @object
     * @description
     *
     * The object `Company.quizzes` groups methods
     * manipulating `Quiz` instances related to `Company`.
     *
     * Call {@link lbServices.Company#quizzes Company.quizzes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Company#quizzes
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Queries quizzes of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
        R.quizzes = function() {
          var TargetResource = $injector.get("Quiz");
          var action = TargetResource["::get::Company::quizzes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.quizzes#count
             * @methodOf lbServices.Company.quizzes
             *
             * @description
             *
             * Counts quizzes of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.quizzes.count = function() {
          var TargetResource = $injector.get("Quiz");
          var action = TargetResource["::count::Company::quizzes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.quizzes#create
             * @methodOf lbServices.Company.quizzes
             *
             * @description
             *
             * Creates a new instance in quizzes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
        R.quizzes.create = function() {
          var TargetResource = $injector.get("Quiz");
          var action = TargetResource["::create::Company::quizzes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.quizzes#createMany
             * @methodOf lbServices.Company.quizzes
             *
             * @description
             *
             * Creates a new instance in quizzes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
        R.quizzes.createMany = function() {
          var TargetResource = $injector.get("Quiz");
          var action = TargetResource["::createMany::Company::quizzes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.quizzes#destroyAll
             * @methodOf lbServices.Company.quizzes
             *
             * @description
             *
             * Deletes all quizzes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.quizzes.destroyAll = function() {
          var TargetResource = $injector.get("Quiz");
          var action = TargetResource["::delete::Company::quizzes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.quizzes#destroyById
             * @methodOf lbServices.Company.quizzes
             *
             * @description
             *
             * Delete a related item by id for quizzes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for quizzes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.quizzes.destroyById = function() {
          var TargetResource = $injector.get("Quiz");
          var action = TargetResource["::destroyById::Company::quizzes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.quizzes#findById
             * @methodOf lbServices.Company.quizzes
             *
             * @description
             *
             * Find a related item by id for quizzes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for quizzes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
        R.quizzes.findById = function() {
          var TargetResource = $injector.get("Quiz");
          var action = TargetResource["::findById::Company::quizzes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.quizzes#updateById
             * @methodOf lbServices.Company.quizzes
             *
             * @description
             *
             * Update a related item by id for quizzes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for quizzes
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
        R.quizzes.updateById = function() {
          var TargetResource = $injector.get("Quiz");
          var action = TargetResource["::updateById::Company::quizzes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Company.products
     * @header lbServices.Company.products
     * @object
     * @description
     *
     * The object `Company.products` groups methods
     * manipulating `Product` instances related to `Company`.
     *
     * Call {@link lbServices.Company#products Company.products()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Company#products
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Queries products of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::get::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#count
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Counts products of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.products.count = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::count::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#create
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Creates a new instance in products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.create = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::create::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#createMany
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Creates a new instance in products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.createMany = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::createMany::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#destroyAll
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Deletes all products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.products.destroyAll = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::delete::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#destroyById
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Delete a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.products.destroyById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::destroyById::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#exists
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Check the existence of products relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.exists = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::exists::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#findById
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Find a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.findById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::findById::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#link
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Add a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.link = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::link::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#unlink
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Remove the products relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.products.unlink = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::unlink::Company::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.products#updateById
             * @methodOf lbServices.Company.products
             *
             * @description
             *
             * Update a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.updateById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::updateById::Company::products"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Company.inventories
     * @header lbServices.Company.inventories
     * @object
     * @description
     *
     * The object `Company.inventories` groups methods
     * manipulating `Inventory` instances related to `Company`.
     *
     * Call {@link lbServices.Company#inventories Company.inventories()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Company#inventories
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Queries inventories of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::Company::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventories#count
             * @methodOf lbServices.Company.inventories
             *
             * @description
             *
             * Counts inventories of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.inventories.count = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::count::Company::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventories#create
             * @methodOf lbServices.Company.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.create = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::create::Company::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventories#createMany
             * @methodOf lbServices.Company.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.createMany = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::createMany::Company::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventories#destroyAll
             * @methodOf lbServices.Company.inventories
             *
             * @description
             *
             * Deletes all inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyAll = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::delete::Company::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventories#destroyById
             * @methodOf lbServices.Company.inventories
             *
             * @description
             *
             * Delete a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::destroyById::Company::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventories#findById
             * @methodOf lbServices.Company.inventories
             *
             * @description
             *
             * Find a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.findById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::findById::Company::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventories#updateById
             * @methodOf lbServices.Company.inventories
             *
             * @description
             *
             * Update a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.updateById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::updateById::Company::inventories"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Company.inventoryContainers
     * @header lbServices.Company.inventoryContainers
     * @object
     * @description
     *
     * The object `Company.inventoryContainers` groups methods
     * manipulating `InventoryContainer` instances related to `Company`.
     *
     * Call {@link lbServices.Company#inventoryContainers Company.inventoryContainers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Company#inventoryContainers
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Queries inventoryContainers of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::get::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#count
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Counts inventoryContainers of Company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.inventoryContainers.count = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::count::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#create
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Creates a new instance in inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.create = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::create::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#createMany
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Creates a new instance in inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.createMany = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::createMany::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#destroyAll
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Deletes all inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventoryContainers.destroyAll = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::delete::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#destroyById
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Delete a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventoryContainers.destroyById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::destroyById::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#exists
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Check the existence of inventoryContainers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.exists = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::exists::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#findById
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Find a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.findById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::findById::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#link
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Add a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.link = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::link::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#unlink
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Remove the inventoryContainers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventoryContainers.unlink = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::unlink::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company.inventoryContainers#updateById
             * @methodOf lbServices.Company.inventoryContainers
             *
             * @description
             *
             * Update a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.updateById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::updateById::Company::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Company#companyType
             * @methodOf lbServices.Company
             *
             * @description
             *
             * Fetches belongsTo relation companyType.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
        R.companyType = function() {
          var TargetResource = $injector.get("CompanyType");
          var action = TargetResource["::get::Company::companyType"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.CompanyMapping
 * @header lbServices.CompanyMapping
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `CompanyMapping` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "CompanyMapping",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/CompanyMappings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use CompanyMapping.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/CompanyMappings/:id/company",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#create
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/CompanyMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#createMany
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/CompanyMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#upsert
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/CompanyMappings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#exists
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/CompanyMappings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#findById
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/CompanyMappings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#find
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/CompanyMappings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#findOne
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/CompanyMappings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#updateAll
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/CompanyMappings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#deleteById
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/CompanyMappings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#count
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/CompanyMappings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#prototype$updateAttributes
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/CompanyMappings/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#createChangeStream
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/CompanyMappings/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Company.principals.findById() instead.
            "::findById::Company::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/principals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.principals.destroyById() instead.
            "::destroyById::Company::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/principals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.principals.updateById() instead.
            "::updateById::Company::principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/principals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.principals() instead.
            "::get::Company::principals": {
              isArray: true,
              url: urlBase + "/Companies/:id/principals",
              method: "GET",
            },

            // INTERNAL. Use Company.principals.create() instead.
            "::create::Company::principals": {
              url: urlBase + "/Companies/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Company.principals.createMany() instead.
            "::createMany::Company::principals": {
              isArray: true,
              url: urlBase + "/Companies/:id/principals",
              method: "POST",
            },

            // INTERNAL. Use Company.principals.destroyAll() instead.
            "::delete::Company::principals": {
              url: urlBase + "/Companies/:id/principals",
              method: "DELETE",
            },

            // INTERNAL. Use Company.principals.count() instead.
            "::count::Company::principals": {
              url: urlBase + "/Companies/:id/principals/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#updateOrCreate
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#update
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#destroyById
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#removeById
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyMapping` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.CompanyMapping#modelName
        * @propertyOf lbServices.CompanyMapping
        * @description
        * The name of the model represented by this $resource,
        * i.e. `CompanyMapping`.
        */
        R.modelName = "CompanyMapping";


            /**
             * @ngdoc method
             * @name lbServices.CompanyMapping#company
             * @methodOf lbServices.CompanyMapping
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::CompanyMapping::company"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Tag
 * @header lbServices.Tag
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Tag` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Tag",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Tags/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Tag.tags.findById() instead.
            "prototype$__findById__tags": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tags/:id/tags/:fk",
              method: "GET",
            },

            // INTERNAL. Use Tag.tags.destroyById() instead.
            "prototype$__destroyById__tags": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tags/:id/tags/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Tag.tags.updateById() instead.
            "prototype$__updateById__tags": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tags/:id/tags/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Tag.tags() instead.
            "prototype$__get__tags": {
              isArray: true,
              url: urlBase + "/Tags/:id/tags",
              method: "GET",
            },

            // INTERNAL. Use Tag.tags.create() instead.
            "prototype$__create__tags": {
              url: urlBase + "/Tags/:id/tags",
              method: "POST",
            },

            // INTERNAL. Use Tag.tags.destroyAll() instead.
            "prototype$__delete__tags": {
              url: urlBase + "/Tags/:id/tags",
              method: "DELETE",
            },

            // INTERNAL. Use Tag.tags.count() instead.
            "prototype$__count__tags": {
              url: urlBase + "/Tags/:id/tags/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#create
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Tags",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#createMany
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Tags",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#upsert
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Tags",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#exists
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Tags/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#findById
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Tags/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#find
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Tags",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#findOne
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Tags/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#updateAll
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Tags/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#deleteById
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Tags/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#count
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Tags/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#prototype$updateAttributes
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Tags/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#createChangeStream
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Tags/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#cloneTagsToCompany
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Clone all tags from one company to another. The operation create a "clonedFrom" property for a cloned tag.<br><b>Be careful with this operation and don't do it twice. You have been warned.</b>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `srcCompanyId` – `{string}` - source company Id
             *
             *  - `destCompanyId` – `{string}` - destination company Id
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of new cloned tags
             */
            "cloneTagsToCompany": {
              isArray: true,
              url: urlBase + "/Tags/cloneTagsToCompany",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#addCompanyAndTagsToSdsFromSrcCompany
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Add a company Id and its tags to SDSs as in source company. <b>The company has to have tags. If not only company Id will be added.</b>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `srcCompanyId` – `{string}` - source company Id
             *
             *  - `destCompanyId` – `{string}` - destination company Id
             *
             *  - `start` – `{number=}` - start position of processing documents from source company, by default is 0
             *
             *  - `rows` – `{number=}` - number of processing documents from source company, by default is 9999
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * response from Solr
             */
            "addCompanyAndTagsToSdsFromSrcCompany": {
              url: urlBase + "/Tags/addCompanyAndTagsToSdsFromSrcCompany",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tag#mergeTags
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Merge two tags into one
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `destTagId` – `{string}` - the destination tag ID
             *
             *  - `mergedTagId` – `{string}` - ID of the tag that should be merged
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * result of the operation
             */
            "mergeTags": {
              url: urlBase + "/Tags/:destTagId/:mergedTagId",
              method: "PUT",
            },

            // INTERNAL. Use Tag.tags.findById() instead.
            "::findById::Tag::tags": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tags/:id/tags/:fk",
              method: "GET",
            },

            // INTERNAL. Use Tag.tags.destroyById() instead.
            "::destroyById::Tag::tags": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tags/:id/tags/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Tag.tags.updateById() instead.
            "::updateById::Tag::tags": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Tags/:id/tags/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Tag.tags() instead.
            "::get::Tag::tags": {
              isArray: true,
              url: urlBase + "/Tags/:id/tags",
              method: "GET",
            },

            // INTERNAL. Use Tag.tags.create() instead.
            "::create::Tag::tags": {
              url: urlBase + "/Tags/:id/tags",
              method: "POST",
            },

            // INTERNAL. Use Tag.tags.createMany() instead.
            "::createMany::Tag::tags": {
              isArray: true,
              url: urlBase + "/Tags/:id/tags",
              method: "POST",
            },

            // INTERNAL. Use Tag.tags.destroyAll() instead.
            "::delete::Tag::tags": {
              url: urlBase + "/Tags/:id/tags",
              method: "DELETE",
            },

            // INTERNAL. Use Tag.tags.count() instead.
            "::count::Tag::tags": {
              url: urlBase + "/Tags/:id/tags/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Tag#updateOrCreate
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Tag#update
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Tag#destroyById
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tag#removeById
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Tag#modelName
        * @propertyOf lbServices.Tag
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Tag`.
        */
        R.modelName = "Tag";

    /**
     * @ngdoc object
     * @name lbServices.Tag.tags
     * @header lbServices.Tag.tags
     * @object
     * @description
     *
     * The object `Tag.tags` groups methods
     * manipulating `Tag` instances related to `Tag`.
     *
     * Call {@link lbServices.Tag#tags Tag.tags()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Tag#tags
             * @methodOf lbServices.Tag
             *
             * @description
             *
             * Queries tags of Tag.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
        R.tags = function() {
          var TargetResource = $injector.get("Tag");
          var action = TargetResource["::get::Tag::tags"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tag.tags#count
             * @methodOf lbServices.Tag.tags
             *
             * @description
             *
             * Counts tags of Tag.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.tags.count = function() {
          var TargetResource = $injector.get("Tag");
          var action = TargetResource["::count::Tag::tags"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tag.tags#create
             * @methodOf lbServices.Tag.tags
             *
             * @description
             *
             * Creates a new instance in tags of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
        R.tags.create = function() {
          var TargetResource = $injector.get("Tag");
          var action = TargetResource["::create::Tag::tags"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tag.tags#createMany
             * @methodOf lbServices.Tag.tags
             *
             * @description
             *
             * Creates a new instance in tags of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
        R.tags.createMany = function() {
          var TargetResource = $injector.get("Tag");
          var action = TargetResource["::createMany::Tag::tags"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tag.tags#destroyAll
             * @methodOf lbServices.Tag.tags
             *
             * @description
             *
             * Deletes all tags of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tags.destroyAll = function() {
          var TargetResource = $injector.get("Tag");
          var action = TargetResource["::delete::Tag::tags"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tag.tags#destroyById
             * @methodOf lbServices.Tag.tags
             *
             * @description
             *
             * Delete a related item by id for tags.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for tags
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.tags.destroyById = function() {
          var TargetResource = $injector.get("Tag");
          var action = TargetResource["::destroyById::Tag::tags"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tag.tags#findById
             * @methodOf lbServices.Tag.tags
             *
             * @description
             *
             * Find a related item by id for tags.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for tags
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
        R.tags.findById = function() {
          var TargetResource = $injector.get("Tag");
          var action = TargetResource["::findById::Tag::tags"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Tag.tags#updateById
             * @methodOf lbServices.Tag.tags
             *
             * @description
             *
             * Update a related item by id for tags.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for tags
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tag` object.)
             * </em>
             */
        R.tags.updateById = function() {
          var TargetResource = $injector.get("Tag");
          var action = TargetResource["::updateById::Tag::tags"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.QRCode
 * @header lbServices.QRCode
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `QRCode` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "QRCode",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/QRCodes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use QRCode.companies() instead.
            "prototype$__get__companies": {
              url: urlBase + "/QRCodes/:id/companies",
              method: "GET",
            },

            // INTERNAL. Use QRCode.companies.create() instead.
            "prototype$__create__companies": {
              url: urlBase + "/QRCodes/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use QRCode.companies.update() instead.
            "prototype$__update__companies": {
              url: urlBase + "/QRCodes/:id/companies",
              method: "PUT",
            },

            // INTERNAL. Use QRCode.companies.destroy() instead.
            "prototype$__destroy__companies": {
              url: urlBase + "/QRCodes/:id/companies",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#create
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/QRCodes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#createMany
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/QRCodes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#upsert
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/QRCodes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#exists
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/QRCodes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#findById
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/QRCodes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#find
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/QRCodes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#findOne
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/QRCodes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#updateAll
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/QRCodes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#deleteById
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/QRCodes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#count
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/QRCodes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#prototype$updateAttributes
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/QRCodes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#createChangeStream
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/QRCodes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.QRCode#getArchive
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Creates an archive with all data related to the given QR code and sends it to the client
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `version` – `{number=}` -
             *
             *  - `fieldList` – `{string=}` - a field list for SDSs e.g. "id, chemicalFriendlyName" (can be comma or space seperated)
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
            "getArchive": {
              url: urlBase + "/QRCodes/:id/archive",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.QRCode#updateOrCreate
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.QRCode#update
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.QRCode#destroyById
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.QRCode#removeById
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `QRCode` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.QRCode#modelName
        * @propertyOf lbServices.QRCode
        * @description
        * The name of the model represented by this $resource,
        * i.e. `QRCode`.
        */
        R.modelName = "QRCode";

    /**
     * @ngdoc object
     * @name lbServices.QRCode.companies
     * @header lbServices.QRCode.companies
     * @object
     * @description
     *
     * The object `QRCode.companies` groups methods
     * manipulating `Company` instances related to `QRCode`.
     *
     * Call {@link lbServices.QRCode#companies QRCode.companies()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.QRCode#companies
             * @methodOf lbServices.QRCode
             *
             * @description
             *
             * Fetches hasOne relation companies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::QRCode::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.QRCode.companies#create
             * @methodOf lbServices.QRCode.companies
             *
             * @description
             *
             * Creates a new instance in companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.create = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::create::QRCode::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.QRCode.companies#createMany
             * @methodOf lbServices.QRCode.companies
             *
             * @description
             *
             * Creates a new instance in companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.createMany = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::createMany::QRCode::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.QRCode.companies#destroy
             * @methodOf lbServices.QRCode.companies
             *
             * @description
             *
             * Deletes companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.companies.destroy = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::destroy::QRCode::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.QRCode.companies#update
             * @methodOf lbServices.QRCode.companies
             *
             * @description
             *
             * Update companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.update = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::update::QRCode::companies"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SdsRequest
 * @header lbServices.SdsRequest
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SdsRequest` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SdsRequest",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SdsRequests/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SdsRequest.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/SdsRequests/:id/company",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#prototype$__get__user
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "prototype$__get__user": {
              url: urlBase + "/SdsRequests/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#prototype$__findById__history
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Find a related item by id for history.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for history
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "prototype$__findById__history": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsRequests/:id/history/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#prototype$__destroyById__history
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Delete a related item by id for history.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for history
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__history": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsRequests/:id/history/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#prototype$__updateById__history
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Update a related item by id for history.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for history
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "prototype$__updateById__history": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SdsRequests/:id/history/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#prototype$__get__history
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Queries history of SdsRequest.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "prototype$__get__history": {
              isArray: true,
              url: urlBase + "/SdsRequests/:id/history",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#prototype$__create__history
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Creates a new instance in history of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "prototype$__create__history": {
              url: urlBase + "/SdsRequests/:id/history",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#prototype$__delete__history
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Deletes all history of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__history": {
              url: urlBase + "/SdsRequests/:id/history",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#prototype$__count__history
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Counts history of SdsRequest.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__history": {
              url: urlBase + "/SdsRequests/:id/history/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#find
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SdsRequests",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#count
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SdsRequests/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#requestSds
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * email request an SDS document to be added
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `userId` – `{string=}` -
             *
             *  - `existingSdsId` – `{string=}` - an ID of the SDS if the SDS exists in the system
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "requestSds": {
              url: urlBase + "/SdsRequests/requestSds",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#deny
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * deny the request
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `requestId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `note` – `{string=}` -
             *
             *  - `approverForm` – `{object=}` -
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * returns an object with statusCode
             */
            "deny": {
              url: urlBase + "/SdsRequests/:requestId/deny",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#approve
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * approve the request
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `requestId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `tagIds` – `{string=}` -
             *
             *  - `inventoryIds` – `{string=}` -
             *
             *  - `approverForm` – `{object=}` -
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * returns an object with statusCode
             */
            "approve": {
              url: urlBase + "/SdsRequests/:requestId/approve",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#complete
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * complete the request
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `requestId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * returns an object with statusCode
             */
            "complete": {
              url: urlBase + "/SdsRequests/:requestId/complete",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#deleteRequest
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * delete a request, its files and history
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "deleteRequest": {
              url: urlBase + "/SdsRequests/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#getRequesterFormPdf
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Get approval request form
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `requestId` – `{string}` - a request ID
             *
             *  - `details` – `{object=}` - form details
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsRequest` object.)
             * </em>
             */
            "getRequesterFormPdf": {
              url: urlBase + "/SdsRequests/getRequesterFormPdf",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.SdsRequest#modelName
        * @propertyOf lbServices.SdsRequest
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SdsRequest`.
        */
        R.modelName = "SdsRequest";


            /**
             * @ngdoc method
             * @name lbServices.SdsRequest#company
             * @methodOf lbServices.SdsRequest
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::SdsRequest::company"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.LocationRequest
 * @header lbServices.LocationRequest
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `LocationRequest` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "LocationRequest",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/LocationRequests/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use LocationRequest.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/LocationRequests/:id/company",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#prototype$__get__user
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationRequest` object.)
             * </em>
             */
            "prototype$__get__user": {
              url: urlBase + "/LocationRequests/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#prototype$__findById__history
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Find a related item by id for history.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for history
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationRequest` object.)
             * </em>
             */
            "prototype$__findById__history": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/LocationRequests/:id/history/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#prototype$__destroyById__history
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Delete a related item by id for history.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for history
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__history": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/LocationRequests/:id/history/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#prototype$__updateById__history
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Update a related item by id for history.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for history
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationRequest` object.)
             * </em>
             */
            "prototype$__updateById__history": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/LocationRequests/:id/history/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#prototype$__get__history
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Queries history of LocationRequest.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationRequest` object.)
             * </em>
             */
            "prototype$__get__history": {
              isArray: true,
              url: urlBase + "/LocationRequests/:id/history",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#prototype$__create__history
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Creates a new instance in history of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationRequest` object.)
             * </em>
             */
            "prototype$__create__history": {
              url: urlBase + "/LocationRequests/:id/history",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#prototype$__delete__history
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Deletes all history of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__history": {
              url: urlBase + "/LocationRequests/:id/history",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#prototype$__count__history
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Counts history of LocationRequest.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__history": {
              url: urlBase + "/LocationRequests/:id/history/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#find
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationRequest` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/LocationRequests",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#count
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/LocationRequests/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#request
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * email location request
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `userId` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationRequest` object.)
             * </em>
             */
            "request": {
              url: urlBase + "/LocationRequests/request",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#deny
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * deny the request
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `requestId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `note` – `{string=}` -
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * returns an object with statusCode
             */
            "deny": {
              url: urlBase + "/LocationRequests/:requestId/deny",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#approve
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * approve the request
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `requestId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * returns an object with statusCode
             */
            "approve": {
              url: urlBase + "/LocationRequests/:requestId/approve",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#complete
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * complete the request
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `requestId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` -
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * returns an object with statusCode
             */
            "complete": {
              url: urlBase + "/LocationRequests/:requestId/complete",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#deleteRequest
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * delete a request, its files and history
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationRequest` object.)
             * </em>
             */
            "deleteRequest": {
              url: urlBase + "/LocationRequests/:id",
              method: "DELETE",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.LocationRequest#modelName
        * @propertyOf lbServices.LocationRequest
        * @description
        * The name of the model represented by this $resource,
        * i.e. `LocationRequest`.
        */
        R.modelName = "LocationRequest";


            /**
             * @ngdoc method
             * @name lbServices.LocationRequest#company
             * @methodOf lbServices.LocationRequest
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::LocationRequest::company"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SystemSetting
 * @header lbServices.SystemSetting
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SystemSetting` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SystemSetting",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SystemSettings/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SystemSetting#get
             * @methodOf lbServices.SystemSetting
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemSetting` object.)
             * </em>
             */
            "get": {
              url: urlBase + "/SystemSettings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemSetting#updateNotifications
             * @methodOf lbServices.SystemSetting
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `notifications` – `{*=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemSetting` object.)
             * </em>
             */
            "updateNotifications": {
              url: urlBase + "/SystemSettings/updateNotifications",
              method: "PUT",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.SystemSetting#modelName
        * @propertyOf lbServices.SystemSetting
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SystemSetting`.
        */
        R.modelName = "SystemSetting";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.File
 * @header lbServices.File
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `File` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "File",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Files/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.File#prototype$__get__user
             * @methodOf lbServices.File
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "prototype$__get__user": {
              url: urlBase + "/Files/:id/user",
              method: "GET",
            },

            // INTERNAL. Use File.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/Files/:id/company",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#create
             * @methodOf lbServices.File
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Files",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#createMany
             * @methodOf lbServices.File
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Files",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#upsert
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Files",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#exists
             * @methodOf lbServices.File
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Files/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#findById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Files/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#find
             * @methodOf lbServices.File
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#findOne
             * @methodOf lbServices.File
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Files/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#updateAll
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Files/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#deleteById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Files/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#count
             * @methodOf lbServices.File
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#prototype$updateAttributes
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Files/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#createChangeStream
             * @methodOf lbServices.File
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Files/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Inventory.files.findById() instead.
            "::findById::Inventory::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.files.destroyById() instead.
            "::destroyById::Inventory::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.files.updateById() instead.
            "::updateById::Inventory::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.files.link() instead.
            "::link::Inventory::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.files.unlink() instead.
            "::unlink::Inventory::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.files.exists() instead.
            "::exists::Inventory::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Inventory.files() instead.
            "::get::Inventory::files": {
              isArray: true,
              url: urlBase + "/Inventories/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Inventory.files.create() instead.
            "::create::Inventory::files": {
              url: urlBase + "/Inventories/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Inventory.files.createMany() instead.
            "::createMany::Inventory::files": {
              isArray: true,
              url: urlBase + "/Inventories/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Inventory.files.destroyAll() instead.
            "::delete::Inventory::files": {
              url: urlBase + "/Inventories/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.files.count() instead.
            "::count::Inventory::files": {
              url: urlBase + "/Inventories/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use InventoryFileMapping.file() instead.
            "::get::InventoryFileMapping::file": {
              url: urlBase + "/InventoryFileMappings/:id/file",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.File#updateOrCreate
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.File#update
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.File#destroyById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.File#removeById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.File#modelName
        * @propertyOf lbServices.File
        * @description
        * The name of the model represented by this $resource,
        * i.e. `File`.
        */
        R.modelName = "File";


            /**
             * @ngdoc method
             * @name lbServices.File#company
             * @methodOf lbServices.File
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::File::company"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.BRCode
 * @header lbServices.BRCode
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `BRCode` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "BRCode",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/BRCodes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.BRCode#find
             * @methodOf lbServices.BRCode
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BRCode` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/BRCodes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BRCode#createChangeStream
             * @methodOf lbServices.BRCode
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/BRCodes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BRCode#addBarCode
             * @methodOf lbServices.BRCode
             *
             * @description
             *
             * Add a barcode to DB.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - the bar id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BRCode` object.)
             * </em>
             */
            "addBarCode": {
              url: urlBase + "/BRCodes/addBarCode",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.BRCode#modelName
        * @propertyOf lbServices.BRCode
        * @description
        * The name of the model represented by this $resource,
        * i.e. `BRCode`.
        */
        R.modelName = "BRCode";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.CompanySetting
 * @header lbServices.CompanySetting
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `CompanySetting` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "CompanySetting",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/CompanySettings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use CompanySetting.disposalCodes.findById() instead.
            "prototype$__findById__disposalCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/disposalCodes/:fk",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.destroyById() instead.
            "prototype$__destroyById__disposalCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/disposalCodes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.updateById() instead.
            "prototype$__updateById__disposalCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/disposalCodes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CompanySetting.storageCodes.findById() instead.
            "prototype$__findById__storageCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/storageCodes/:fk",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.storageCodes.destroyById() instead.
            "prototype$__destroyById__storageCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/storageCodes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CompanySetting.storageCodes.updateById() instead.
            "prototype$__updateById__storageCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/storageCodes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CompanySetting.disposalCodes() instead.
            "prototype$__get__disposalCodes": {
              isArray: true,
              url: urlBase + "/CompanySettings/:id/disposalCodes",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.create() instead.
            "prototype$__create__disposalCodes": {
              url: urlBase + "/CompanySettings/:id/disposalCodes",
              method: "POST",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.destroyAll() instead.
            "prototype$__delete__disposalCodes": {
              url: urlBase + "/CompanySettings/:id/disposalCodes",
              method: "DELETE",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.count() instead.
            "prototype$__count__disposalCodes": {
              url: urlBase + "/CompanySettings/:id/disposalCodes/count",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.storageCodes() instead.
            "prototype$__get__storageCodes": {
              isArray: true,
              url: urlBase + "/CompanySettings/:id/storageCodes",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.storageCodes.create() instead.
            "prototype$__create__storageCodes": {
              url: urlBase + "/CompanySettings/:id/storageCodes",
              method: "POST",
            },

            // INTERNAL. Use CompanySetting.storageCodes.destroyAll() instead.
            "prototype$__delete__storageCodes": {
              url: urlBase + "/CompanySettings/:id/storageCodes",
              method: "DELETE",
            },

            // INTERNAL. Use CompanySetting.storageCodes.count() instead.
            "prototype$__count__storageCodes": {
              url: urlBase + "/CompanySettings/:id/storageCodes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#create
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/CompanySettings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#createMany
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/CompanySettings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#upsert
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/CompanySettings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#exists
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/CompanySettings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#findById
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/CompanySettings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#find
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/CompanySettings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#findOne
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/CompanySettings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#updateAll
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/CompanySettings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#deleteById
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/CompanySettings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#count
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/CompanySettings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#prototype$updateAttributes
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/CompanySettings/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#createChangeStream
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/CompanySettings/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#getAttrs
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `req` – `{}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `attrs` – `{object=}` -
             */
            "getAttrs": {
              url: urlBase + "/CompanySettings/:id/attrs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#renameAttrs
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * rename the company custom field (attr) on the all SDSs
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `oldAttrName` – `{string}` - previous name of the company custom field
             *
             *  - `newAttrName` – `{string}` - new name of the company custom field
             *
             *  - `id` – `{string}` - company ID
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * list of doc IDs were updated
             */
            "renameAttrs": {
              isArray: true,
              url: urlBase + "/CompanySettings/:id/attrs",
              method: "PUT",
            },

            // INTERNAL. Use Company.settings() instead.
            "::get::Company::settings": {
              url: urlBase + "/Companies/:id/settings",
              method: "GET",
            },

            // INTERNAL. Use Company.settings.create() instead.
            "::create::Company::settings": {
              url: urlBase + "/Companies/:id/settings",
              method: "POST",
            },

            // INTERNAL. Use Company.settings.createMany() instead.
            "::createMany::Company::settings": {
              isArray: true,
              url: urlBase + "/Companies/:id/settings",
              method: "POST",
            },

            // INTERNAL. Use Company.settings.update() instead.
            "::update::Company::settings": {
              url: urlBase + "/Companies/:id/settings",
              method: "PUT",
            },

            // INTERNAL. Use Company.settings.destroy() instead.
            "::destroy::Company::settings": {
              url: urlBase + "/Companies/:id/settings",
              method: "DELETE",
            },

            // INTERNAL. Use DisposalCodes.companySetting() instead.
            "::get::DisposalCodes::companySetting": {
              url: urlBase + "/DisposalCodes/:id/companySetting",
              method: "GET",
            },

            // INTERNAL. Use StorageCodes.companySetting() instead.
            "::get::StorageCodes::companySetting": {
              url: urlBase + "/StorageCodes/:id/companySetting",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#updateOrCreate
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#update
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#destroyById
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#removeById
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.CompanySetting#modelName
        * @propertyOf lbServices.CompanySetting
        * @description
        * The name of the model represented by this $resource,
        * i.e. `CompanySetting`.
        */
        R.modelName = "CompanySetting";

    /**
     * @ngdoc object
     * @name lbServices.CompanySetting.disposalCodes
     * @header lbServices.CompanySetting.disposalCodes
     * @object
     * @description
     *
     * The object `CompanySetting.disposalCodes` groups methods
     * manipulating `DisposalCodes` instances related to `CompanySetting`.
     *
     * Call {@link lbServices.CompanySetting#disposalCodes CompanySetting.disposalCodes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#disposalCodes
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Queries disposalCodes of CompanySetting.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
        R.disposalCodes = function() {
          var TargetResource = $injector.get("DisposalCodes");
          var action = TargetResource["::get::CompanySetting::disposalCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.disposalCodes#count
             * @methodOf lbServices.CompanySetting.disposalCodes
             *
             * @description
             *
             * Counts disposalCodes of CompanySetting.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.disposalCodes.count = function() {
          var TargetResource = $injector.get("DisposalCodes");
          var action = TargetResource["::count::CompanySetting::disposalCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.disposalCodes#create
             * @methodOf lbServices.CompanySetting.disposalCodes
             *
             * @description
             *
             * Creates a new instance in disposalCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
        R.disposalCodes.create = function() {
          var TargetResource = $injector.get("DisposalCodes");
          var action = TargetResource["::create::CompanySetting::disposalCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.disposalCodes#createMany
             * @methodOf lbServices.CompanySetting.disposalCodes
             *
             * @description
             *
             * Creates a new instance in disposalCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
        R.disposalCodes.createMany = function() {
          var TargetResource = $injector.get("DisposalCodes");
          var action = TargetResource["::createMany::CompanySetting::disposalCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.disposalCodes#destroyAll
             * @methodOf lbServices.CompanySetting.disposalCodes
             *
             * @description
             *
             * Deletes all disposalCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.disposalCodes.destroyAll = function() {
          var TargetResource = $injector.get("DisposalCodes");
          var action = TargetResource["::delete::CompanySetting::disposalCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.disposalCodes#destroyById
             * @methodOf lbServices.CompanySetting.disposalCodes
             *
             * @description
             *
             * Delete a related item by id for disposalCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for disposalCodes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.disposalCodes.destroyById = function() {
          var TargetResource = $injector.get("DisposalCodes");
          var action = TargetResource["::destroyById::CompanySetting::disposalCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.disposalCodes#findById
             * @methodOf lbServices.CompanySetting.disposalCodes
             *
             * @description
             *
             * Find a related item by id for disposalCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for disposalCodes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
        R.disposalCodes.findById = function() {
          var TargetResource = $injector.get("DisposalCodes");
          var action = TargetResource["::findById::CompanySetting::disposalCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.disposalCodes#updateById
             * @methodOf lbServices.CompanySetting.disposalCodes
             *
             * @description
             *
             * Update a related item by id for disposalCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for disposalCodes
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
        R.disposalCodes.updateById = function() {
          var TargetResource = $injector.get("DisposalCodes");
          var action = TargetResource["::updateById::CompanySetting::disposalCodes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.CompanySetting.storageCodes
     * @header lbServices.CompanySetting.storageCodes
     * @object
     * @description
     *
     * The object `CompanySetting.storageCodes` groups methods
     * manipulating `StorageCodes` instances related to `CompanySetting`.
     *
     * Call {@link lbServices.CompanySetting#storageCodes CompanySetting.storageCodes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.CompanySetting#storageCodes
             * @methodOf lbServices.CompanySetting
             *
             * @description
             *
             * Queries storageCodes of CompanySetting.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
        R.storageCodes = function() {
          var TargetResource = $injector.get("StorageCodes");
          var action = TargetResource["::get::CompanySetting::storageCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.storageCodes#count
             * @methodOf lbServices.CompanySetting.storageCodes
             *
             * @description
             *
             * Counts storageCodes of CompanySetting.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.storageCodes.count = function() {
          var TargetResource = $injector.get("StorageCodes");
          var action = TargetResource["::count::CompanySetting::storageCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.storageCodes#create
             * @methodOf lbServices.CompanySetting.storageCodes
             *
             * @description
             *
             * Creates a new instance in storageCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
        R.storageCodes.create = function() {
          var TargetResource = $injector.get("StorageCodes");
          var action = TargetResource["::create::CompanySetting::storageCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.storageCodes#createMany
             * @methodOf lbServices.CompanySetting.storageCodes
             *
             * @description
             *
             * Creates a new instance in storageCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
        R.storageCodes.createMany = function() {
          var TargetResource = $injector.get("StorageCodes");
          var action = TargetResource["::createMany::CompanySetting::storageCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.storageCodes#destroyAll
             * @methodOf lbServices.CompanySetting.storageCodes
             *
             * @description
             *
             * Deletes all storageCodes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.storageCodes.destroyAll = function() {
          var TargetResource = $injector.get("StorageCodes");
          var action = TargetResource["::delete::CompanySetting::storageCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.storageCodes#destroyById
             * @methodOf lbServices.CompanySetting.storageCodes
             *
             * @description
             *
             * Delete a related item by id for storageCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for storageCodes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.storageCodes.destroyById = function() {
          var TargetResource = $injector.get("StorageCodes");
          var action = TargetResource["::destroyById::CompanySetting::storageCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.storageCodes#findById
             * @methodOf lbServices.CompanySetting.storageCodes
             *
             * @description
             *
             * Find a related item by id for storageCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for storageCodes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
        R.storageCodes.findById = function() {
          var TargetResource = $injector.get("StorageCodes");
          var action = TargetResource["::findById::CompanySetting::storageCodes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanySetting.storageCodes#updateById
             * @methodOf lbServices.CompanySetting.storageCodes
             *
             * @description
             *
             * Update a related item by id for storageCodes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for storageCodes
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
        R.storageCodes.updateById = function() {
          var TargetResource = $injector.get("StorageCodes");
          var action = TargetResource["::updateById::CompanySetting::storageCodes"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.IPWhitelist
 * @header lbServices.IPWhitelist
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `IPWhitelist` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "IPWhitelist",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/IPWhitelists/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#create
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/IPWhitelists",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#createMany
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/IPWhitelists",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#upsert
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/IPWhitelists",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#exists
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/IPWhitelists/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#findById
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/IPWhitelists/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#find
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/IPWhitelists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#findOne
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/IPWhitelists/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#updateAll
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/IPWhitelists/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#deleteById
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/IPWhitelists/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#count
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/IPWhitelists/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#prototype$updateAttributes
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/IPWhitelists/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#createChangeStream
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/IPWhitelists/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#isIPallowed
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * 
             *     Check access by IP
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object}` - filter data
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
            "isIPallowed": {
              url: urlBase + "/IPWhitelists/isIPallowed",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#updateOrCreate
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#update
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#destroyById
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.IPWhitelist#removeById
             * @methodOf lbServices.IPWhitelist
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `IPWhitelist` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.IPWhitelist#modelName
        * @propertyOf lbServices.IPWhitelist
        * @description
        * The name of the model represented by this $resource,
        * i.e. `IPWhitelist`.
        */
        R.modelName = "IPWhitelist";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.BannedList
 * @header lbServices.BannedList
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `BannedList` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "BannedList",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/BannedLists/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.BannedList#create
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/BannedLists",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#createMany
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/BannedLists",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#upsert
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/BannedLists",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#exists
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/BannedLists/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#findById
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/BannedLists/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#find
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/BannedLists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#findOne
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/BannedLists/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#updateAll
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/BannedLists/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#deleteById
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/BannedLists/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#count
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/BannedLists/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#prototype$updateAttributes
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/BannedLists/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#createChangeStream
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/BannedLists/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedList#cloneBannedList
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Clone banned list from one company to another. The operation create a "clonedFrom" property to prevent unwanted clones.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `srcCompanyId` – `{string}` - source company Id
             *
             *  - `destCompanyId` – `{string}` - destination company Id
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of new banned list
             */
            "cloneBannedList": {
              isArray: true,
              url: urlBase + "/BannedLists/cloneBannedList",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.BannedList#updateOrCreate
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.BannedList#update
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.BannedList#destroyById
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BannedList#removeById
             * @methodOf lbServices.BannedList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedList` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.BannedList#modelName
        * @propertyOf lbServices.BannedList
        * @description
        * The name of the model represented by this $resource,
        * i.e. `BannedList`.
        */
        R.modelName = "BannedList";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.GoogleAnalytics
 * @header lbServices.GoogleAnalytics
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `GoogleAnalytics` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "GoogleAnalytics",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/GoogleAnalytics/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.GoogleAnalytics#getToken
             * @methodOf lbServices.GoogleAnalytics
             *
             * @description
             *
             * gets the token
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `GoogleAnalytics` object.)
             * </em>
             */
            "getToken": {
              url: urlBase + "/GoogleAnalytics/getToken",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.GoogleAnalytics#getGA4Data
             * @methodOf lbServices.GoogleAnalytics
             *
             * @description
             *
             * gets GA4 Report Data
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object}` - GA4 query filter
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `GoogleAnalytics` object.)
             * </em>
             */
            "getGA4Data": {
              url: urlBase + "/GoogleAnalytics/getGA4Data",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.GoogleAnalytics#modelName
        * @propertyOf lbServices.GoogleAnalytics
        * @description
        * The name of the model represented by this $resource,
        * i.e. `GoogleAnalytics`.
        */
        R.modelName = "GoogleAnalytics";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Equipment
 * @header lbServices.Equipment
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Equipment` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Equipment",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Equipment/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Equipment.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/Equipment/:id/company",
              method: "GET",
            },

            // INTERNAL. Use Equipment.amounts.findById() instead.
            "prototype$__findById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/amounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Equipment.amounts.destroyById() instead.
            "prototype$__destroyById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/amounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.amounts.updateById() instead.
            "prototype$__updateById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/amounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Equipment.inventories.findById() instead.
            "prototype$__findById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use Equipment.inventories.destroyById() instead.
            "prototype$__destroyById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.inventories.updateById() instead.
            "prototype$__updateById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Equipment.inventories.link() instead.
            "prototype$__link__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Equipment.inventories.unlink() instead.
            "prototype$__unlink__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.inventories.exists() instead.
            "prototype$__exists__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Equipment.amounts() instead.
            "prototype$__get__amounts": {
              isArray: true,
              url: urlBase + "/Equipment/:id/amounts",
              method: "GET",
            },

            // INTERNAL. Use Equipment.amounts.create() instead.
            "prototype$__create__amounts": {
              url: urlBase + "/Equipment/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Equipment.amounts.destroyAll() instead.
            "prototype$__delete__amounts": {
              url: urlBase + "/Equipment/:id/amounts",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.amounts.count() instead.
            "prototype$__count__amounts": {
              url: urlBase + "/Equipment/:id/amounts/count",
              method: "GET",
            },

            // INTERNAL. Use Equipment.inventories() instead.
            "prototype$__get__inventories": {
              isArray: true,
              url: urlBase + "/Equipment/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use Equipment.inventories.create() instead.
            "prototype$__create__inventories": {
              url: urlBase + "/Equipment/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Equipment.inventories.destroyAll() instead.
            "prototype$__delete__inventories": {
              url: urlBase + "/Equipment/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.inventories.count() instead.
            "prototype$__count__inventories": {
              url: urlBase + "/Equipment/:id/inventories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#create
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Equipment",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#createMany
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Equipment",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#upsert
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Equipment",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#exists
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Equipment/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#findById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Equipment/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#find
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Equipment",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#findOne
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Equipment/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#updateAll
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Equipment/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#deleteById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Equipment/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#count
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Equipment/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#prototype$updateAttributes
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Equipment/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Equipment#createChangeStream
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Equipment/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Inventory.equipment.findById() instead.
            "::findById::Inventory::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipment.destroyById() instead.
            "::destroyById::Inventory::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipment.updateById() instead.
            "::updateById::Inventory::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.equipment.link() instead.
            "::link::Inventory::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.equipment.unlink() instead.
            "::unlink::Inventory::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipment.exists() instead.
            "::exists::Inventory::equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Inventory.equipment() instead.
            "::get::Inventory::equipment": {
              isArray: true,
              url: urlBase + "/Inventories/:id/equipment",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipment.create() instead.
            "::create::Inventory::equipment": {
              url: urlBase + "/Inventories/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Inventory.equipment.createMany() instead.
            "::createMany::Inventory::equipment": {
              isArray: true,
              url: urlBase + "/Inventories/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Inventory.equipment.destroyAll() instead.
            "::delete::Inventory::equipment": {
              url: urlBase + "/Inventories/:id/equipment",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipment.count() instead.
            "::count::Inventory::equipment": {
              url: urlBase + "/Inventories/:id/equipment/count",
              method: "GET",
            },

            // INTERNAL. Use InventoryEquipmentMapping.equipment() instead.
            "::get::InventoryEquipmentMapping::equipment": {
              url: urlBase + "/InventoryEquipmentMappings/:id/equipment",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Equipment#updateOrCreate
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#update
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#destroyById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Equipment#removeById
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Equipment#modelName
        * @propertyOf lbServices.Equipment
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Equipment`.
        */
        R.modelName = "Equipment";


            /**
             * @ngdoc method
             * @name lbServices.Equipment#company
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::Equipment::company"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Equipment.amounts
     * @header lbServices.Equipment.amounts
     * @object
     * @description
     *
     * The object `Equipment.amounts` groups methods
     * manipulating `InventoryEquipmentMapping` instances related to `Equipment`.
     *
     * Call {@link lbServices.Equipment#amounts Equipment.amounts()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Equipment#amounts
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Queries amounts of Equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.amounts = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::get::Equipment::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.amounts#count
             * @methodOf lbServices.Equipment.amounts
             *
             * @description
             *
             * Counts amounts of Equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.amounts.count = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::count::Equipment::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.amounts#create
             * @methodOf lbServices.Equipment.amounts
             *
             * @description
             *
             * Creates a new instance in amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.amounts.create = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::create::Equipment::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.amounts#createMany
             * @methodOf lbServices.Equipment.amounts
             *
             * @description
             *
             * Creates a new instance in amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.amounts.createMany = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::createMany::Equipment::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.amounts#destroyAll
             * @methodOf lbServices.Equipment.amounts
             *
             * @description
             *
             * Deletes all amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.amounts.destroyAll = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::delete::Equipment::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.amounts#destroyById
             * @methodOf lbServices.Equipment.amounts
             *
             * @description
             *
             * Delete a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.amounts.destroyById = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::destroyById::Equipment::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.amounts#findById
             * @methodOf lbServices.Equipment.amounts
             *
             * @description
             *
             * Find a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.amounts.findById = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::findById::Equipment::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.amounts#updateById
             * @methodOf lbServices.Equipment.amounts
             *
             * @description
             *
             * Update a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.amounts.updateById = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::updateById::Equipment::amounts"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Equipment.inventories
     * @header lbServices.Equipment.inventories
     * @object
     * @description
     *
     * The object `Equipment.inventories` groups methods
     * manipulating `Inventory` instances related to `Equipment`.
     *
     * Call {@link lbServices.Equipment#inventories Equipment.inventories()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Equipment#inventories
             * @methodOf lbServices.Equipment
             *
             * @description
             *
             * Queries inventories of Equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#count
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Counts inventories of Equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.inventories.count = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::count::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#create
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.create = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::create::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#createMany
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.createMany = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::createMany::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#destroyAll
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Deletes all inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyAll = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::delete::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#destroyById
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Delete a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::destroyById::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#exists
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Check the existence of inventories relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.exists = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::exists::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#findById
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Find a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.findById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::findById::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#link
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Add a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.link = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::link::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#unlink
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Remove the inventories relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.unlink = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::unlink::Equipment::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Equipment.inventories#updateById
             * @methodOf lbServices.Equipment.inventories
             *
             * @description
             *
             * Update a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.updateById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::updateById::Equipment::inventories"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Quiz
 * @header lbServices.Quiz
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Quiz` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Quiz",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Quizzes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Quiz.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/Quizzes/:id/company",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#create
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Quizzes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#createMany
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Quizzes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#upsert
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Quizzes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#exists
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Quizzes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#findById
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Quizzes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#find
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Quizzes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#findOne
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Quizzes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#updateAll
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Quizzes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#deleteById
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Quizzes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#count
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Quizzes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#prototype$updateAttributes
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Quizzes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#createChangeStream
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Quizzes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#emailResults
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * email results of the quiz to the teacher
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "emailResults": {
              url: urlBase + "/Quizzes/email-send",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Quiz#sendResultsToClassroom
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Sends results to Google Classroom and creates google doc to attach to these results
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `courseId` – `{string}` -
             *
             *  - `courseWorkId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object}` - Data object with quiz answers
             *
             *  - `req` – `{}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "sendResultsToClassroom": {
              url: urlBase + "/Quizzes/classroom-send",
              method: "POST",
            },

            // INTERNAL. Use Company.quizzes.findById() instead.
            "::findById::Company::quizzes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/quizzes/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.quizzes.destroyById() instead.
            "::destroyById::Company::quizzes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/quizzes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.quizzes.updateById() instead.
            "::updateById::Company::quizzes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/quizzes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.quizzes() instead.
            "::get::Company::quizzes": {
              isArray: true,
              url: urlBase + "/Companies/:id/quizzes",
              method: "GET",
            },

            // INTERNAL. Use Company.quizzes.create() instead.
            "::create::Company::quizzes": {
              url: urlBase + "/Companies/:id/quizzes",
              method: "POST",
            },

            // INTERNAL. Use Company.quizzes.createMany() instead.
            "::createMany::Company::quizzes": {
              isArray: true,
              url: urlBase + "/Companies/:id/quizzes",
              method: "POST",
            },

            // INTERNAL. Use Company.quizzes.destroyAll() instead.
            "::delete::Company::quizzes": {
              url: urlBase + "/Companies/:id/quizzes",
              method: "DELETE",
            },

            // INTERNAL. Use Company.quizzes.count() instead.
            "::count::Company::quizzes": {
              url: urlBase + "/Companies/:id/quizzes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Quiz#updateOrCreate
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Quiz#update
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Quiz#destroyById
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Quiz#removeById
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Quiz` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Quiz#modelName
        * @propertyOf lbServices.Quiz
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Quiz`.
        */
        R.modelName = "Quiz";


            /**
             * @ngdoc method
             * @name lbServices.Quiz#company
             * @methodOf lbServices.Quiz
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::Quiz::company"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.JournalChange
 * @header lbServices.JournalChange
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `JournalChange` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "JournalChange",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/JournalChange/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.JournalChange#prototype$__get__source
             * @methodOf lbServices.JournalChange
             *
             * @description
             *
             * Fetches belongsTo relation source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JournalChange id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JournalChange` object.)
             * </em>
             */
            "prototype$__get__source": {
              url: urlBase + "/JournalChange/:id/source",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JournalChange#prototype$__get__user
             * @methodOf lbServices.JournalChange
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - JournalChange id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JournalChange` object.)
             * </em>
             */
            "prototype$__get__user": {
              url: urlBase + "/JournalChange/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JournalChange#getChanging_SDS_Companies
             * @methodOf lbServices.JournalChange
             *
             * @description
             *
             * get a version control data for SDS companies
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `dateFrom` – `{date=}` - Start date
             *
             *  - `dateTo` – `{date=}` - End date
             *
             *  - `filter` – `{object=}` - Filter for query
             *
             *  - `tagIds` – `{*=}` - Tag IDs to filter
             *
             *  - `inventoryIds` – `{*=}` - Inventory IDs to filter
             *
             *  - `lang` – `{string=}` - UI language
             *
             *  - `availableLanguages` – `{*=}` - Filter SDSs by available languages
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JournalChange` object.)
             * </em>
             */
            "getChanging_SDS_Companies": {
              isArray: true,
              url: urlBase + "/JournalChange/changing_SDS_Companies",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JournalChange#deleteByCompanyId
             * @methodOf lbServices.JournalChange
             *
             * @description
             *
             * Delete all information by company id
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - company id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `respond` – `{undefined=}` -
             */
            "deleteByCompanyId": {
              url: urlBase + "/JournalChange/deleteByCompanyId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.JournalChange#changing
             * @methodOf lbServices.JournalChange
             *
             * @description
             *
             * get a version control data
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `name` – `{string}` - How is this data called
             *
             *  - `filter` – `{object=}` - Filter fro query
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `JournalChange` object.)
             * </em>
             */
            "changing": {
              isArray: true,
              url: urlBase + "/JournalChange/changing",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.JournalChange#modelName
        * @propertyOf lbServices.JournalChange
        * @description
        * The name of the model represented by this $resource,
        * i.e. `JournalChange`.
        */
        R.modelName = "JournalChange";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Email
 * @header lbServices.Email
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Email` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Email",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Emails/:id",
          { 'id': '@id' },
          {
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Email#modelName
        * @propertyOf lbServices.Email
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Email`.
        */
        R.modelName = "Email";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SdsDocumentSolrMap
 * @header lbServices.SdsDocumentSolrMap
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SdsDocumentSolrMap` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SdsDocumentSolrMap",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SdsDocumentsSolrMap/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#create
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SdsDocumentsSolrMap",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#createMany
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SdsDocumentsSolrMap",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#upsert
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SdsDocumentsSolrMap",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#exists
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SdsDocumentsSolrMap/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#findById
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SdsDocumentsSolrMap/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#find
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SdsDocumentsSolrMap",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#findOne
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SdsDocumentsSolrMap/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#updateAll
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/SdsDocumentsSolrMap/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#deleteById
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SdsDocumentsSolrMap/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#count
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SdsDocumentsSolrMap/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#prototype$updateAttributes
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SdsDocumentsSolrMap/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#createChangeStream
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SdsDocumentsSolrMap/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#updateOrCreate
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#update
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#destroyById
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SdsDocumentSolrMap#removeById
             * @methodOf lbServices.SdsDocumentSolrMap
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsDocumentSolrMap` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.SdsDocumentSolrMap#modelName
        * @propertyOf lbServices.SdsDocumentSolrMap
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SdsDocumentSolrMap`.
        */
        R.modelName = "SdsDocumentSolrMap";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DisposalCodes
 * @header lbServices.DisposalCodes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DisposalCodes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DisposalCodes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DisposalCodes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use DisposalCodes.companySetting() instead.
            "prototype$__get__companySetting": {
              url: urlBase + "/DisposalCodes/:id/companySetting",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#create
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DisposalCodes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#createMany
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DisposalCodes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#upsert
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DisposalCodes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#exists
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DisposalCodes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#findById
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DisposalCodes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#find
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DisposalCodes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#findOne
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DisposalCodes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#updateAll
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/DisposalCodes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#deleteById
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DisposalCodes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#count
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DisposalCodes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#prototype$updateAttributes
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DisposalCodes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#createChangeStream
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DisposalCodes/change-stream",
              method: "POST",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.findById() instead.
            "::findById::CompanySetting::disposalCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/disposalCodes/:fk",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.destroyById() instead.
            "::destroyById::CompanySetting::disposalCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/disposalCodes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.updateById() instead.
            "::updateById::CompanySetting::disposalCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/disposalCodes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CompanySetting.disposalCodes() instead.
            "::get::CompanySetting::disposalCodes": {
              isArray: true,
              url: urlBase + "/CompanySettings/:id/disposalCodes",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.create() instead.
            "::create::CompanySetting::disposalCodes": {
              url: urlBase + "/CompanySettings/:id/disposalCodes",
              method: "POST",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.createMany() instead.
            "::createMany::CompanySetting::disposalCodes": {
              isArray: true,
              url: urlBase + "/CompanySettings/:id/disposalCodes",
              method: "POST",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.destroyAll() instead.
            "::delete::CompanySetting::disposalCodes": {
              url: urlBase + "/CompanySettings/:id/disposalCodes",
              method: "DELETE",
            },

            // INTERNAL. Use CompanySetting.disposalCodes.count() instead.
            "::count::CompanySetting::disposalCodes": {
              url: urlBase + "/CompanySettings/:id/disposalCodes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#updateOrCreate
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#update
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#destroyById
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#removeById
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DisposalCodes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.DisposalCodes#modelName
        * @propertyOf lbServices.DisposalCodes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DisposalCodes`.
        */
        R.modelName = "DisposalCodes";


            /**
             * @ngdoc method
             * @name lbServices.DisposalCodes#companySetting
             * @methodOf lbServices.DisposalCodes
             *
             * @description
             *
             * Fetches belongsTo relation companySetting.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R.companySetting = function() {
          var TargetResource = $injector.get("CompanySetting");
          var action = TargetResource["::get::DisposalCodes::companySetting"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.StorageCodes
 * @header lbServices.StorageCodes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `StorageCodes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "StorageCodes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/StorageCodes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use StorageCodes.companySetting() instead.
            "prototype$__get__companySetting": {
              url: urlBase + "/StorageCodes/:id/companySetting",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#create
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/StorageCodes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#createMany
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/StorageCodes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#upsert
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/StorageCodes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#exists
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/StorageCodes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#findById
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/StorageCodes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#find
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/StorageCodes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#findOne
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/StorageCodes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#updateAll
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/StorageCodes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#deleteById
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/StorageCodes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#count
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/StorageCodes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#prototype$updateAttributes
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/StorageCodes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#createChangeStream
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/StorageCodes/change-stream",
              method: "POST",
            },

            // INTERNAL. Use CompanySetting.storageCodes.findById() instead.
            "::findById::CompanySetting::storageCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/storageCodes/:fk",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.storageCodes.destroyById() instead.
            "::destroyById::CompanySetting::storageCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/storageCodes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CompanySetting.storageCodes.updateById() instead.
            "::updateById::CompanySetting::storageCodes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanySettings/:id/storageCodes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CompanySetting.storageCodes() instead.
            "::get::CompanySetting::storageCodes": {
              isArray: true,
              url: urlBase + "/CompanySettings/:id/storageCodes",
              method: "GET",
            },

            // INTERNAL. Use CompanySetting.storageCodes.create() instead.
            "::create::CompanySetting::storageCodes": {
              url: urlBase + "/CompanySettings/:id/storageCodes",
              method: "POST",
            },

            // INTERNAL. Use CompanySetting.storageCodes.createMany() instead.
            "::createMany::CompanySetting::storageCodes": {
              isArray: true,
              url: urlBase + "/CompanySettings/:id/storageCodes",
              method: "POST",
            },

            // INTERNAL. Use CompanySetting.storageCodes.destroyAll() instead.
            "::delete::CompanySetting::storageCodes": {
              url: urlBase + "/CompanySettings/:id/storageCodes",
              method: "DELETE",
            },

            // INTERNAL. Use CompanySetting.storageCodes.count() instead.
            "::count::CompanySetting::storageCodes": {
              url: urlBase + "/CompanySettings/:id/storageCodes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#updateOrCreate
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#update
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#destroyById
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#removeById
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StorageCodes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.StorageCodes#modelName
        * @propertyOf lbServices.StorageCodes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `StorageCodes`.
        */
        R.modelName = "StorageCodes";


            /**
             * @ngdoc method
             * @name lbServices.StorageCodes#companySetting
             * @methodOf lbServices.StorageCodes
             *
             * @description
             *
             * Fetches belongsTo relation companySetting.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanySetting` object.)
             * </em>
             */
        R.companySetting = function() {
          var TargetResource = $injector.get("CompanySetting");
          var action = TargetResource["::get::StorageCodes::companySetting"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Product
 * @header lbServices.Product
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Product` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Product",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Products/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Product.companies.findById() instead.
            "prototype$__findById__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/:fk",
              method: "GET",
            },

            // INTERNAL. Use Product.companies.destroyById() instead.
            "prototype$__destroyById__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.companies.updateById() instead.
            "prototype$__updateById__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.companies.link() instead.
            "prototype$__link__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.companies.unlink() instead.
            "prototype$__unlink__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.companies.exists() instead.
            "prototype$__exists__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/companies/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Product.manufacturer() instead.
            "prototype$__get__manufacturer": {
              url: urlBase + "/Products/:id/manufacturer",
              method: "GET",
            },

            // INTERNAL. Use Product.amounts.findById() instead.
            "prototype$__findById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/amounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Product.amounts.destroyById() instead.
            "prototype$__destroyById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/amounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.amounts.updateById() instead.
            "prototype$__updateById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/amounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.inventories.findById() instead.
            "prototype$__findById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use Product.inventories.destroyById() instead.
            "prototype$__destroyById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventories.updateById() instead.
            "prototype$__updateById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.inventories.link() instead.
            "prototype$__link__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.inventories.unlink() instead.
            "prototype$__unlink__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventories.exists() instead.
            "prototype$__exists__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Product.inventoryContainers.findById() instead.
            "prototype$__findById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventoryContainers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Product.inventoryContainers.destroyById() instead.
            "prototype$__destroyById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventoryContainers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventoryContainers.updateById() instead.
            "prototype$__updateById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventoryContainers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.measure() instead.
            "prototype$__get__measure": {
              url: urlBase + "/Products/:id/measure",
              method: "GET",
            },

            // INTERNAL. Use Product.companies() instead.
            "prototype$__get__companies": {
              isArray: true,
              url: urlBase + "/Products/:id/companies",
              method: "GET",
            },

            // INTERNAL. Use Product.companies.create() instead.
            "prototype$__create__companies": {
              url: urlBase + "/Products/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use Product.companies.destroyAll() instead.
            "prototype$__delete__companies": {
              url: urlBase + "/Products/:id/companies",
              method: "DELETE",
            },

            // INTERNAL. Use Product.companies.count() instead.
            "prototype$__count__companies": {
              url: urlBase + "/Products/:id/companies/count",
              method: "GET",
            },

            // INTERNAL. Use Product.amounts() instead.
            "prototype$__get__amounts": {
              isArray: true,
              url: urlBase + "/Products/:id/amounts",
              method: "GET",
            },

            // INTERNAL. Use Product.amounts.create() instead.
            "prototype$__create__amounts": {
              url: urlBase + "/Products/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Product.amounts.destroyAll() instead.
            "prototype$__delete__amounts": {
              url: urlBase + "/Products/:id/amounts",
              method: "DELETE",
            },

            // INTERNAL. Use Product.amounts.count() instead.
            "prototype$__count__amounts": {
              url: urlBase + "/Products/:id/amounts/count",
              method: "GET",
            },

            // INTERNAL. Use Product.inventories() instead.
            "prototype$__get__inventories": {
              isArray: true,
              url: urlBase + "/Products/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use Product.inventories.create() instead.
            "prototype$__create__inventories": {
              url: urlBase + "/Products/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Product.inventories.destroyAll() instead.
            "prototype$__delete__inventories": {
              url: urlBase + "/Products/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventories.count() instead.
            "prototype$__count__inventories": {
              url: urlBase + "/Products/:id/inventories/count",
              method: "GET",
            },

            // INTERNAL. Use Product.inventoryContainers() instead.
            "prototype$__get__inventoryContainers": {
              isArray: true,
              url: urlBase + "/Products/:id/inventoryContainers",
              method: "GET",
            },

            // INTERNAL. Use Product.inventoryContainers.create() instead.
            "prototype$__create__inventoryContainers": {
              url: urlBase + "/Products/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Product.inventoryContainers.destroyAll() instead.
            "prototype$__delete__inventoryContainers": {
              url: urlBase + "/Products/:id/inventoryContainers",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventoryContainers.count() instead.
            "prototype$__count__inventoryContainers": {
              url: urlBase + "/Products/:id/inventoryContainers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#create
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Products",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#createMany
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Products",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#upsert
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Products",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#exists
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Products/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#findById
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Products/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#find
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Products",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#findOne
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Products/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#updateAll
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Products/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#deleteById
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Products/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#count
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Products/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#prototype$updateAttributes
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Products/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#createChangeStream
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Products/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#updateRelations
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Update product in companies and its amount in inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - product id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an initial product
             */
            "updateRelations": {
              url: urlBase + "/Products/:id/updateRelations",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#search
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Search products by the given string
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `text` – `{String=}` - a string to search on product name
             *
             *  - `filter` – `{Object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             *  - `companyId` – `{string=}` - a company ID to filter by
             *
             *  - `fieldsToSearch` – `{string=}` - fields in Solr to search in
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * response with an array of products and total count
             */
            "search": {
              url: urlBase + "/Products/search",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#updateSdsId
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Adds or removes sdsId from products
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `sdsId` – `{string}` - SDS id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * a count of products were updated
             */
            "updateSdsId": {
              url: urlBase + "/Products/updateSdsId",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#getInventoriesTree
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Create a inventories tree from child to parent from inventories those have the given products
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `productIds` – `{*}` - an array of product IDs
             *
             *  - `filter` – `{Object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of inventories with given products
             */
            "getInventoriesTree": {
              isArray: true,
              url: urlBase + "/Products/inventories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#getPresentInInventory
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Checking, has product in any inventory
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `productIds` – `{*}` - an array of product IDs
             *
             *  - `filter` – `{Object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Products is present in inventory
             */
            "getPresentInInventory": {
              url: urlBase + "/Products/inventoriesIsPresent",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#createCompanyProduct
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Creates an unverified product and add it to the company
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - ID of the company the product is creating for
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * the created product
             */
            "createCompanyProduct": {
              url: urlBase + "/Products/company",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#copyProduct
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Creates an unverified product and add it to the company
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `product` – `{function ModelConstructor(data, options) {
      if (!(this instanceof ModelConstructor)) {
        return new ModelConstructor(data, options);
      }
      if (ModelClass.settings.unresolved) {
        throw new Error('Model ' + ModelClass.modelName + ' is not defined.');
      }
      ModelBaseClass.apply(this, arguments);
    }}` - the product to create
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * the created product
             */
            "copyProduct": {
              url: urlBase + "/Products/copyProduct",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#replaceWithAnotherProduct
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Replaces all relations of the old product with another product and then removes the old one
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `oldProductId` – `{string}` - ID of the product that should be replaced
             *
             *  - `newProductId` – `{string}` - ID of the product that will replace the old one
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * product, which updated the old
             */
            "replaceWithAnotherProduct": {
              url: urlBase + "/Products/replaceWithAnotherProduct",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Product#updateCompanyProductBanning
             * @methodOf lbServices.Product
             *
             * @description
             *
             * 
             *       Updates the status of banning of the given product for the given company.
             *       In other words just adding or removing the company ID from the product's 'bannedInCompanies' property.
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `productId` – `{string}` - a product ID
             *
             *  - `status` – `{boolean}` - a status of banning - "true" to add / "false" to remove
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * updated product
             */
            "updateCompanyProductBanning": {
              url: urlBase + "/Products/:companyId/banned/:productId/:status",
              method: "PUT",
            },

            // INTERNAL. Use Company.products.findById() instead.
            "::findById::Company::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.products.destroyById() instead.
            "::destroyById::Company::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.products.updateById() instead.
            "::updateById::Company::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.products.link() instead.
            "::link::Company::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.products.unlink() instead.
            "::unlink::Company::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.products.exists() instead.
            "::exists::Company::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/products/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Company.products() instead.
            "::get::Company::products": {
              isArray: true,
              url: urlBase + "/Companies/:id/products",
              method: "GET",
            },

            // INTERNAL. Use Company.products.create() instead.
            "::create::Company::products": {
              url: urlBase + "/Companies/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Company.products.createMany() instead.
            "::createMany::Company::products": {
              isArray: true,
              url: urlBase + "/Companies/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Company.products.destroyAll() instead.
            "::delete::Company::products": {
              url: urlBase + "/Companies/:id/products",
              method: "DELETE",
            },

            // INTERNAL. Use Company.products.count() instead.
            "::count::Company::products": {
              url: urlBase + "/Companies/:id/products/count",
              method: "GET",
            },

            // INTERNAL. Use Manufacturer.products.findById() instead.
            "::findById::Manufacturer::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Manufacturers/:id/products/:fk",
              method: "GET",
            },

            // INTERNAL. Use Manufacturer.products.destroyById() instead.
            "::destroyById::Manufacturer::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Manufacturers/:id/products/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manufacturer.products.updateById() instead.
            "::updateById::Manufacturer::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Manufacturers/:id/products/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manufacturer.products() instead.
            "::get::Manufacturer::products": {
              isArray: true,
              url: urlBase + "/Manufacturers/:id/products",
              method: "GET",
            },

            // INTERNAL. Use Manufacturer.products.create() instead.
            "::create::Manufacturer::products": {
              url: urlBase + "/Manufacturers/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Manufacturer.products.createMany() instead.
            "::createMany::Manufacturer::products": {
              isArray: true,
              url: urlBase + "/Manufacturers/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Manufacturer.products.destroyAll() instead.
            "::delete::Manufacturer::products": {
              url: urlBase + "/Manufacturers/:id/products",
              method: "DELETE",
            },

            // INTERNAL. Use Manufacturer.products.count() instead.
            "::count::Manufacturer::products": {
              url: urlBase + "/Manufacturers/:id/products/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.products.findById() instead.
            "::findById::Inventory::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.products.destroyById() instead.
            "::destroyById::Inventory::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.products.updateById() instead.
            "::updateById::Inventory::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.products.link() instead.
            "::link::Inventory::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.products.unlink() instead.
            "::unlink::Inventory::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.products.exists() instead.
            "::exists::Inventory::products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Inventory.products() instead.
            "::get::Inventory::products": {
              isArray: true,
              url: urlBase + "/Inventories/:id/products",
              method: "GET",
            },

            // INTERNAL. Use Inventory.products.create() instead.
            "::create::Inventory::products": {
              url: urlBase + "/Inventories/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Inventory.products.createMany() instead.
            "::createMany::Inventory::products": {
              isArray: true,
              url: urlBase + "/Inventories/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Inventory.products.destroyAll() instead.
            "::delete::Inventory::products": {
              url: urlBase + "/Inventories/:id/products",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.products.count() instead.
            "::count::Inventory::products": {
              url: urlBase + "/Inventories/:id/products/count",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductMapping.product() instead.
            "::get::InventoryProductMapping::product": {
              url: urlBase + "/InventoryProductMappings/:id/product",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.product() instead.
            "::get::InventoryContainer::product": {
              url: urlBase + "/InventoryContainers/:id/product",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductAlert.product() instead.
            "::get::InventoryProductAlert::product": {
              url: urlBase + "/InventoryProductAlerts/:id/product",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Product#updateOrCreate
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Product#update
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Product#destroyById
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Product#removeById
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Product#modelName
        * @propertyOf lbServices.Product
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Product`.
        */
        R.modelName = "Product";

    /**
     * @ngdoc object
     * @name lbServices.Product.companies
     * @header lbServices.Product.companies
     * @object
     * @description
     *
     * The object `Product.companies` groups methods
     * manipulating `Company` instances related to `Product`.
     *
     * Call {@link lbServices.Product#companies Product.companies()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Product#companies
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Queries companies of Product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#count
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Counts companies of Product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.companies.count = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::count::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#create
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Creates a new instance in companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.create = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::create::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#createMany
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Creates a new instance in companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.createMany = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::createMany::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#destroyAll
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Deletes all companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.companies.destroyAll = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::delete::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#destroyById
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Delete a related item by id for companies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.companies.destroyById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::destroyById::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#exists
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Check the existence of companies relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.exists = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::exists::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#findById
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Find a related item by id for companies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.findById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::findById::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#link
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Add a related item by id for companies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.link = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::link::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#unlink
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Remove the companies relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.companies.unlink = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::unlink::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.companies#updateById
             * @methodOf lbServices.Product.companies
             *
             * @description
             *
             * Update a related item by id for companies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.updateById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::updateById::Product::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product#manufacturer
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Fetches belongsTo relation manufacturer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
        R.manufacturer = function() {
          var TargetResource = $injector.get("Manufacturer");
          var action = TargetResource["::get::Product::manufacturer"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Product.amounts
     * @header lbServices.Product.amounts
     * @object
     * @description
     *
     * The object `Product.amounts` groups methods
     * manipulating `InventoryProductMapping` instances related to `Product`.
     *
     * Call {@link lbServices.Product#amounts Product.amounts()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Product#amounts
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Queries amounts of Product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::get::Product::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.amounts#count
             * @methodOf lbServices.Product.amounts
             *
             * @description
             *
             * Counts amounts of Product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.amounts.count = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::count::Product::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.amounts#create
             * @methodOf lbServices.Product.amounts
             *
             * @description
             *
             * Creates a new instance in amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts.create = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::create::Product::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.amounts#createMany
             * @methodOf lbServices.Product.amounts
             *
             * @description
             *
             * Creates a new instance in amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts.createMany = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::createMany::Product::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.amounts#destroyAll
             * @methodOf lbServices.Product.amounts
             *
             * @description
             *
             * Deletes all amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.amounts.destroyAll = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::delete::Product::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.amounts#destroyById
             * @methodOf lbServices.Product.amounts
             *
             * @description
             *
             * Delete a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.amounts.destroyById = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::destroyById::Product::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.amounts#findById
             * @methodOf lbServices.Product.amounts
             *
             * @description
             *
             * Find a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts.findById = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::findById::Product::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.amounts#updateById
             * @methodOf lbServices.Product.amounts
             *
             * @description
             *
             * Update a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts.updateById = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::updateById::Product::amounts"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Product.inventories
     * @header lbServices.Product.inventories
     * @object
     * @description
     *
     * The object `Product.inventories` groups methods
     * manipulating `Inventory` instances related to `Product`.
     *
     * Call {@link lbServices.Product#inventories Product.inventories()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Product#inventories
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Queries inventories of Product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#count
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Counts inventories of Product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.inventories.count = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::count::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#create
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.create = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::create::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#createMany
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.createMany = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::createMany::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#destroyAll
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Deletes all inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyAll = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::delete::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#destroyById
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Delete a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::destroyById::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#exists
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Check the existence of inventories relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.exists = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::exists::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#findById
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Find a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.findById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::findById::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#link
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Add a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.link = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::link::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#unlink
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Remove the inventories relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.unlink = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::unlink::Product::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventories#updateById
             * @methodOf lbServices.Product.inventories
             *
             * @description
             *
             * Update a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.updateById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::updateById::Product::inventories"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Product.inventoryContainers
     * @header lbServices.Product.inventoryContainers
     * @object
     * @description
     *
     * The object `Product.inventoryContainers` groups methods
     * manipulating `InventoryContainer` instances related to `Product`.
     *
     * Call {@link lbServices.Product#inventoryContainers Product.inventoryContainers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Product#inventoryContainers
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Queries inventoryContainers of Product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::get::Product::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventoryContainers#count
             * @methodOf lbServices.Product.inventoryContainers
             *
             * @description
             *
             * Counts inventoryContainers of Product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.inventoryContainers.count = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::count::Product::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventoryContainers#create
             * @methodOf lbServices.Product.inventoryContainers
             *
             * @description
             *
             * Creates a new instance in inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.create = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::create::Product::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventoryContainers#createMany
             * @methodOf lbServices.Product.inventoryContainers
             *
             * @description
             *
             * Creates a new instance in inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.createMany = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::createMany::Product::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventoryContainers#destroyAll
             * @methodOf lbServices.Product.inventoryContainers
             *
             * @description
             *
             * Deletes all inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventoryContainers.destroyAll = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::delete::Product::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventoryContainers#destroyById
             * @methodOf lbServices.Product.inventoryContainers
             *
             * @description
             *
             * Delete a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventoryContainers.destroyById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::destroyById::Product::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventoryContainers#findById
             * @methodOf lbServices.Product.inventoryContainers
             *
             * @description
             *
             * Find a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.findById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::findById::Product::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product.inventoryContainers#updateById
             * @methodOf lbServices.Product.inventoryContainers
             *
             * @description
             *
             * Update a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.updateById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::updateById::Product::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Product#measure
             * @methodOf lbServices.Product
             *
             * @description
             *
             * Fetches belongsTo relation measure.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Measure` object.)
             * </em>
             */
        R.measure = function() {
          var TargetResource = $injector.get("Measure");
          var action = TargetResource["::get::Product::measure"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Manufacturer
 * @header lbServices.Manufacturer
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Manufacturer` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Manufacturer",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Manufacturers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Manufacturer.products.findById() instead.
            "prototype$__findById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Manufacturers/:id/products/:fk",
              method: "GET",
            },

            // INTERNAL. Use Manufacturer.products.destroyById() instead.
            "prototype$__destroyById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Manufacturers/:id/products/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Manufacturer.products.updateById() instead.
            "prototype$__updateById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Manufacturers/:id/products/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Manufacturer.products() instead.
            "prototype$__get__products": {
              isArray: true,
              url: urlBase + "/Manufacturers/:id/products",
              method: "GET",
            },

            // INTERNAL. Use Manufacturer.products.create() instead.
            "prototype$__create__products": {
              url: urlBase + "/Manufacturers/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Manufacturer.products.destroyAll() instead.
            "prototype$__delete__products": {
              url: urlBase + "/Manufacturers/:id/products",
              method: "DELETE",
            },

            // INTERNAL. Use Manufacturer.products.count() instead.
            "prototype$__count__products": {
              url: urlBase + "/Manufacturers/:id/products/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#create
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Manufacturers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#createMany
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Manufacturers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#upsert
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Manufacturers",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#exists
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Manufacturers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#findById
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Manufacturers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#find
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Manufacturers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#findOne
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Manufacturers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#updateAll
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Manufacturers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#deleteById
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Manufacturers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#count
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Manufacturers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#prototype$updateAttributes
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Manufacturers/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#createChangeStream
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Manufacturers/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Product.manufacturer() instead.
            "::get::Product::manufacturer": {
              url: urlBase + "/Products/:id/manufacturer",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#updateOrCreate
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#update
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#destroyById
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#removeById
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Manufacturer` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Manufacturer#modelName
        * @propertyOf lbServices.Manufacturer
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Manufacturer`.
        */
        R.modelName = "Manufacturer";

    /**
     * @ngdoc object
     * @name lbServices.Manufacturer.products
     * @header lbServices.Manufacturer.products
     * @object
     * @description
     *
     * The object `Manufacturer.products` groups methods
     * manipulating `Product` instances related to `Manufacturer`.
     *
     * Call {@link lbServices.Manufacturer#products Manufacturer.products()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Manufacturer#products
             * @methodOf lbServices.Manufacturer
             *
             * @description
             *
             * Queries products of Manufacturer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::get::Manufacturer::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer.products#count
             * @methodOf lbServices.Manufacturer.products
             *
             * @description
             *
             * Counts products of Manufacturer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.products.count = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::count::Manufacturer::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer.products#create
             * @methodOf lbServices.Manufacturer.products
             *
             * @description
             *
             * Creates a new instance in products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.create = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::create::Manufacturer::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer.products#createMany
             * @methodOf lbServices.Manufacturer.products
             *
             * @description
             *
             * Creates a new instance in products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.createMany = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::createMany::Manufacturer::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer.products#destroyAll
             * @methodOf lbServices.Manufacturer.products
             *
             * @description
             *
             * Deletes all products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.products.destroyAll = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::delete::Manufacturer::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer.products#destroyById
             * @methodOf lbServices.Manufacturer.products
             *
             * @description
             *
             * Delete a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.products.destroyById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::destroyById::Manufacturer::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer.products#findById
             * @methodOf lbServices.Manufacturer.products
             *
             * @description
             *
             * Find a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.findById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::findById::Manufacturer::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Manufacturer.products#updateById
             * @methodOf lbServices.Manufacturer.products
             *
             * @description
             *
             * Update a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.updateById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::updateById::Manufacturer::products"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Inventory
 * @header lbServices.Inventory
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Inventory` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Inventory",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Inventories/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Inventory.parentInventory() instead.
            "prototype$__get__parentInventory": {
              url: urlBase + "/Inventories/:id/parentInventory",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventories.findById() instead.
            "prototype$__findById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventories.destroyById() instead.
            "prototype$__destroyById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventories.updateById() instead.
            "prototype$__updateById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/Inventories/:id/company",
              method: "GET",
            },

            // INTERNAL. Use Inventory.amounts.findById() instead.
            "prototype$__findById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/amounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.amounts.destroyById() instead.
            "prototype$__destroyById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/amounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.amounts.updateById() instead.
            "prototype$__updateById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/amounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.products.findById() instead.
            "prototype$__findById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.products.destroyById() instead.
            "prototype$__destroyById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.products.updateById() instead.
            "prototype$__updateById__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.products.link() instead.
            "prototype$__link__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.products.unlink() instead.
            "prototype$__unlink__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.products.exists() instead.
            "prototype$__exists__products": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/products/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Inventory.containerAmounts.findById() instead.
            "prototype$__findById__containerAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/containerAmounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.containerAmounts.destroyById() instead.
            "prototype$__destroyById__containerAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/containerAmounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.containerAmounts.updateById() instead.
            "prototype$__updateById__containerAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/containerAmounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.inventoryContainers.findById() instead.
            "prototype$__findById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventoryContainers.destroyById() instead.
            "prototype$__destroyById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventoryContainers.updateById() instead.
            "prototype$__updateById__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.inventoryContainers.link() instead.
            "prototype$__link__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.inventoryContainers.unlink() instead.
            "prototype$__unlink__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventoryContainers.exists() instead.
            "prototype$__exists__inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.findById() instead.
            "prototype$__findById__equipmentAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipmentAmounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.destroyById() instead.
            "prototype$__destroyById__equipmentAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipmentAmounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.updateById() instead.
            "prototype$__updateById__equipmentAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipmentAmounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.equipment.findById() instead.
            "prototype$__findById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipment.destroyById() instead.
            "prototype$__destroyById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipment.updateById() instead.
            "prototype$__updateById__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.equipment.link() instead.
            "prototype$__link__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.equipment.unlink() instead.
            "prototype$__unlink__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipment.exists() instead.
            "prototype$__exists__equipment": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipment/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Inventory.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.files.link() instead.
            "prototype$__link__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.files.unlink() instead.
            "prototype$__unlink__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.files.exists() instead.
            "prototype$__exists__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/files/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Inventory.inventories() instead.
            "prototype$__get__inventories": {
              isArray: true,
              url: urlBase + "/Inventories/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventories.create() instead.
            "prototype$__create__inventories": {
              url: urlBase + "/Inventories/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Inventory.inventories.destroyAll() instead.
            "prototype$__delete__inventories": {
              url: urlBase + "/Inventories/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventories.count() instead.
            "prototype$__count__inventories": {
              url: urlBase + "/Inventories/:id/inventories/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.amounts() instead.
            "prototype$__get__amounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/amounts",
              method: "GET",
            },

            // INTERNAL. Use Inventory.amounts.create() instead.
            "prototype$__create__amounts": {
              url: urlBase + "/Inventories/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.amounts.destroyAll() instead.
            "prototype$__delete__amounts": {
              url: urlBase + "/Inventories/:id/amounts",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.amounts.count() instead.
            "prototype$__count__amounts": {
              url: urlBase + "/Inventories/:id/amounts/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.products() instead.
            "prototype$__get__products": {
              isArray: true,
              url: urlBase + "/Inventories/:id/products",
              method: "GET",
            },

            // INTERNAL. Use Inventory.products.create() instead.
            "prototype$__create__products": {
              url: urlBase + "/Inventories/:id/products",
              method: "POST",
            },

            // INTERNAL. Use Inventory.products.destroyAll() instead.
            "prototype$__delete__products": {
              url: urlBase + "/Inventories/:id/products",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.products.count() instead.
            "prototype$__count__products": {
              url: urlBase + "/Inventories/:id/products/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.containerAmounts() instead.
            "prototype$__get__containerAmounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/containerAmounts",
              method: "GET",
            },

            // INTERNAL. Use Inventory.containerAmounts.create() instead.
            "prototype$__create__containerAmounts": {
              url: urlBase + "/Inventories/:id/containerAmounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.containerAmounts.destroyAll() instead.
            "prototype$__delete__containerAmounts": {
              url: urlBase + "/Inventories/:id/containerAmounts",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.containerAmounts.count() instead.
            "prototype$__count__containerAmounts": {
              url: urlBase + "/Inventories/:id/containerAmounts/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventoryContainers() instead.
            "prototype$__get__inventoryContainers": {
              isArray: true,
              url: urlBase + "/Inventories/:id/inventoryContainers",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventoryContainers.create() instead.
            "prototype$__create__inventoryContainers": {
              url: urlBase + "/Inventories/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Inventory.inventoryContainers.destroyAll() instead.
            "prototype$__delete__inventoryContainers": {
              url: urlBase + "/Inventories/:id/inventoryContainers",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventoryContainers.count() instead.
            "prototype$__count__inventoryContainers": {
              url: urlBase + "/Inventories/:id/inventoryContainers/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipmentAmounts() instead.
            "prototype$__get__equipmentAmounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/equipmentAmounts",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.create() instead.
            "prototype$__create__equipmentAmounts": {
              url: urlBase + "/Inventories/:id/equipmentAmounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.destroyAll() instead.
            "prototype$__delete__equipmentAmounts": {
              url: urlBase + "/Inventories/:id/equipmentAmounts",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.count() instead.
            "prototype$__count__equipmentAmounts": {
              url: urlBase + "/Inventories/:id/equipmentAmounts/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipment() instead.
            "prototype$__get__equipment": {
              isArray: true,
              url: urlBase + "/Inventories/:id/equipment",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipment.create() instead.
            "prototype$__create__equipment": {
              url: urlBase + "/Inventories/:id/equipment",
              method: "POST",
            },

            // INTERNAL. Use Inventory.equipment.destroyAll() instead.
            "prototype$__delete__equipment": {
              url: urlBase + "/Inventories/:id/equipment",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipment.count() instead.
            "prototype$__count__equipment": {
              url: urlBase + "/Inventories/:id/equipment/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Inventories/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Inventory.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Inventories/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Inventory.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Inventories/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Inventories/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#create
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Inventories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#createMany
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Inventories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#upsert
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Inventories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#exists
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Inventories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#findById
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Inventories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#find
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Inventories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#findOne
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Inventories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#updateAll
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Inventories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#deleteById
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Inventories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#count
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Inventories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#prototype$updateAttributes
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Inventories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#createChangeStream
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Inventories/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getSortedInventories
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source and sorted as tree.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             *  - `additionalRelations` – `{string=}` - an array with relations name
             *
             *  - `thresholds` – `{boolean=}` - add thresholds calculations
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of inventories sorted as tree
             */
            "getSortedInventories": {
              isArray: true,
              url: urlBase + "/Inventories/getSortedInventories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getTreeInfo
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Find all instances with path information matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "getTreeInfo": {
              url: urlBase + "/Inventories/getTreeInfo",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#wclSchedulerRun
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Perform Scheduler Run
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `recipients` – `{string}` - recipients
             *
             *  - `unique` – `{string}` - any unique value
             *
             *  - `hash` – `{string}` - data hash sign
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "wclSchedulerRun": {
              url: urlBase + "/Inventories/wclSchedulerRun",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getWCLreport
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Get WCL Report data
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `lang` – `{string=}` - selected language
             *
             *  - `offset` – `{number=}` - offset
             *
             *  - `limit` – `{number=}` - limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "getWCLreport": {
              url: urlBase + "/Inventories/getWCLreport",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getWCLexcel
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Get WCL Report Excel File
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `lang` – `{string=}` - selected language
             *
             *  - `offset` – `{number=}` - offset
             *
             *  - `limit` – `{number=}` - limit
             *
             *  - `inventoryIds` – `{string=}` - Inventory to filter by
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "getWCLexcel": {
              url: urlBase + "/Inventories/getWCLexcel",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getWCLpdf
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Get WCL Report PDF File
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `lang` – `{string=}` - selected language
             *
             *  - `inventoryIds` – `{string=}` - Inventory to filter by
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "getWCLpdf": {
              url: urlBase + "/Inventories/getWCLpdf",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getInventoryCERS
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Find all instances with thresholds of the model matched by filter from the data source and sorted as tree.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             *  - `additionalRelations` – `{string=}` - an array with relations name
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Object with inventory SDS information and an array of inventories sorted as tree
             */
            "getInventoryCERS": {
              isArray: true,
              url: urlBase + "/Inventories/getInventoryCERS",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#updateMultipleInventories
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Update an array of given inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of updated inventories
             */
            "updateMultipleInventories": {
              isArray: true,
              url: urlBase + "/Inventories/updateMultipleInventories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getContentItemsNumbersInParentInventory
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Get number of content items in the parent inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `inventories` – `{*}` - an array of inventory ids
             *
             *  - `relationName` – `{string}` - the name of the relation which items will be content of inventories,
             *                     this relations must be lying "through" another model and have a "keyThrough" property
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of content items
             */
            "getContentItemsNumbersInParentInventory": {
              isArray: true,
              url: urlBase + "/Inventories/contentItemsInParent",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#requestContentItemsInInventories
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Get content items and their amount from the inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of content items with amount
             */
            "requestContentItemsInInventories": {
              isArray: true,
              url: urlBase + "/Inventories/requestContentItemsInInventories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getContentItemsInInventory
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Get content items and their amount from the inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `inventoryId` – `{string}` - the inventory Id
             *
             *  - `relationName` – `{string}` - the name of the relation which items will be content of inventories,
             *                     this relations must be lying "through" another model and have a "keyThrough" property
             *
             *  - `tagIds` – `{string=}` - an array of tags IDs to filter products
             *
             *  - `andOrStrategy` – `{string=}` - a strategy to include ALL (and) or any (or) tags
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of content items with amount
             */
            "getContentItemsInInventory": {
              isArray: true,
              url: urlBase + "/Inventories/contentItems",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#updateAmountOfItemsInInventories
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Update items amount in given inventory
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `relationName` – `{string}` - the name of the relation which items will be content of inventories,
             *                     this relations must be lying "through" another model and have a "keyThrough" property
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array with results of the update
             */
            "updateAmountOfItemsInInventories": {
              isArray: true,
              url: urlBase + "/Inventories/items/inventories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#saveAlertSettings
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Sets new alert settings
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `newAlertSettingsInventoryId` – `{string}` - an ID of an inventory that has new alerts settings
             *
             *  - `prevAlertSettingsInventoryId` – `{string=}` - an ID of the inventory that has previous alerts settings
             *
             *  - `alertType` – `{string=}` - type of the alerts
             *
             *  - `shouldCreateAlerts` – `{boolean=}` - determines to create alerts or not
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an object with updated data
             */
            "saveAlertSettings": {
              url: urlBase + "/Inventories/saveAlertSettings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#removeInventoryAlerts
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * 
             *       Remove alerts for affected inventory,
             *       when inventory grouping that doesn't have alertsEnabled moved out from the inventory grouping that has
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `movedInventoryId` – `{string}` - an ID of the moved inventory
             *
             *  - `alertSettingsInventoryId` – `{string}` - an ID of the parent grouping inventory with alertsEnabled
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array with counts of the removed alerts
             */
            "removeInventoryAlerts": {
              isArray: true,
              url: urlBase + "/Inventories/removeInventoryAlerts",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#updateSettingsLink
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Update the alertSettingsInventoryId for the alerts and delete the alerts with alertThreshold lower than the new one or create new alerts
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `previousSettingsId` – `{string}` - previous inventory ID which was set as alertSettingsInventoryId
             *
             *  - `newSettingsId` – `{string}` - new inventory ID which should be set as alertSettingsInventoryId
             *
             *  - `alertType` – `{string=}` - type of the alerts
             *
             *  - `alertThreshold` – `{object}` - the alertThreshold of the new inventory with alert settings
             *
             *  - `companyId` – `{string}` - ID of the company the alerts will be set for
             *
             *  - `inventoryId` – `{string=}` - if this parameter is set the alerts only for this inventory will be updated/deleted/created
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of the updated/created alerts
             */
            "updateSettingsLink": {
              isArray: true,
              url: urlBase + "/Inventories/updateSettingsLink",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#removeAlertsBySettingsId
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Removes all alerts by the given alertSettingsInventoryId
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `alertSettingsInventoryId` – `{string}` - an ID of the inventory with the alert settings
             *
             *  - `alertType` – `{string=}` - type of the alerts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an object with the count of deleted entries
             */
            "removeAlertsBySettingsId": {
              url: urlBase + "/Inventories/removeAlertsBySettingsId",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Inventory#getWithProductIds
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * get all company's inventory with productIds field
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
            "getWithProductIds": {
              isArray: true,
              url: urlBase + "/Inventories/getWithProductIds",
              method: "GET",
            },

            // INTERNAL. Use Company.inventories.findById() instead.
            "::findById::Company::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.inventories.destroyById() instead.
            "::destroyById::Company::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventories.updateById() instead.
            "::updateById::Company::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.inventories() instead.
            "::get::Company::inventories": {
              isArray: true,
              url: urlBase + "/Companies/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use Company.inventories.create() instead.
            "::create::Company::inventories": {
              url: urlBase + "/Companies/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Company.inventories.createMany() instead.
            "::createMany::Company::inventories": {
              isArray: true,
              url: urlBase + "/Companies/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Company.inventories.destroyAll() instead.
            "::delete::Company::inventories": {
              url: urlBase + "/Companies/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventories.count() instead.
            "::count::Company::inventories": {
              url: urlBase + "/Companies/:id/inventories/count",
              method: "GET",
            },

            // INTERNAL. Use Equipment.inventories.findById() instead.
            "::findById::Equipment::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use Equipment.inventories.destroyById() instead.
            "::destroyById::Equipment::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.inventories.updateById() instead.
            "::updateById::Equipment::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Equipment.inventories.link() instead.
            "::link::Equipment::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Equipment.inventories.unlink() instead.
            "::unlink::Equipment::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.inventories.exists() instead.
            "::exists::Equipment::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/inventories/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Equipment.inventories() instead.
            "::get::Equipment::inventories": {
              isArray: true,
              url: urlBase + "/Equipment/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use Equipment.inventories.create() instead.
            "::create::Equipment::inventories": {
              url: urlBase + "/Equipment/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Equipment.inventories.createMany() instead.
            "::createMany::Equipment::inventories": {
              isArray: true,
              url: urlBase + "/Equipment/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Equipment.inventories.destroyAll() instead.
            "::delete::Equipment::inventories": {
              url: urlBase + "/Equipment/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.inventories.count() instead.
            "::count::Equipment::inventories": {
              url: urlBase + "/Equipment/:id/inventories/count",
              method: "GET",
            },

            // INTERNAL. Use Product.inventories.findById() instead.
            "::findById::Product::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use Product.inventories.destroyById() instead.
            "::destroyById::Product::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventories.updateById() instead.
            "::updateById::Product::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.inventories.link() instead.
            "::link::Product::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.inventories.unlink() instead.
            "::unlink::Product::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventories.exists() instead.
            "::exists::Product::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventories/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Product.inventories() instead.
            "::get::Product::inventories": {
              isArray: true,
              url: urlBase + "/Products/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use Product.inventories.create() instead.
            "::create::Product::inventories": {
              url: urlBase + "/Products/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Product.inventories.createMany() instead.
            "::createMany::Product::inventories": {
              isArray: true,
              url: urlBase + "/Products/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Product.inventories.destroyAll() instead.
            "::delete::Product::inventories": {
              url: urlBase + "/Products/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventories.count() instead.
            "::count::Product::inventories": {
              url: urlBase + "/Products/:id/inventories/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.parentInventory() instead.
            "::get::Inventory::parentInventory": {
              url: urlBase + "/Inventories/:id/parentInventory",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventories.findById() instead.
            "::findById::Inventory::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventories.destroyById() instead.
            "::destroyById::Inventory::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventories.updateById() instead.
            "::updateById::Inventory::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.inventories() instead.
            "::get::Inventory::inventories": {
              isArray: true,
              url: urlBase + "/Inventories/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventories.create() instead.
            "::create::Inventory::inventories": {
              url: urlBase + "/Inventories/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Inventory.inventories.createMany() instead.
            "::createMany::Inventory::inventories": {
              isArray: true,
              url: urlBase + "/Inventories/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use Inventory.inventories.destroyAll() instead.
            "::delete::Inventory::inventories": {
              url: urlBase + "/Inventories/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventories.count() instead.
            "::count::Inventory::inventories": {
              url: urlBase + "/Inventories/:id/inventories/count",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductMapping.inventory() instead.
            "::get::InventoryProductMapping::inventory": {
              url: urlBase + "/InventoryProductMappings/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use DenyToInventory.inventory() instead.
            "::get::DenyToInventory::inventory": {
              url: urlBase + "/DenyToInventories/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryReview.inventory() instead.
            "::get::InventoryReview::inventory": {
              url: urlBase + "/InventoryReviews/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.inventories.findById() instead.
            "::findById::InventoryContainer::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.inventories.destroyById() instead.
            "::destroyById::InventoryContainer::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.inventories.updateById() instead.
            "::updateById::InventoryContainer::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use InventoryContainer.inventories.link() instead.
            "::link::InventoryContainer::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use InventoryContainer.inventories.unlink() instead.
            "::unlink::InventoryContainer::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.inventories.exists() instead.
            "::exists::InventoryContainer::inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use InventoryContainer.inventories() instead.
            "::get::InventoryContainer::inventories": {
              isArray: true,
              url: urlBase + "/InventoryContainers/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.inventories.create() instead.
            "::create::InventoryContainer::inventories": {
              url: urlBase + "/InventoryContainers/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use InventoryContainer.inventories.createMany() instead.
            "::createMany::InventoryContainer::inventories": {
              isArray: true,
              url: urlBase + "/InventoryContainers/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use InventoryContainer.inventories.destroyAll() instead.
            "::delete::InventoryContainer::inventories": {
              url: urlBase + "/InventoryContainers/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.inventories.count() instead.
            "::count::InventoryContainer::inventories": {
              url: urlBase + "/InventoryContainers/:id/inventories/count",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainerMapping.inventory() instead.
            "::get::InventoryContainerMapping::inventory": {
              url: urlBase + "/InventoryContainerMappings/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductAlert.alertSettings() instead.
            "::get::InventoryProductAlert::alertSettings": {
              url: urlBase + "/InventoryProductAlerts/:id/alertSettings",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductAlert.inventory() instead.
            "::get::InventoryProductAlert::inventory": {
              url: urlBase + "/InventoryProductAlerts/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryEquipmentMapping.inventory() instead.
            "::get::InventoryEquipmentMapping::inventory": {
              url: urlBase + "/InventoryEquipmentMappings/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryFileMapping.inventory() instead.
            "::get::InventoryFileMapping::inventory": {
              url: urlBase + "/InventoryFileMappings/:id/inventory",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Inventory#updateOrCreate
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Inventory#update
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Inventory#destroyById
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Inventory#removeById
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Inventory#modelName
        * @propertyOf lbServices.Inventory
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Inventory`.
        */
        R.modelName = "Inventory";


            /**
             * @ngdoc method
             * @name lbServices.Inventory#parentInventory
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Fetches belongsTo relation parentInventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.parentInventory = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::Inventory::parentInventory"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Inventory.inventories
     * @header lbServices.Inventory.inventories
     * @object
     * @description
     *
     * The object `Inventory.inventories` groups methods
     * manipulating `Inventory` instances related to `Inventory`.
     *
     * Call {@link lbServices.Inventory#inventories Inventory.inventories()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Inventory#inventories
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Queries inventories of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::Inventory::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventories#count
             * @methodOf lbServices.Inventory.inventories
             *
             * @description
             *
             * Counts inventories of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.inventories.count = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::count::Inventory::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventories#create
             * @methodOf lbServices.Inventory.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.create = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::create::Inventory::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventories#createMany
             * @methodOf lbServices.Inventory.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.createMany = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::createMany::Inventory::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventories#destroyAll
             * @methodOf lbServices.Inventory.inventories
             *
             * @description
             *
             * Deletes all inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyAll = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::delete::Inventory::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventories#destroyById
             * @methodOf lbServices.Inventory.inventories
             *
             * @description
             *
             * Delete a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::destroyById::Inventory::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventories#findById
             * @methodOf lbServices.Inventory.inventories
             *
             * @description
             *
             * Find a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.findById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::findById::Inventory::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventories#updateById
             * @methodOf lbServices.Inventory.inventories
             *
             * @description
             *
             * Update a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.updateById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::updateById::Inventory::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory#company
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::Inventory::company"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Inventory.amounts
     * @header lbServices.Inventory.amounts
     * @object
     * @description
     *
     * The object `Inventory.amounts` groups methods
     * manipulating `InventoryProductMapping` instances related to `Inventory`.
     *
     * Call {@link lbServices.Inventory#amounts Inventory.amounts()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Inventory#amounts
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Queries amounts of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::get::Inventory::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.amounts#count
             * @methodOf lbServices.Inventory.amounts
             *
             * @description
             *
             * Counts amounts of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.amounts.count = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::count::Inventory::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.amounts#create
             * @methodOf lbServices.Inventory.amounts
             *
             * @description
             *
             * Creates a new instance in amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts.create = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::create::Inventory::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.amounts#createMany
             * @methodOf lbServices.Inventory.amounts
             *
             * @description
             *
             * Creates a new instance in amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts.createMany = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::createMany::Inventory::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.amounts#destroyAll
             * @methodOf lbServices.Inventory.amounts
             *
             * @description
             *
             * Deletes all amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.amounts.destroyAll = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::delete::Inventory::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.amounts#destroyById
             * @methodOf lbServices.Inventory.amounts
             *
             * @description
             *
             * Delete a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.amounts.destroyById = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::destroyById::Inventory::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.amounts#findById
             * @methodOf lbServices.Inventory.amounts
             *
             * @description
             *
             * Find a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts.findById = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::findById::Inventory::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.amounts#updateById
             * @methodOf lbServices.Inventory.amounts
             *
             * @description
             *
             * Update a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R.amounts.updateById = function() {
          var TargetResource = $injector.get("InventoryProductMapping");
          var action = TargetResource["::updateById::Inventory::amounts"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Inventory.products
     * @header lbServices.Inventory.products
     * @object
     * @description
     *
     * The object `Inventory.products` groups methods
     * manipulating `Product` instances related to `Inventory`.
     *
     * Call {@link lbServices.Inventory#products Inventory.products()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Inventory#products
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Queries products of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::get::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#count
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Counts products of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.products.count = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::count::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#create
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Creates a new instance in products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.create = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::create::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#createMany
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Creates a new instance in products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.createMany = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::createMany::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#destroyAll
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Deletes all products of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.products.destroyAll = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::delete::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#destroyById
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Delete a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.products.destroyById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::destroyById::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#exists
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Check the existence of products relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.exists = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::exists::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#findById
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Find a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.findById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::findById::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#link
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Add a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.link = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::link::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#unlink
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Remove the products relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.products.unlink = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::unlink::Inventory::products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.products#updateById
             * @methodOf lbServices.Inventory.products
             *
             * @description
             *
             * Update a related item by id for products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for products
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.products.updateById = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::updateById::Inventory::products"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Inventory.containerAmounts
     * @header lbServices.Inventory.containerAmounts
     * @object
     * @description
     *
     * The object `Inventory.containerAmounts` groups methods
     * manipulating `InventoryContainerMapping` instances related to `Inventory`.
     *
     * Call {@link lbServices.Inventory#containerAmounts Inventory.containerAmounts()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Inventory#containerAmounts
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Queries containerAmounts of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.containerAmounts = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::get::Inventory::containerAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.containerAmounts#count
             * @methodOf lbServices.Inventory.containerAmounts
             *
             * @description
             *
             * Counts containerAmounts of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.containerAmounts.count = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::count::Inventory::containerAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.containerAmounts#create
             * @methodOf lbServices.Inventory.containerAmounts
             *
             * @description
             *
             * Creates a new instance in containerAmounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.containerAmounts.create = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::create::Inventory::containerAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.containerAmounts#createMany
             * @methodOf lbServices.Inventory.containerAmounts
             *
             * @description
             *
             * Creates a new instance in containerAmounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.containerAmounts.createMany = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::createMany::Inventory::containerAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.containerAmounts#destroyAll
             * @methodOf lbServices.Inventory.containerAmounts
             *
             * @description
             *
             * Deletes all containerAmounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.containerAmounts.destroyAll = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::delete::Inventory::containerAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.containerAmounts#destroyById
             * @methodOf lbServices.Inventory.containerAmounts
             *
             * @description
             *
             * Delete a related item by id for containerAmounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for containerAmounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.containerAmounts.destroyById = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::destroyById::Inventory::containerAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.containerAmounts#findById
             * @methodOf lbServices.Inventory.containerAmounts
             *
             * @description
             *
             * Find a related item by id for containerAmounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for containerAmounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.containerAmounts.findById = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::findById::Inventory::containerAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.containerAmounts#updateById
             * @methodOf lbServices.Inventory.containerAmounts
             *
             * @description
             *
             * Update a related item by id for containerAmounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for containerAmounts
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.containerAmounts.updateById = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::updateById::Inventory::containerAmounts"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Inventory.inventoryContainers
     * @header lbServices.Inventory.inventoryContainers
     * @object
     * @description
     *
     * The object `Inventory.inventoryContainers` groups methods
     * manipulating `InventoryContainer` instances related to `Inventory`.
     *
     * Call {@link lbServices.Inventory#inventoryContainers Inventory.inventoryContainers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Inventory#inventoryContainers
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Queries inventoryContainers of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::get::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#count
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Counts inventoryContainers of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.inventoryContainers.count = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::count::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#create
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Creates a new instance in inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.create = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::create::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#createMany
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Creates a new instance in inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.createMany = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::createMany::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#destroyAll
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Deletes all inventoryContainers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventoryContainers.destroyAll = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::delete::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#destroyById
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Delete a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventoryContainers.destroyById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::destroyById::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#exists
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Check the existence of inventoryContainers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.exists = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::exists::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#findById
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Find a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.findById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::findById::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#link
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Add a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.link = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::link::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#unlink
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Remove the inventoryContainers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventoryContainers.unlink = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::unlink::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.inventoryContainers#updateById
             * @methodOf lbServices.Inventory.inventoryContainers
             *
             * @description
             *
             * Update a related item by id for inventoryContainers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventoryContainers
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainers.updateById = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::updateById::Inventory::inventoryContainers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Inventory.equipmentAmounts
     * @header lbServices.Inventory.equipmentAmounts
     * @object
     * @description
     *
     * The object `Inventory.equipmentAmounts` groups methods
     * manipulating `InventoryEquipmentMapping` instances related to `Inventory`.
     *
     * Call {@link lbServices.Inventory#equipmentAmounts Inventory.equipmentAmounts()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Inventory#equipmentAmounts
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Queries equipmentAmounts of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.equipmentAmounts = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::get::Inventory::equipmentAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipmentAmounts#count
             * @methodOf lbServices.Inventory.equipmentAmounts
             *
             * @description
             *
             * Counts equipmentAmounts of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.equipmentAmounts.count = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::count::Inventory::equipmentAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipmentAmounts#create
             * @methodOf lbServices.Inventory.equipmentAmounts
             *
             * @description
             *
             * Creates a new instance in equipmentAmounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.equipmentAmounts.create = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::create::Inventory::equipmentAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipmentAmounts#createMany
             * @methodOf lbServices.Inventory.equipmentAmounts
             *
             * @description
             *
             * Creates a new instance in equipmentAmounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.equipmentAmounts.createMany = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::createMany::Inventory::equipmentAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipmentAmounts#destroyAll
             * @methodOf lbServices.Inventory.equipmentAmounts
             *
             * @description
             *
             * Deletes all equipmentAmounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipmentAmounts.destroyAll = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::delete::Inventory::equipmentAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipmentAmounts#destroyById
             * @methodOf lbServices.Inventory.equipmentAmounts
             *
             * @description
             *
             * Delete a related item by id for equipmentAmounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipmentAmounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipmentAmounts.destroyById = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::destroyById::Inventory::equipmentAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipmentAmounts#findById
             * @methodOf lbServices.Inventory.equipmentAmounts
             *
             * @description
             *
             * Find a related item by id for equipmentAmounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipmentAmounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.equipmentAmounts.findById = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::findById::Inventory::equipmentAmounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipmentAmounts#updateById
             * @methodOf lbServices.Inventory.equipmentAmounts
             *
             * @description
             *
             * Update a related item by id for equipmentAmounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipmentAmounts
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R.equipmentAmounts.updateById = function() {
          var TargetResource = $injector.get("InventoryEquipmentMapping");
          var action = TargetResource["::updateById::Inventory::equipmentAmounts"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Inventory.equipment
     * @header lbServices.Inventory.equipment
     * @object
     * @description
     *
     * The object `Inventory.equipment` groups methods
     * manipulating `Equipment` instances related to `Inventory`.
     *
     * Call {@link lbServices.Inventory#equipment Inventory.equipment()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Inventory#equipment
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Queries equipment of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::get::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#count
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Counts equipment of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.equipment.count = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::count::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#create
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Creates a new instance in equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.create = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::create::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#createMany
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Creates a new instance in equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.createMany = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::createMany::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#destroyAll
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Deletes all equipment of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipment.destroyAll = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::delete::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#destroyById
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Delete a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipment.destroyById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::destroyById::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#exists
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Check the existence of equipment relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.exists = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::exists::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#findById
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Find a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.findById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::findById::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#link
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Add a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.link = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::link::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#unlink
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Remove the equipment relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.equipment.unlink = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::unlink::Inventory::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.equipment#updateById
             * @methodOf lbServices.Inventory.equipment
             *
             * @description
             *
             * Update a related item by id for equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for equipment
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment.updateById = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::updateById::Inventory::equipment"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Inventory.files
     * @header lbServices.Inventory.files
     * @object
     * @description
     *
     * The object `Inventory.files` groups methods
     * manipulating `File` instances related to `Inventory`.
     *
     * Call {@link lbServices.Inventory#files Inventory.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Inventory#files
             * @methodOf lbServices.Inventory
             *
             * @description
             *
             * Queries files of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#count
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Counts files of Inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::count::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#create
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::create::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#createMany
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::createMany::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#destroyAll
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::delete::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#destroyById
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::destroyById::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#exists
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Check the existence of files relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.exists = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::exists::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#findById
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::findById::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#link
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Add a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.link = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::link::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#unlink
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Remove the files relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.unlink = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::unlink::Inventory::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Inventory.files#updateById
             * @methodOf lbServices.Inventory.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::updateById::Inventory::files"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InventoryProductMapping
 * @header lbServices.InventoryProductMapping
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InventoryProductMapping` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InventoryProductMapping",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InventoryProductMappings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InventoryProductMapping.product() instead.
            "prototype$__get__product": {
              url: urlBase + "/InventoryProductMappings/:id/product",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductMapping.inventory() instead.
            "prototype$__get__inventory": {
              url: urlBase + "/InventoryProductMappings/:id/inventory",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#create
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InventoryProductMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#createMany
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InventoryProductMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#upsert
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InventoryProductMappings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#exists
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InventoryProductMappings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#findById
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InventoryProductMappings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#find
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InventoryProductMappings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#findOne
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InventoryProductMappings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#updateAll
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/InventoryProductMappings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#deleteById
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InventoryProductMappings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#count
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InventoryProductMappings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#prototype$updateAttributes
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InventoryProductMappings/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#createChangeStream
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InventoryProductMappings/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Product.amounts.findById() instead.
            "::findById::Product::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/amounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Product.amounts.destroyById() instead.
            "::destroyById::Product::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/amounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.amounts.updateById() instead.
            "::updateById::Product::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/amounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.amounts() instead.
            "::get::Product::amounts": {
              isArray: true,
              url: urlBase + "/Products/:id/amounts",
              method: "GET",
            },

            // INTERNAL. Use Product.amounts.create() instead.
            "::create::Product::amounts": {
              url: urlBase + "/Products/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Product.amounts.createMany() instead.
            "::createMany::Product::amounts": {
              isArray: true,
              url: urlBase + "/Products/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Product.amounts.destroyAll() instead.
            "::delete::Product::amounts": {
              url: urlBase + "/Products/:id/amounts",
              method: "DELETE",
            },

            // INTERNAL. Use Product.amounts.count() instead.
            "::count::Product::amounts": {
              url: urlBase + "/Products/:id/amounts/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.amounts.findById() instead.
            "::findById::Inventory::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/amounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.amounts.destroyById() instead.
            "::destroyById::Inventory::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/amounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.amounts.updateById() instead.
            "::updateById::Inventory::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/amounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.amounts() instead.
            "::get::Inventory::amounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/amounts",
              method: "GET",
            },

            // INTERNAL. Use Inventory.amounts.create() instead.
            "::create::Inventory::amounts": {
              url: urlBase + "/Inventories/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.amounts.createMany() instead.
            "::createMany::Inventory::amounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.amounts.destroyAll() instead.
            "::delete::Inventory::amounts": {
              url: urlBase + "/Inventories/:id/amounts",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.amounts.count() instead.
            "::count::Inventory::amounts": {
              url: urlBase + "/Inventories/:id/amounts/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#updateOrCreate
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#update
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#destroyById
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#removeById
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductMapping` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.InventoryProductMapping#modelName
        * @propertyOf lbServices.InventoryProductMapping
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InventoryProductMapping`.
        */
        R.modelName = "InventoryProductMapping";


            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#product
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Fetches belongsTo relation product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.product = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::get::InventoryProductMapping::product"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductMapping#inventory
             * @methodOf lbServices.InventoryProductMapping
             *
             * @description
             *
             * Fetches belongsTo relation inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventory = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::InventoryProductMapping::inventory"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SystemAudit
 * @header lbServices.SystemAudit
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SystemAudit` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SystemAudit",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SystemAudits/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#prototype$__get__user
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "prototype$__get__user": {
              url: urlBase + "/SystemAudits/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#create
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SystemAudits",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#createMany
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SystemAudits",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#upsert
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SystemAudits",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#exists
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SystemAudits/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#findById
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SystemAudits/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#find
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SystemAudits",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#findOne
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SystemAudits/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#updateAll
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/SystemAudits/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#deleteById
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SystemAudits/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#count
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SystemAudits/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#prototype$updateAttributes
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SystemAudits/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#createChangeStream
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SystemAudits/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#clear
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * removes all audit entries
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * a count of audits were removed
             */
            "clear": {
              url: urlBase + "/SystemAudits/clear",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#updateOrCreate
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#update
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#destroyById
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAudit#removeById
             * @methodOf lbServices.SystemAudit
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAudit` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.SystemAudit#modelName
        * @propertyOf lbServices.SystemAudit
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SystemAudit`.
        */
        R.modelName = "SystemAudit";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Attachment
 * @header lbServices.Attachment
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Attachment` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Attachment",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Attachments/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Attachment#create
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Attachments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#createMany
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Attachments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#upsert
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Attachments",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#exists
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Attachments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#findById
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Attachments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#find
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Attachments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#findOne
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Attachments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#updateAll
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Attachments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#deleteById
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Attachments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#count
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Attachments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#prototype$updateAttributes
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Attachments/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#createChangeStream
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Attachments/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#updateForCompany
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Update an attachment information in company
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - attachment id
             *
             *  - `company` – `{object}` - object to update
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `updated` – `{undefined=}` -
             */
            "updateForCompany": {
              url: urlBase + "/Attachments/updateForCompany",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#makeMarksForCompanies
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Update an attachment information in company
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` - attachment id
             *
             *  - `companies` – `{*}` - array of company
             *
             *  - `req` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `updated` – `{undefined=}` -
             */
            "makeMarksForCompanies": {
              url: urlBase + "/Attachments/makeMarksForCompanies",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Attachment#download
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Download attached file
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` - attachment id
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
            "download": {
              url: urlBase + "/Attachments/download/:id",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Attachment#updateOrCreate
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Attachment#update
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Attachment#destroyById
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Attachment#removeById
             * @methodOf lbServices.Attachment
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Attachment` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Attachment#modelName
        * @propertyOf lbServices.Attachment
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Attachment`.
        */
        R.modelName = "Attachment";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DenyToInventory
 * @header lbServices.DenyToInventory
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DenyToInventory` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DenyToInventory",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DenyToInventories/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#prototype$__get__user
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "prototype$__get__user": {
              url: urlBase + "/DenyToInventories/:id/user",
              method: "GET",
            },

            // INTERNAL. Use DenyToInventory.inventory() instead.
            "prototype$__get__inventory": {
              url: urlBase + "/DenyToInventories/:id/inventory",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#create
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DenyToInventories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#createMany
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DenyToInventories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#upsert
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DenyToInventories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#exists
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DenyToInventories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#findById
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DenyToInventories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#find
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DenyToInventories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#findOne
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DenyToInventories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#updateAll
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/DenyToInventories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#deleteById
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DenyToInventories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#count
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DenyToInventories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#prototype$updateAttributes
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DenyToInventories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#createChangeStream
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DenyToInventories/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#updateMany
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `where` – `{*}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `updated` – `{undefined=}` -
             */
            "updateMany": {
              url: urlBase + "/DenyToInventories/updateMany",
              method: "PUT",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#updateOrCreate
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#update
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#destroyById
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#removeById
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DenyToInventory` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.DenyToInventory#modelName
        * @propertyOf lbServices.DenyToInventory
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DenyToInventory`.
        */
        R.modelName = "DenyToInventory";


            /**
             * @ngdoc method
             * @name lbServices.DenyToInventory#inventory
             * @methodOf lbServices.DenyToInventory
             *
             * @description
             *
             * Fetches belongsTo relation inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventory = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::DenyToInventory::inventory"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.BannedSearchJournal
 * @header lbServices.BannedSearchJournal
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `BannedSearchJournal` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "BannedSearchJournal",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/BannedSearchJournals/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.BannedSearchJournal#find
             * @methodOf lbServices.BannedSearchJournal
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BannedSearchJournal` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/BannedSearchJournals",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BannedSearchJournal#count
             * @methodOf lbServices.BannedSearchJournal
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/BannedSearchJournals/count",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.BannedSearchJournal#modelName
        * @propertyOf lbServices.BannedSearchJournal
        * @description
        * The name of the model represented by this $resource,
        * i.e. `BannedSearchJournal`.
        */
        R.modelName = "BannedSearchJournal";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InventoryReview
 * @header lbServices.InventoryReview
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InventoryReview` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InventoryReview",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InventoryReviews/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InventoryReview.inventory() instead.
            "prototype$__get__inventory": {
              url: urlBase + "/InventoryReviews/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryReview.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/InventoryReviews/:id/company",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#create
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InventoryReviews",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#createMany
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InventoryReviews",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#upsert
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InventoryReviews",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#exists
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InventoryReviews/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#findById
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InventoryReviews/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#find
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InventoryReviews",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#findOne
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InventoryReviews/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#updateAll
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/InventoryReviews/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#deleteById
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InventoryReviews/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#count
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InventoryReviews/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#prototype$updateAttributes
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InventoryReviews/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#createChangeStream
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InventoryReviews/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#getLatestReviews
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Get latest inventory reviews by company ID
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID to get reviews from
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an array of the latest reviews
             */
            "getLatestReviews": {
              isArray: true,
              url: urlBase + "/InventoryReviews/latest",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#updateOrCreate
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#update
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#destroyById
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#removeById
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryReview` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.InventoryReview#modelName
        * @propertyOf lbServices.InventoryReview
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InventoryReview`.
        */
        R.modelName = "InventoryReview";


            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#inventory
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Fetches belongsTo relation inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventory = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::InventoryReview::inventory"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryReview#company
             * @methodOf lbServices.InventoryReview
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::InventoryReview::company"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InventoryContainer
 * @header lbServices.InventoryContainer
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InventoryContainer` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InventoryContainer",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InventoryContainers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InventoryContainer.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/InventoryContainers/:id/company",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.product() instead.
            "prototype$__get__product": {
              url: urlBase + "/InventoryContainers/:id/product",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.amounts.findById() instead.
            "prototype$__findById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/amounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.amounts.destroyById() instead.
            "prototype$__destroyById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/amounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.amounts.updateById() instead.
            "prototype$__updateById__amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/amounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use InventoryContainer.inventories.findById() instead.
            "prototype$__findById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/:fk",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.inventories.destroyById() instead.
            "prototype$__destroyById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.inventories.updateById() instead.
            "prototype$__updateById__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/:fk",
              method: "PUT",
            },

            // INTERNAL. Use InventoryContainer.inventories.link() instead.
            "prototype$__link__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use InventoryContainer.inventories.unlink() instead.
            "prototype$__unlink__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.inventories.exists() instead.
            "prototype$__exists__inventories": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/inventories/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use InventoryContainer.amounts() instead.
            "prototype$__get__amounts": {
              isArray: true,
              url: urlBase + "/InventoryContainers/:id/amounts",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.amounts.create() instead.
            "prototype$__create__amounts": {
              url: urlBase + "/InventoryContainers/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use InventoryContainer.amounts.destroyAll() instead.
            "prototype$__delete__amounts": {
              url: urlBase + "/InventoryContainers/:id/amounts",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.amounts.count() instead.
            "prototype$__count__amounts": {
              url: urlBase + "/InventoryContainers/:id/amounts/count",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.inventories() instead.
            "prototype$__get__inventories": {
              isArray: true,
              url: urlBase + "/InventoryContainers/:id/inventories",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.inventories.create() instead.
            "prototype$__create__inventories": {
              url: urlBase + "/InventoryContainers/:id/inventories",
              method: "POST",
            },

            // INTERNAL. Use InventoryContainer.inventories.destroyAll() instead.
            "prototype$__delete__inventories": {
              url: urlBase + "/InventoryContainers/:id/inventories",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.inventories.count() instead.
            "prototype$__count__inventories": {
              url: urlBase + "/InventoryContainers/:id/inventories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#create
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InventoryContainers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#createMany
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InventoryContainers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#upsert
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InventoryContainers",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#exists
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InventoryContainers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#findById
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InventoryContainers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#find
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InventoryContainers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#findOne
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InventoryContainers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#updateAll
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/InventoryContainers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#deleteById
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InventoryContainers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#count
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InventoryContainers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#prototype$updateAttributes
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InventoryContainers/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#createChangeStream
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InventoryContainers/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Company.inventoryContainers.findById() instead.
            "::findById::Company::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Company.inventoryContainers.destroyById() instead.
            "::destroyById::Company::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventoryContainers.updateById() instead.
            "::updateById::Company::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.inventoryContainers.link() instead.
            "::link::Company::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Company.inventoryContainers.unlink() instead.
            "::unlink::Company::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventoryContainers.exists() instead.
            "::exists::Company::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Companies/:id/inventoryContainers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Company.inventoryContainers() instead.
            "::get::Company::inventoryContainers": {
              isArray: true,
              url: urlBase + "/Companies/:id/inventoryContainers",
              method: "GET",
            },

            // INTERNAL. Use Company.inventoryContainers.create() instead.
            "::create::Company::inventoryContainers": {
              url: urlBase + "/Companies/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Company.inventoryContainers.createMany() instead.
            "::createMany::Company::inventoryContainers": {
              isArray: true,
              url: urlBase + "/Companies/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Company.inventoryContainers.destroyAll() instead.
            "::delete::Company::inventoryContainers": {
              url: urlBase + "/Companies/:id/inventoryContainers",
              method: "DELETE",
            },

            // INTERNAL. Use Company.inventoryContainers.count() instead.
            "::count::Company::inventoryContainers": {
              url: urlBase + "/Companies/:id/inventoryContainers/count",
              method: "GET",
            },

            // INTERNAL. Use Product.inventoryContainers.findById() instead.
            "::findById::Product::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventoryContainers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Product.inventoryContainers.destroyById() instead.
            "::destroyById::Product::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventoryContainers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventoryContainers.updateById() instead.
            "::updateById::Product::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Products/:id/inventoryContainers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Product.inventoryContainers() instead.
            "::get::Product::inventoryContainers": {
              isArray: true,
              url: urlBase + "/Products/:id/inventoryContainers",
              method: "GET",
            },

            // INTERNAL. Use Product.inventoryContainers.create() instead.
            "::create::Product::inventoryContainers": {
              url: urlBase + "/Products/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Product.inventoryContainers.createMany() instead.
            "::createMany::Product::inventoryContainers": {
              isArray: true,
              url: urlBase + "/Products/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Product.inventoryContainers.destroyAll() instead.
            "::delete::Product::inventoryContainers": {
              url: urlBase + "/Products/:id/inventoryContainers",
              method: "DELETE",
            },

            // INTERNAL. Use Product.inventoryContainers.count() instead.
            "::count::Product::inventoryContainers": {
              url: urlBase + "/Products/:id/inventoryContainers/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventoryContainers.findById() instead.
            "::findById::Inventory::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventoryContainers.destroyById() instead.
            "::destroyById::Inventory::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventoryContainers.updateById() instead.
            "::updateById::Inventory::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.inventoryContainers.link() instead.
            "::link::Inventory::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.inventoryContainers.unlink() instead.
            "::unlink::Inventory::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventoryContainers.exists() instead.
            "::exists::Inventory::inventoryContainers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/inventoryContainers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Inventory.inventoryContainers() instead.
            "::get::Inventory::inventoryContainers": {
              isArray: true,
              url: urlBase + "/Inventories/:id/inventoryContainers",
              method: "GET",
            },

            // INTERNAL. Use Inventory.inventoryContainers.create() instead.
            "::create::Inventory::inventoryContainers": {
              url: urlBase + "/Inventories/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Inventory.inventoryContainers.createMany() instead.
            "::createMany::Inventory::inventoryContainers": {
              isArray: true,
              url: urlBase + "/Inventories/:id/inventoryContainers",
              method: "POST",
            },

            // INTERNAL. Use Inventory.inventoryContainers.destroyAll() instead.
            "::delete::Inventory::inventoryContainers": {
              url: urlBase + "/Inventories/:id/inventoryContainers",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.inventoryContainers.count() instead.
            "::count::Inventory::inventoryContainers": {
              url: urlBase + "/Inventories/:id/inventoryContainers/count",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainerMapping.inventoryContainer() instead.
            "::get::InventoryContainerMapping::inventoryContainer": {
              url: urlBase + "/InventoryContainerMappings/:id/inventoryContainer",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#updateOrCreate
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#update
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#destroyById
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#removeById
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.InventoryContainer#modelName
        * @propertyOf lbServices.InventoryContainer
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InventoryContainer`.
        */
        R.modelName = "InventoryContainer";


            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#company
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::InventoryContainer::company"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#product
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Fetches belongsTo relation product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.product = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::get::InventoryContainer::product"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.InventoryContainer.amounts
     * @header lbServices.InventoryContainer.amounts
     * @object
     * @description
     *
     * The object `InventoryContainer.amounts` groups methods
     * manipulating `InventoryContainerMapping` instances related to `InventoryContainer`.
     *
     * Call {@link lbServices.InventoryContainer#amounts InventoryContainer.amounts()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#amounts
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Queries amounts of InventoryContainer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.amounts = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::get::InventoryContainer::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.amounts#count
             * @methodOf lbServices.InventoryContainer.amounts
             *
             * @description
             *
             * Counts amounts of InventoryContainer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.amounts.count = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::count::InventoryContainer::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.amounts#create
             * @methodOf lbServices.InventoryContainer.amounts
             *
             * @description
             *
             * Creates a new instance in amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.amounts.create = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::create::InventoryContainer::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.amounts#createMany
             * @methodOf lbServices.InventoryContainer.amounts
             *
             * @description
             *
             * Creates a new instance in amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.amounts.createMany = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::createMany::InventoryContainer::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.amounts#destroyAll
             * @methodOf lbServices.InventoryContainer.amounts
             *
             * @description
             *
             * Deletes all amounts of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.amounts.destroyAll = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::delete::InventoryContainer::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.amounts#destroyById
             * @methodOf lbServices.InventoryContainer.amounts
             *
             * @description
             *
             * Delete a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.amounts.destroyById = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::destroyById::InventoryContainer::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.amounts#findById
             * @methodOf lbServices.InventoryContainer.amounts
             *
             * @description
             *
             * Find a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.amounts.findById = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::findById::InventoryContainer::amounts"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.amounts#updateById
             * @methodOf lbServices.InventoryContainer.amounts
             *
             * @description
             *
             * Update a related item by id for amounts.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for amounts
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R.amounts.updateById = function() {
          var TargetResource = $injector.get("InventoryContainerMapping");
          var action = TargetResource["::updateById::InventoryContainer::amounts"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.InventoryContainer.inventories
     * @header lbServices.InventoryContainer.inventories
     * @object
     * @description
     *
     * The object `InventoryContainer.inventories` groups methods
     * manipulating `Inventory` instances related to `InventoryContainer`.
     *
     * Call {@link lbServices.InventoryContainer#inventories InventoryContainer.inventories()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer#inventories
             * @methodOf lbServices.InventoryContainer
             *
             * @description
             *
             * Queries inventories of InventoryContainer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#count
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Counts inventories of InventoryContainer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.inventories.count = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::count::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#create
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.create = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::create::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#createMany
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Creates a new instance in inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.createMany = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::createMany::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#destroyAll
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Deletes all inventories of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyAll = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::delete::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#destroyById
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Delete a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.destroyById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::destroyById::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#exists
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Check the existence of inventories relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.exists = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::exists::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#findById
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Find a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.findById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::findById::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#link
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Add a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.link = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::link::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#unlink
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Remove the inventories relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.inventories.unlink = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::unlink::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainer.inventories#updateById
             * @methodOf lbServices.InventoryContainer.inventories
             *
             * @description
             *
             * Update a related item by id for inventories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for inventories
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventories.updateById = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::updateById::InventoryContainer::inventories"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InventoryContainerMapping
 * @header lbServices.InventoryContainerMapping
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InventoryContainerMapping` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InventoryContainerMapping",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InventoryContainerMappings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InventoryContainerMapping.inventory() instead.
            "prototype$__get__inventory": {
              url: urlBase + "/InventoryContainerMappings/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainerMapping.inventoryContainer() instead.
            "prototype$__get__inventoryContainer": {
              url: urlBase + "/InventoryContainerMappings/:id/inventoryContainer",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#create
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InventoryContainerMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#createMany
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InventoryContainerMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#upsert
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InventoryContainerMappings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#exists
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InventoryContainerMappings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#findById
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InventoryContainerMappings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#find
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InventoryContainerMappings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#findOne
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InventoryContainerMappings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#updateAll
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/InventoryContainerMappings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#deleteById
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InventoryContainerMappings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#count
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InventoryContainerMappings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#prototype$updateAttributes
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InventoryContainerMappings/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#createChangeStream
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InventoryContainerMappings/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Inventory.containerAmounts.findById() instead.
            "::findById::Inventory::containerAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/containerAmounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.containerAmounts.destroyById() instead.
            "::destroyById::Inventory::containerAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/containerAmounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.containerAmounts.updateById() instead.
            "::updateById::Inventory::containerAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/containerAmounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.containerAmounts() instead.
            "::get::Inventory::containerAmounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/containerAmounts",
              method: "GET",
            },

            // INTERNAL. Use Inventory.containerAmounts.create() instead.
            "::create::Inventory::containerAmounts": {
              url: urlBase + "/Inventories/:id/containerAmounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.containerAmounts.createMany() instead.
            "::createMany::Inventory::containerAmounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/containerAmounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.containerAmounts.destroyAll() instead.
            "::delete::Inventory::containerAmounts": {
              url: urlBase + "/Inventories/:id/containerAmounts",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.containerAmounts.count() instead.
            "::count::Inventory::containerAmounts": {
              url: urlBase + "/Inventories/:id/containerAmounts/count",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.amounts.findById() instead.
            "::findById::InventoryContainer::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/amounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.amounts.destroyById() instead.
            "::destroyById::InventoryContainer::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/amounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.amounts.updateById() instead.
            "::updateById::InventoryContainer::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InventoryContainers/:id/amounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use InventoryContainer.amounts() instead.
            "::get::InventoryContainer::amounts": {
              isArray: true,
              url: urlBase + "/InventoryContainers/:id/amounts",
              method: "GET",
            },

            // INTERNAL. Use InventoryContainer.amounts.create() instead.
            "::create::InventoryContainer::amounts": {
              url: urlBase + "/InventoryContainers/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use InventoryContainer.amounts.createMany() instead.
            "::createMany::InventoryContainer::amounts": {
              isArray: true,
              url: urlBase + "/InventoryContainers/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use InventoryContainer.amounts.destroyAll() instead.
            "::delete::InventoryContainer::amounts": {
              url: urlBase + "/InventoryContainers/:id/amounts",
              method: "DELETE",
            },

            // INTERNAL. Use InventoryContainer.amounts.count() instead.
            "::count::InventoryContainer::amounts": {
              url: urlBase + "/InventoryContainers/:id/amounts/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#updateOrCreate
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#update
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#destroyById
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#removeById
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainerMapping` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.InventoryContainerMapping#modelName
        * @propertyOf lbServices.InventoryContainerMapping
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InventoryContainerMapping`.
        */
        R.modelName = "InventoryContainerMapping";


            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#inventory
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Fetches belongsTo relation inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventory = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::InventoryContainerMapping::inventory"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryContainerMapping#inventoryContainer
             * @methodOf lbServices.InventoryContainerMapping
             *
             * @description
             *
             * Fetches belongsTo relation inventoryContainer.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryContainer` object.)
             * </em>
             */
        R.inventoryContainer = function() {
          var TargetResource = $injector.get("InventoryContainer");
          var action = TargetResource["::get::InventoryContainerMapping::inventoryContainer"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InventoryProductAlert
 * @header lbServices.InventoryProductAlert
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InventoryProductAlert` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InventoryProductAlert",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InventoryProductAlerts/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InventoryProductAlert.alertSettings() instead.
            "prototype$__get__alertSettings": {
              url: urlBase + "/InventoryProductAlerts/:id/alertSettings",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductAlert.inventory() instead.
            "prototype$__get__inventory": {
              url: urlBase + "/InventoryProductAlerts/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductAlert.product() instead.
            "prototype$__get__product": {
              url: urlBase + "/InventoryProductAlerts/:id/product",
              method: "GET",
            },

            // INTERNAL. Use InventoryProductAlert.company() instead.
            "prototype$__get__company": {
              url: urlBase + "/InventoryProductAlerts/:id/company",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#create
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InventoryProductAlerts",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#createMany
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InventoryProductAlerts",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#upsert
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InventoryProductAlerts",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#exists
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InventoryProductAlerts/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#findById
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InventoryProductAlerts/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#find
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InventoryProductAlerts",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#findOne
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InventoryProductAlerts/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#updateAll
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/InventoryProductAlerts/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#deleteById
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InventoryProductAlerts/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#count
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InventoryProductAlerts/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#prototype$updateAttributes
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InventoryProductAlerts/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#createChangeStream
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InventoryProductAlerts/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#updateOrCreate
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#update
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#destroyById
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#removeById
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryProductAlert` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.InventoryProductAlert#modelName
        * @propertyOf lbServices.InventoryProductAlert
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InventoryProductAlert`.
        */
        R.modelName = "InventoryProductAlert";


            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#alertSettings
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Fetches belongsTo relation alertSettings.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.alertSettings = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::InventoryProductAlert::alertSettings"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#inventory
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Fetches belongsTo relation inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventory = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::InventoryProductAlert::inventory"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#product
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Fetches belongsTo relation product.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Product` object.)
             * </em>
             */
        R.product = function() {
          var TargetResource = $injector.get("Product");
          var action = TargetResource["::get::InventoryProductAlert::product"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryProductAlert#company
             * @methodOf lbServices.InventoryProductAlert
             *
             * @description
             *
             * Fetches belongsTo relation company.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.company = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::InventoryProductAlert::company"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InventoryEquipmentMapping
 * @header lbServices.InventoryEquipmentMapping
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InventoryEquipmentMapping` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InventoryEquipmentMapping",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InventoryEquipmentMappings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InventoryEquipmentMapping.equipment() instead.
            "prototype$__get__equipment": {
              url: urlBase + "/InventoryEquipmentMappings/:id/equipment",
              method: "GET",
            },

            // INTERNAL. Use InventoryEquipmentMapping.inventory() instead.
            "prototype$__get__inventory": {
              url: urlBase + "/InventoryEquipmentMappings/:id/inventory",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#create
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InventoryEquipmentMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#createMany
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InventoryEquipmentMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#upsert
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InventoryEquipmentMappings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#exists
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InventoryEquipmentMappings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#findById
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InventoryEquipmentMappings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#find
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InventoryEquipmentMappings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#findOne
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InventoryEquipmentMappings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#updateAll
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/InventoryEquipmentMappings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#deleteById
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InventoryEquipmentMappings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#count
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InventoryEquipmentMappings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#prototype$updateAttributes
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InventoryEquipmentMappings/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#createChangeStream
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InventoryEquipmentMappings/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Equipment.amounts.findById() instead.
            "::findById::Equipment::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/amounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Equipment.amounts.destroyById() instead.
            "::destroyById::Equipment::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/amounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.amounts.updateById() instead.
            "::updateById::Equipment::amounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Equipment/:id/amounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Equipment.amounts() instead.
            "::get::Equipment::amounts": {
              isArray: true,
              url: urlBase + "/Equipment/:id/amounts",
              method: "GET",
            },

            // INTERNAL. Use Equipment.amounts.create() instead.
            "::create::Equipment::amounts": {
              url: urlBase + "/Equipment/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Equipment.amounts.createMany() instead.
            "::createMany::Equipment::amounts": {
              isArray: true,
              url: urlBase + "/Equipment/:id/amounts",
              method: "POST",
            },

            // INTERNAL. Use Equipment.amounts.destroyAll() instead.
            "::delete::Equipment::amounts": {
              url: urlBase + "/Equipment/:id/amounts",
              method: "DELETE",
            },

            // INTERNAL. Use Equipment.amounts.count() instead.
            "::count::Equipment::amounts": {
              url: urlBase + "/Equipment/:id/amounts/count",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.findById() instead.
            "::findById::Inventory::equipmentAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipmentAmounts/:fk",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.destroyById() instead.
            "::destroyById::Inventory::equipmentAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipmentAmounts/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.updateById() instead.
            "::updateById::Inventory::equipmentAmounts": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Inventories/:id/equipmentAmounts/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Inventory.equipmentAmounts() instead.
            "::get::Inventory::equipmentAmounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/equipmentAmounts",
              method: "GET",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.create() instead.
            "::create::Inventory::equipmentAmounts": {
              url: urlBase + "/Inventories/:id/equipmentAmounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.createMany() instead.
            "::createMany::Inventory::equipmentAmounts": {
              isArray: true,
              url: urlBase + "/Inventories/:id/equipmentAmounts",
              method: "POST",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.destroyAll() instead.
            "::delete::Inventory::equipmentAmounts": {
              url: urlBase + "/Inventories/:id/equipmentAmounts",
              method: "DELETE",
            },

            // INTERNAL. Use Inventory.equipmentAmounts.count() instead.
            "::count::Inventory::equipmentAmounts": {
              url: urlBase + "/Inventories/:id/equipmentAmounts/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#updateOrCreate
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#update
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#destroyById
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#removeById
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryEquipmentMapping` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.InventoryEquipmentMapping#modelName
        * @propertyOf lbServices.InventoryEquipmentMapping
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InventoryEquipmentMapping`.
        */
        R.modelName = "InventoryEquipmentMapping";


            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#equipment
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Fetches belongsTo relation equipment.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Equipment` object.)
             * </em>
             */
        R.equipment = function() {
          var TargetResource = $injector.get("Equipment");
          var action = TargetResource["::get::InventoryEquipmentMapping::equipment"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryEquipmentMapping#inventory
             * @methodOf lbServices.InventoryEquipmentMapping
             *
             * @description
             *
             * Fetches belongsTo relation inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventory = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::InventoryEquipmentMapping::inventory"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InventoryFileMapping
 * @header lbServices.InventoryFileMapping
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InventoryFileMapping` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InventoryFileMapping",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InventoryFileMappings/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InventoryFileMapping.inventory() instead.
            "prototype$__get__inventory": {
              url: urlBase + "/InventoryFileMappings/:id/inventory",
              method: "GET",
            },

            // INTERNAL. Use InventoryFileMapping.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/InventoryFileMappings/:id/file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#create
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InventoryFileMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#createMany
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InventoryFileMappings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#upsert
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InventoryFileMappings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#exists
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InventoryFileMappings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#findById
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InventoryFileMappings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#find
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InventoryFileMappings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#findOne
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InventoryFileMappings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#updateAll
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/InventoryFileMappings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#deleteById
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InventoryFileMappings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#count
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InventoryFileMappings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#prototype$updateAttributes
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InventoryFileMappings/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#createChangeStream
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InventoryFileMappings/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#updateOrCreate
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#update
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#destroyById
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#removeById
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InventoryFileMapping` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.InventoryFileMapping#modelName
        * @propertyOf lbServices.InventoryFileMapping
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InventoryFileMapping`.
        */
        R.modelName = "InventoryFileMapping";


            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#inventory
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Fetches belongsTo relation inventory.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Inventory` object.)
             * </em>
             */
        R.inventory = function() {
          var TargetResource = $injector.get("Inventory");
          var action = TargetResource["::get::InventoryFileMapping::inventory"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InventoryFileMapping#file
             * @methodOf lbServices.InventoryFileMapping
             *
             * @description
             *
             * Fetches belongsTo relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::InventoryFileMapping::file"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SdsSearchJournal
 * @header lbServices.SdsSearchJournal
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SdsSearchJournal` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SdsSearchJournal",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SdsSearchJournals/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SdsSearchJournal#find
             * @methodOf lbServices.SdsSearchJournal
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsSearchJournal` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SdsSearchJournals",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsSearchJournal#count
             * @methodOf lbServices.SdsSearchJournal
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SdsSearchJournals/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsSearchJournal#generateSearchesWithoutResultsReport
             * @methodOf lbServices.SdsSearchJournal
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` -
             *
             *  - `fromDate` – `{string=}` -
             *
             *  - `toDate` – `{string=}` -
             *
             *  - `unique` – `{string}` - any unique value
             *
             *  - `hash` – `{string}` - data hash sign
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsSearchJournal` object.)
             * </em>
             */
            "generateSearchesWithoutResultsReport": {
              url: urlBase + "/SdsSearchJournals/generateSearchesWithoutResultsReport",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.SdsSearchJournal#modelName
        * @propertyOf lbServices.SdsSearchJournal
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SdsSearchJournal`.
        */
        R.modelName = "SdsSearchJournal";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.CompanyType
 * @header lbServices.CompanyType
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `CompanyType` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "CompanyType",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/CompanyTypes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use CompanyType.companies.findById() instead.
            "prototype$__findById__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanyTypes/:id/companies/:fk",
              method: "GET",
            },

            // INTERNAL. Use CompanyType.companies.destroyById() instead.
            "prototype$__destroyById__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanyTypes/:id/companies/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use CompanyType.companies.updateById() instead.
            "prototype$__updateById__companies": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/CompanyTypes/:id/companies/:fk",
              method: "PUT",
            },

            // INTERNAL. Use CompanyType.companies() instead.
            "prototype$__get__companies": {
              isArray: true,
              url: urlBase + "/CompanyTypes/:id/companies",
              method: "GET",
            },

            // INTERNAL. Use CompanyType.companies.create() instead.
            "prototype$__create__companies": {
              url: urlBase + "/CompanyTypes/:id/companies",
              method: "POST",
            },

            // INTERNAL. Use CompanyType.companies.destroyAll() instead.
            "prototype$__delete__companies": {
              url: urlBase + "/CompanyTypes/:id/companies",
              method: "DELETE",
            },

            // INTERNAL. Use CompanyType.companies.count() instead.
            "prototype$__count__companies": {
              url: urlBase + "/CompanyTypes/:id/companies/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#create
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/CompanyTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#createMany
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/CompanyTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#upsert
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/CompanyTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#exists
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/CompanyTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#findById
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/CompanyTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#find
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/CompanyTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#findOne
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/CompanyTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#updateAll
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/CompanyTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#deleteById
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/CompanyTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#count
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/CompanyTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#prototype$updateAttributes
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/CompanyTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#createChangeStream
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/CompanyTypes/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Company.companyType() instead.
            "::get::Company::companyType": {
              url: urlBase + "/Companies/:id/companyType",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.CompanyType#updateOrCreate
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#update
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#destroyById
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.CompanyType#removeById
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CompanyType` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.CompanyType#modelName
        * @propertyOf lbServices.CompanyType
        * @description
        * The name of the model represented by this $resource,
        * i.e. `CompanyType`.
        */
        R.modelName = "CompanyType";

    /**
     * @ngdoc object
     * @name lbServices.CompanyType.companies
     * @header lbServices.CompanyType.companies
     * @object
     * @description
     *
     * The object `CompanyType.companies` groups methods
     * manipulating `Company` instances related to `CompanyType`.
     *
     * Call {@link lbServices.CompanyType#companies CompanyType.companies()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.CompanyType#companies
             * @methodOf lbServices.CompanyType
             *
             * @description
             *
             * Queries companies of CompanyType.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::get::CompanyType::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanyType.companies#count
             * @methodOf lbServices.CompanyType.companies
             *
             * @description
             *
             * Counts companies of CompanyType.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.companies.count = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::count::CompanyType::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanyType.companies#create
             * @methodOf lbServices.CompanyType.companies
             *
             * @description
             *
             * Creates a new instance in companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.create = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::create::CompanyType::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanyType.companies#createMany
             * @methodOf lbServices.CompanyType.companies
             *
             * @description
             *
             * Creates a new instance in companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.createMany = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::createMany::CompanyType::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanyType.companies#destroyAll
             * @methodOf lbServices.CompanyType.companies
             *
             * @description
             *
             * Deletes all companies of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.companies.destroyAll = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::delete::CompanyType::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanyType.companies#destroyById
             * @methodOf lbServices.CompanyType.companies
             *
             * @description
             *
             * Delete a related item by id for companies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.companies.destroyById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::destroyById::CompanyType::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanyType.companies#findById
             * @methodOf lbServices.CompanyType.companies
             *
             * @description
             *
             * Find a related item by id for companies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.findById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::findById::CompanyType::companies"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.CompanyType.companies#updateById
             * @methodOf lbServices.CompanyType.companies
             *
             * @description
             *
             * Update a related item by id for companies.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for companies
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Company` object.)
             * </em>
             */
        R.companies.updateById = function() {
          var TargetResource = $injector.get("Company");
          var action = TargetResource["::updateById::CompanyType::companies"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ProcessingTemplate
 * @header lbServices.ProcessingTemplate
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ProcessingTemplate` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ProcessingTemplate",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ProcessingTemplates/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#create
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ProcessingTemplates",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#createMany
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ProcessingTemplates",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#upsert
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ProcessingTemplates",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#exists
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ProcessingTemplates/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#findById
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ProcessingTemplates/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#find
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ProcessingTemplates",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#findOne
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ProcessingTemplates/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#updateAll
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/ProcessingTemplates/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#deleteById
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ProcessingTemplates/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#count
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ProcessingTemplates/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#prototype$updateAttributes
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ProcessingTemplates/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#createChangeStream
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ProcessingTemplates/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#updateOrCreate
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#update
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#destroyById
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProcessingTemplate#removeById
             * @methodOf lbServices.ProcessingTemplate
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProcessingTemplate` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.ProcessingTemplate#modelName
        * @propertyOf lbServices.ProcessingTemplate
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ProcessingTemplate`.
        */
        R.modelName = "ProcessingTemplate";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SupportTicket
 * @header lbServices.SupportTicket
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SupportTicket` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SupportTicket",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SupportTicket/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#prototype$__get__user
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Fetches belongsTo relation user.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "prototype$__get__user": {
              url: urlBase + "/SupportTicket/:id/user",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#create
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SupportTicket",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#createMany
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SupportTicket",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#upsert
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SupportTicket",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#exists
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SupportTicket/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#findById
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SupportTicket/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#find
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SupportTicket",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#findOne
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SupportTicket/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#updateAll
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/SupportTicket/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#deleteById
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SupportTicket/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#count
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SupportTicket/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#prototype$updateAttributes
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SupportTicket/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#createChangeStream
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SupportTicket/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#updateOrCreate
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#update
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#destroyById
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SupportTicket#removeById
             * @methodOf lbServices.SupportTicket
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SupportTicket` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.SupportTicket#modelName
        * @propertyOf lbServices.SupportTicket
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SupportTicket`.
        */
        R.modelName = "SupportTicket";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SchedulerApi
 * @header lbServices.SchedulerApi
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SchedulerApi` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SchedulerApi",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SchedulerApis/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#status
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "status": {
              url: urlBase + "/SchedulerApis/status",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#invoke
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "invoke": {
              url: urlBase + "/SchedulerApis/invoke",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#getSdsRequestReminders
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "getSdsRequestReminders": {
              url: urlBase + "/SchedulerApis/getSdsRequestReminders",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#upsertSdsRequestReminder
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` -
             *
             *  - `repeatEvery` – `{number=}` -
             *
             *  - `period` – `{string=}` -
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "upsertSdsRequestReminder": {
              url: urlBase + "/SchedulerApis/upsertSdsRequestReminder",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#removeSdsRequestReminder
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "removeSdsRequestReminder": {
              url: urlBase + "/SchedulerApis/removeSdsRequestReminder",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#getSdsRequestReminder
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * Get email reminder for open "Add SDS requests"
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "getSdsRequestReminder": {
              url: urlBase + "/SchedulerApis/getSdsRequestReminder",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#getReports
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * Get all scheduled sreports
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "getReports": {
              isArray: true,
              url: urlBase + "/SchedulerApis/report",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#postReports
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * Schedule reports
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `reportName` – `{string}` -
             *
             *  - `companyId` – `{string}` -
             *
             *  - `emails` – `{string}` -
             *
             *  - `frequency` – `{string}` -
             *
             *  - `params` – `{object=}` - additional params
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "postReports": {
              url: urlBase + "/SchedulerApis/report/:reportName",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#putReports
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * Update reports
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `reportName` – `{string}` -
             *
             *  - `id` – `{string}` -
             *
             *  - `emails` – `{string}` -
             *
             *  - `frequency` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "putReports": {
              url: urlBase + "/SchedulerApis/report/:reportName/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SchedulerApi#deleteReports
             * @methodOf lbServices.SchedulerApi
             *
             * @description
             *
             * Remove reports
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `reportName` – `{string}` -
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SchedulerApi` object.)
             * </em>
             */
            "deleteReports": {
              url: urlBase + "/SchedulerApis/report/:reportName/:id",
              method: "DELETE",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.SchedulerApi#modelName
        * @propertyOf lbServices.SchedulerApi
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SchedulerApi`.
        */
        R.modelName = "SchedulerApi";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.HazardousSubstanceList
 * @header lbServices.HazardousSubstanceList
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `HazardousSubstanceList` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "HazardousSubstanceList",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/HazardousSubstanceList/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#create
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/HazardousSubstanceList",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#createMany
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/HazardousSubstanceList",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#upsert
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/HazardousSubstanceList",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#exists
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/HazardousSubstanceList/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#findById
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/HazardousSubstanceList/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#find
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/HazardousSubstanceList",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#findOne
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/HazardousSubstanceList/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#updateAll
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/HazardousSubstanceList/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#deleteById
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/HazardousSubstanceList/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#count
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/HazardousSubstanceList/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#prototype$updateAttributes
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/HazardousSubstanceList/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#createChangeStream
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/HazardousSubstanceList/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#sendPAHSSFReport
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Creates PA HSSF report and sends link to archive to email address
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `email` – `{string}` - an email address to send the link to
             *
             *  - `companyId` – `{string=}` -
             *
             *  - `tagIds` – `{string=}` - tag IDs to filter
             *
             *  - `andOrStrategy` – `{string=}` - search SDSs by ALL (and) or ANY (or) tags
             *
             *  - `filterName` – `{string=}` - filter description
             *
             *  - `hostname` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `status` – `{string=}` -
             */
            "sendPAHSSFReport": {
              url: urlBase + "/HazardousSubstanceList/sendPAHSSFReport",
              method: "POST",
            },

            // INTERNAL. Use SDSHazardous.hslData.findById() instead.
            "::findById::SDSHazardous::hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/:fk",
              method: "GET",
            },

            // INTERNAL. Use SDSHazardous.hslData.destroyById() instead.
            "::destroyById::SDSHazardous::hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SDSHazardous.hslData.updateById() instead.
            "::updateById::SDSHazardous::hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SDSHazardous.hslData.link() instead.
            "::link::SDSHazardous::hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SDSHazardous.hslData.unlink() instead.
            "::unlink::SDSHazardous::hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SDSHazardous.hslData.exists() instead.
            "::exists::SDSHazardous::hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SDSHazardous.hslData() instead.
            "::get::SDSHazardous::hslData": {
              isArray: true,
              url: urlBase + "/SDSHazardous/:id/hslData",
              method: "GET",
            },

            // INTERNAL. Use SDSHazardous.hslData.create() instead.
            "::create::SDSHazardous::hslData": {
              url: urlBase + "/SDSHazardous/:id/hslData",
              method: "POST",
            },

            // INTERNAL. Use SDSHazardous.hslData.createMany() instead.
            "::createMany::SDSHazardous::hslData": {
              isArray: true,
              url: urlBase + "/SDSHazardous/:id/hslData",
              method: "POST",
            },

            // INTERNAL. Use SDSHazardous.hslData.destroyAll() instead.
            "::delete::SDSHazardous::hslData": {
              url: urlBase + "/SDSHazardous/:id/hslData",
              method: "DELETE",
            },

            // INTERNAL. Use SDSHazardous.hslData.count() instead.
            "::count::SDSHazardous::hslData": {
              url: urlBase + "/SDSHazardous/:id/hslData/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#updateOrCreate
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#update
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#destroyById
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.HazardousSubstanceList#removeById
             * @methodOf lbServices.HazardousSubstanceList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.HazardousSubstanceList#modelName
        * @propertyOf lbServices.HazardousSubstanceList
        * @description
        * The name of the model represented by this $resource,
        * i.e. `HazardousSubstanceList`.
        */
        R.modelName = "HazardousSubstanceList";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AtsdrSubstancePriorityList
 * @header lbServices.AtsdrSubstancePriorityList
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AtsdrSubstancePriorityList` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AtsdrSubstancePriorityList",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AtsdrSubstancePriorityList/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#create
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AtsdrSubstancePriorityList",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#createMany
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AtsdrSubstancePriorityList",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#upsert
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/AtsdrSubstancePriorityList",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#exists
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AtsdrSubstancePriorityList/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#findById
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AtsdrSubstancePriorityList/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#find
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AtsdrSubstancePriorityList",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#findOne
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AtsdrSubstancePriorityList/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#updateAll
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/AtsdrSubstancePriorityList/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#deleteById
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AtsdrSubstancePriorityList/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#count
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AtsdrSubstancePriorityList/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#prototype$updateAttributes
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/AtsdrSubstancePriorityList/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#createChangeStream
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AtsdrSubstancePriorityList/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#export
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * 
             *     Get Report File
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `type` – `{string}` - export type
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "export": {
              url: urlBase + "/AtsdrSubstancePriorityList/export",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#getSdsReportData
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * 
             *     Get SDS Report Data
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "getSdsReportData": {
              url: urlBase + "/AtsdrSubstancePriorityList/getSdsReportData",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#schedulerReportRun
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Perform Scheduler Run
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `recipients` – `{string}` - recipients
             *
             *  - `unique` – `{string}` - any unique value
             *
             *  - `hash` – `{string}` - data hash sign
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
            "schedulerReportRun": {
              url: urlBase + "/AtsdrSubstancePriorityList/schedulerReportRun",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#updateOrCreate
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#update
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#destroyById
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AtsdrSubstancePriorityList#removeById
             * @methodOf lbServices.AtsdrSubstancePriorityList
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AtsdrSubstancePriorityList` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.AtsdrSubstancePriorityList#modelName
        * @propertyOf lbServices.AtsdrSubstancePriorityList
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AtsdrSubstancePriorityList`.
        */
        R.modelName = "AtsdrSubstancePriorityList";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SDSHazardous
 * @header lbServices.SDSHazardous
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SDSHazardous` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SDSHazardous",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SDSHazardous/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use SDSHazardous.hslData.findById() instead.
            "prototype$__findById__hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/:fk",
              method: "GET",
            },

            // INTERNAL. Use SDSHazardous.hslData.destroyById() instead.
            "prototype$__destroyById__hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SDSHazardous.hslData.updateById() instead.
            "prototype$__updateById__hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SDSHazardous.hslData.link() instead.
            "prototype$__link__hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use SDSHazardous.hslData.unlink() instead.
            "prototype$__unlink__hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use SDSHazardous.hslData.exists() instead.
            "prototype$__exists__hslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/hslData/rel/:fk",
              method: "HEAD",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__findById__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Find a related item by id for ehslData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for ehslData
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "prototype$__findById__ehslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/ehslData/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__destroyById__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Delete a related item by id for ehslData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for ehslData
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__ehslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/ehslData/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__updateById__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Update a related item by id for ehslData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for ehslData
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "prototype$__updateById__ehslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/ehslData/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__link__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Add a related item by id for ehslData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for ehslData
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "prototype$__link__ehslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/ehslData/rel/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__unlink__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Remove the ehslData relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for ehslData
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__unlink__ehslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/ehslData/rel/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__exists__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Check the existence of ehslData relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for ehslData
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "prototype$__exists__ehslData": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SDSHazardous/:id/ehslData/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SDSHazardous.hslData() instead.
            "prototype$__get__hslData": {
              isArray: true,
              url: urlBase + "/SDSHazardous/:id/hslData",
              method: "GET",
            },

            // INTERNAL. Use SDSHazardous.hslData.create() instead.
            "prototype$__create__hslData": {
              url: urlBase + "/SDSHazardous/:id/hslData",
              method: "POST",
            },

            // INTERNAL. Use SDSHazardous.hslData.destroyAll() instead.
            "prototype$__delete__hslData": {
              url: urlBase + "/SDSHazardous/:id/hslData",
              method: "DELETE",
            },

            // INTERNAL. Use SDSHazardous.hslData.count() instead.
            "prototype$__count__hslData": {
              url: urlBase + "/SDSHazardous/:id/hslData/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__get__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Queries ehslData of SDSHazardous.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "prototype$__get__ehslData": {
              isArray: true,
              url: urlBase + "/SDSHazardous/:id/ehslData",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__create__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Creates a new instance in ehslData of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "prototype$__create__ehslData": {
              url: urlBase + "/SDSHazardous/:id/ehslData",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__delete__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Deletes all ehslData of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__ehslData": {
              url: urlBase + "/SDSHazardous/:id/ehslData",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$__count__ehslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Counts ehslData of SDSHazardous.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__ehslData": {
              url: urlBase + "/SDSHazardous/:id/ehslData/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#create
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SDSHazardous",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#createMany
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SDSHazardous",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#upsert
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SDSHazardous",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#exists
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SDSHazardous/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#findById
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SDSHazardous/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#find
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SDSHazardous",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#findOne
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SDSHazardous/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#updateAll
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/SDSHazardous/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#deleteById
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SDSHazardous/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#count
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SDSHazardous/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#prototype$updateAttributes
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SDSHazardous/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#createChangeStream
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SDSHazardous/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#updateOrCreate
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#update
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#destroyById
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#removeById
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SDSHazardous` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.SDSHazardous#modelName
        * @propertyOf lbServices.SDSHazardous
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SDSHazardous`.
        */
        R.modelName = "SDSHazardous";

    /**
     * @ngdoc object
     * @name lbServices.SDSHazardous.hslData
     * @header lbServices.SDSHazardous.hslData
     * @object
     * @description
     *
     * The object `SDSHazardous.hslData` groups methods
     * manipulating `HazardousSubstanceList` instances related to `SDSHazardous`.
     *
     * Call {@link lbServices.SDSHazardous#hslData SDSHazardous.hslData()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous#hslData
             * @methodOf lbServices.SDSHazardous
             *
             * @description
             *
             * Queries hslData of SDSHazardous.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R.hslData = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::get::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#count
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Counts hslData of SDSHazardous.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.hslData.count = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::count::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#create
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Creates a new instance in hslData of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R.hslData.create = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::create::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#createMany
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Creates a new instance in hslData of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R.hslData.createMany = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::createMany::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#destroyAll
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Deletes all hslData of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.hslData.destroyAll = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::delete::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#destroyById
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Delete a related item by id for hslData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for hslData
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.hslData.destroyById = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::destroyById::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#exists
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Check the existence of hslData relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for hslData
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R.hslData.exists = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::exists::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#findById
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Find a related item by id for hslData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for hslData
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R.hslData.findById = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::findById::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#link
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Add a related item by id for hslData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for hslData
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R.hslData.link = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::link::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#unlink
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Remove the hslData relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for hslData
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.hslData.unlink = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::unlink::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SDSHazardous.hslData#updateById
             * @methodOf lbServices.SDSHazardous.hslData
             *
             * @description
             *
             * Update a related item by id for hslData.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for hslData
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HazardousSubstanceList` object.)
             * </em>
             */
        R.hslData.updateById = function() {
          var TargetResource = $injector.get("HazardousSubstanceList");
          var action = TargetResource["::updateById::SDSHazardous::hslData"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SdsListByTagReport
 * @header lbServices.SdsListByTagReport
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SdsListByTagReport` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SdsListByTagReport",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/sdsListByTagReport/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SdsListByTagReport#schedulerRun
             * @methodOf lbServices.SdsListByTagReport
             *
             * @description
             *
             * 
             *     Perform Scheduler Run
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `recipients` – `{string}` - recipients
             *
             *  - `unique` – `{string}` - any unique value
             *
             *  - `hash` – `{string}` - data hash sign
             *
             *  - `tags` – `{*}` - an array of tag Ids
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsListByTagReport` object.)
             * </em>
             */
            "schedulerRun": {
              url: urlBase + "/sdsListByTagReport/schedulerRun",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsListByTagReport#export
             * @methodOf lbServices.SdsListByTagReport
             *
             * @description
             *
             * 
             *     Get Report File
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `tags` – `{*}` - an array of tag Ids
             *
             *  - `email` – `{string}` - Email to receive data
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `filename` – `{string=}` -
             */
            "export": {
              url: urlBase + "/sdsListByTagReport/export",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsListByTagReport#splitExport
             * @methodOf lbServices.SdsListByTagReport
             *
             * @description
             *
             * 
             *     Get Report File
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `tags` – `{*}` - an array of tag Ids
             *
             *  - `email` – `{string}` - Email to receive data
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `hostname` – `{string=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `filename` – `{string=}` -
             */
            "splitExport": {
              url: urlBase + "/sdsListByTagReport/splitExport",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsListByTagReport#userFillableInventoryQuantityReport
             * @methodOf lbServices.SdsListByTagReport
             *
             * @description
             *
             * 
             *     Get Report File
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `tags` – `{*}` - an array of tag Ids
             *
             *  - `email` – `{string}` - Email to receive data
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `filename` – `{string=}` -
             */
            "userFillableInventoryQuantityReport": {
              url: urlBase + "/sdsListByTagReport/userFillableInventoryQuantityReport",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SdsListByTagReport#find
             * @methodOf lbServices.SdsListByTagReport
             *
             * @description
             *
             * 
             *     Get Report Data
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `tags` – `{*=}` - an array of tag Ids
             *
             *  - `limit` – `{number=}` - limit
             *
             *  - `offset` – `{number=}` - offset
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SdsListByTagReport` object.)
             * </em>
             */
            "find": {
              url: urlBase + "/sdsListByTagReport/find",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.SdsListByTagReport#modelName
        * @propertyOf lbServices.SdsListByTagReport
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SdsListByTagReport`.
        */
        R.modelName = "SdsListByTagReport";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FireResponseReport
 * @header lbServices.FireResponseReport
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FireResponseReport` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FireResponseReport",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/FireResponseReports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#create
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/FireResponseReports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#createMany
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/FireResponseReports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#upsert
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/FireResponseReports",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#exists
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/FireResponseReports/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#findById
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/FireResponseReports/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#find
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/FireResponseReports",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#findOne
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/FireResponseReports/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#updateAll
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/FireResponseReports/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#deleteById
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/FireResponseReports/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#count
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/FireResponseReports/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#prototype$updateAttributes
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/FireResponseReports/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#createChangeStream
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/FireResponseReports/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#export
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * 
             *     Get Report File
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `type` – `{string}` - export type
             *
             *  - `inventoryIds` – `{*}` - inventory Ids
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "export": {
              url: urlBase + "/FireResponseReports/export",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#getData
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * 
             *     Get Report Data
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "getData": {
              isArray: true,
              url: urlBase + "/FireResponseReports/getData",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#schedulerRun
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Perform Scheduler Run
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `recipients` – `{string}` - recipients
             *
             *  - `unique` – `{string}` - any unique value
             *
             *  - `hash` – `{string}` - data hash sign
             *
             *  - `inventoryIds` – `{*=}` - an array of inventory IDs
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
            "schedulerRun": {
              url: urlBase + "/FireResponseReports/schedulerRun",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#updateOrCreate
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#update
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#destroyById
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.FireResponseReport#removeById
             * @methodOf lbServices.FireResponseReport
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FireResponseReport` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.FireResponseReport#modelName
        * @propertyOf lbServices.FireResponseReport
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FireResponseReport`.
        */
        R.modelName = "FireResponseReport";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Section313Report
 * @header lbServices.Section313Report
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Section313Report` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Section313Report",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Section313Reports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#create
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Section313Reports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#createMany
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Section313Reports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#upsert
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Section313Reports",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#exists
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Section313Reports/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#findById
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Section313Reports/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#find
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Section313Reports",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#findOne
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Section313Reports/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#updateAll
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/Section313Reports/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#deleteById
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Section313Reports/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#count
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Section313Reports/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#prototype$updateAttributes
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Section313Reports/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#createChangeStream
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Section313Reports/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#export
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * 
             *     Get Report File
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `type` – `{string}` - export type
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "export": {
              url: urlBase + "/Section313Reports/export",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#getData
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * 
             *     Get Report Data
             *     
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `limit` – `{number=}` - limit
             *
             *  - `offset` – `{number=}` - offset
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "getData": {
              url: urlBase + "/Section313Reports/getData",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#schedulerRun
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Perform Scheduler Run
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `companyId` – `{string}` - a company ID
             *
             *  - `lang` – `{string=}` - current site language, e.g. "en"
             *
             *  - `recipients` – `{string}` - recipients
             *
             *  - `unique` – `{string}` - any unique value
             *
             *  - `hash` – `{string}` - data hash sign
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
            "schedulerRun": {
              url: urlBase + "/Section313Reports/schedulerRun",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Section313Report#updateOrCreate
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#update
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#destroyById
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Section313Report#removeById
             * @methodOf lbServices.Section313Report
             *
             * @description
             *
             * Delete a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Section313Report` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];


        /**
        * @ngdoc property
        * @name lbServices.Section313Report#modelName
        * @propertyOf lbServices.Section313Report
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Section313Report`.
        */
        R.modelName = "Section313Report";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Measure
 * @header lbServices.Measure
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Measure` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Measure",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Measures/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Measure#find
             * @methodOf lbServices.Measure
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Measure` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Measures",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Measure#count
             * @methodOf lbServices.Measure
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Measures/count",
              method: "GET",
            },

            // INTERNAL. Use Product.measure() instead.
            "::get::Product::measure": {
              url: urlBase + "/Products/:id/measure",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Measure#modelName
        * @propertyOf lbServices.Measure
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Measure`.
        */
        R.modelName = "Measure";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.GHSData
 * @header lbServices.GHSData
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `GHSData` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "GHSData",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/GHSData/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.GHSData#upsertGHSData
             * @methodOf lbServices.GHSData
             *
             * @description
             *
             * Create GHS data for the given SDS ID.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `sdsId` – `{string}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `GHSData` object.)
             * </em>
             */
            "upsertGHSData": {
              url: urlBase + "/GHSData/:sdsId",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.GHSData#getGHSData
             * @methodOf lbServices.GHSData
             *
             * @description
             *
             * Get GHS data for the given SDS IDs. If there is no data for an SDS ID, that SDS will be parsed and the data will be stored in the database.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `sdsIds` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * an object where keys are the SDS IDs and values are the GHS data
             */
            "getGHSData": {
              url: urlBase + "/GHSData/getGHSData",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.GHSData#modelName
        * @propertyOf lbServices.GHSData
        * @description
        * The name of the model represented by this $resource,
        * i.e. `GHSData`.
        */
        R.modelName = "GHSData";



        return R;
      }]);


  module
  .factory('LoopBackAuth', function() {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function(name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function() {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function(name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function(accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function() {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function() {
      props.forEach(function(name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    // Note: LocalStorage converts the value to string
    // We are using empty string as a marker for null/undefined values.
    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
  }])
  .factory('LoopBackAuthRequestInterceptor', ['$q', 'LoopBackAuth',
    function($q, LoopBackAuth) {
      return {
        'request': function(config) {
          // filter out external requests
          var host = getHost(config.url);
          if (host && host !== urlBaseHost) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            var res = {
              body: { error: { status: 401 }},
              status: 401,
              config: config,
              headers: function() { return undefined; },
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        },
      };
    }])

  /**
   * @ngdoc object
   * @name lbServices.LoopBackResourceProvider
   * @header lbServices.LoopBackResourceProvider
   * @description
   * Use `LoopBackResourceProvider` to change the global configuration
   * settings used by all models. Note that the provider is available
   * to Configuration Blocks only, see
   * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
   * for more details.
   *
   * ## Example
   *
   * ```js
   * angular.module('app')
   *  .config(function(LoopBackResourceProvider) {
   *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
   *  });
   * ```
   */
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} header The header name to use, e.g. `X-Access-Token`
     * @description
     * Configure the REST transport to use a different header for sending
     * the authentication token. It is sent in the `Authorization` header
     * by default.
     */
    this.setAuthHeader = function(header) {
      authHeader = header;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the header name that is used for sending the authentication token.
     */
    this.getAuthHeader = function() {
      return authHeader;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
     * @description
     * Change the URL of the REST API server. By default, the URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.setUrlBase = function(url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the URL of the REST API server. The URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.getUrlBase = function() {
      return urlBase;
    };

    this.$get = ['$resource', function($resource) {
      var LoopBackResource = function(url, params, actions) {
        var resource = $resource(url, params, actions);

        // Angular always calls POST on $save()
        // This hack is based on
        // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
        resource.prototype.$save = function(success, error) {
          // Fortunately, LoopBack provides a convenient `upsert` method
          // that exactly fits our needs.
          var result = resource.upsert.call(this, {}, this, success, error);
          return result.$promise || result;
        };
        return resource;
      };

      LoopBackResource.getUrlBase = function() {
        return urlBase;
      };

      LoopBackResource.getAuthHeader = function() {
        return authHeader;
      };

      return LoopBackResource;
    }];
  });
})(window, window.angular);
