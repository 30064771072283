/* global StateMachine */

import angular from 'angular';
import { uniq, isEmpty, get } from 'lodash';
import app from '../../app';
import { deObjectify, getNameFromFile, pivotArray } from '../../helpers';
import { getLegislationByCode, legislations } from '../../../../common/helpers/legislations';

app.controller('processCtrl', [
  '$scope',
  '$state',
  '$rootScope',
  '$http',
  '$q',
  '$timeout',
  'SdsUser',
  'SolrDocument',
  'Company',
  'Tag',
  'TagFactory',
  'SearchFactory',
  'usSpinnerService',
  '$translate',
  'ProcessingTemplates',
  'Product',
  'ErrorService',
  'manufacturers',
  'CompanySetting',
  'SdsDocumentSolrMap',
  'HelperService',
  'SpinnerService',
  'ModalService',
  'CompanyService',
  'AttachmentService',
  'Attachment',
  'MessagesService',
  'PpeService',
  processCtrl
]);

function processCtrl(
  $scope,
  $state,
  $rootScope,
  $http,
  $q,
  $timeout,
  SdsUser,
  SolrDocument,
  Company,
  Tag,
  TagFactory,
  SearchFactory,
  usSpinnerService,
  $translate,
  ProcessingTemplates,
  Product,
  ErrorService,
  manufacturers,
  CompanySetting,
  SdsDocumentSolrMap,
  HelperService,
  SpinnerService,
  ModalService,
  CompanyService,
  AttachmentService,
  Attachment,
  MessagesService,
  PpeService
) {
  $scope.PpeService = PpeService;

  function textTranslate() {
    $translate([
      'PROCESS.SOLR_FORM.SECTION_1',
      'PROCESS.SOLR_FORM.SECTION_2',
      'PROCESS.SOLR_FORM.SECTION_3',
      'PROCESS.SOLR_FORM.SECTION_4',
      'PROCESS.SOLR_FORM.SECTION_5',
      'PROCESS.SOLR_FORM.SECTION_6',
      'PROCESS.SOLR_FORM.SECTION_7',
      'PROCESS.SOLR_FORM.SECTION_8',
      'PROCESS.SOLR_FORM.SECTION_9',
      'PROCESS.SOLR_FORM.SECTION_10',
      'PROCESS.SOLR_FORM.SECTION_11',
      'PROCESS.SOLR_FORM.SECTION_12',
      'PROCESS.SOLR_FORM.SECTION_13',
      'PROCESS.SOLR_FORM.SECTION_14',
      'PROCESS.SOLR_FORM.SECTION_15',
      'PROCESS.SOLR_FORM.SECTION_16',
      'PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.FLAME.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.CORROSION.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.DESCRIPTION',
      'PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.TITLE',
      'PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.DESCRIPTION'
    ]).then(function (translations) {
      var translate = {};

      for (var translation in translations) {
        translate[translation] = translations[translation];
      }

      setTranslations(translate);
    });
  }

  let kitIds = [];
  let newAddedCompanies = [];
  let linkedSds = [];
  let sdsEditorAllowedCompaniesIds = [];

  textTranslate();

  $rootScope.$on('$translateChangeSuccess', function () {
    textTranslate();
  });

  const simpleCatch = ErrorService.simpleCatch;

  const processingTemplates = new ProcessingTemplates();
  $scope.processingTemplates = processingTemplates.processingTemplates;
  $scope.selectedTemplate = processingTemplates.selectedTemplate;

  $scope.onTemplateSelected = function ($event, name) {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.selectedTemplate = name;
  };

  const vm = this;
  vm.datePicker = {
    options: {
      maxDate: new Date(),
      showWeeks: false
    },
    defaultFormat: 'yyyy-MM-dd',
    altInputFormats: [
      'yyyy-MM-dd',
      'MMMM dd, yy',
      'MMMM dd, yyyy',
      'yyyy-MM-dd',
      'dd-MMMM-yyyy',
      'yyyy/MM/dd',
      'dd.MM.yyyy',
      'shortDate',
      ''
    ],
    show: false,
    value: '',
    onDatePicked: () => {
      $scope.sdsDocument.issueDate.set = vm.datePicker.value
        ? new Date(vm.datePicker.value).toISOString().substr(0, 10)
        : '';
      $scope.changed();
    }
  };
  vm.UniversalTags = new TagFactory(Tag);
  vm.allSdsCompanies = {
    companyIds: [],
    add: function (companyId) {
      const childSdsCompanies = $scope.companies[companyId]
        ? $scope.companies[companyId].childSdsCompanies.map(company => company.id)
        : [];
      const familyCompanyIds = [companyId, ...childSdsCompanies];

      this.companyIds = uniq(this.companyIds.concat(familyCompanyIds));

      return this.companyIds;
    },
    remove: function (companyId) {
      const childSdsCompanies = $scope.companies[companyId]
        ? $scope.companies[companyId].childSdsCompanies.map(company => company.id)
        : [];
      const familyCompanyIds = [companyId, ...childSdsCompanies];

      this.companyIds = this.companyIds.filter(companyId => !familyCompanyIds.includes(companyId));

      return this.companyIds;
    }
  };
  vm.nfpa = {};
  vm.changeCopyNFPA = {};
  vm.tagsByCompanyId = {};
  vm.backgroundDisposalSelect = {};
  vm.backgroundStorageSelect = {};
  vm.companySettingsByCompanyId = {};
  vm.selectedStorageCode = {};
  vm.selectedDisposalCode = {};
  vm.sitename = $rootScope.sitename;
  vm.SearchFactory = SearchFactory;
  SearchFactory.languageSelected = {};
  vm.legislations = legislations;
  vm.legislationCountries = legislations.map(legislation => legislation.country);
  vm.manufacturers = manufacturers;
  vm.knownManu = [{ id: null, name: '' }].concat(
    Object.keys(manufacturers).map(function (id) {
      return manufacturers[id];
    })
  );
  vm.knownManuNames = Object.keys(manufacturers).map(function (id) {
    return manufacturers[id].name;
  });
  vm.products = [];
  vm.attachments = [];
  $scope.attachmentOpened = true;
  $scope.$on('attachmentsChange', function (event) {
    vm.attachments = AttachmentService.filterAttachments(vm.attachments);
  });
  vm.attrs = {};
  vm.kitMode = false;
  vm.kitDocs = [];
  vm.kitSpinnerActive = true;
  vm.kitCoverFile = null;

  vm.unsetCover = function () {
    vm.kitCoverFile = null;
  };

  //angular doesn't run validation if field isn't changed, makes it manually
  vm.typeaheadSelected = function ($item, $model, $label, formInput) {
    $scope.changed();
    formInput.$setValidity('includes', true);
  };

  vm.isSdsChildCompany = function (company) {
    return !!company.sdsParentCompanyId;
  };

  vm.getAttrLangName = CompanyService.getAttrLangName;

  $scope.extractedContent = {};
  $scope.extractedContent.raw = '';
  $scope.extractedContent.sections = [];
  $scope.spinnerActive = false;
  $scope.currentFilename = '';
  $scope.documentDeleted = false;
  $scope.saveState = '';
  $scope.saveError = false;
  $scope.isDirty = false;
  $scope.sdsDocument = {};

  // TODO: replace with HazardService
  function setTranslations(translate) {
    $scope.hazardTypes = [
      {
        value: 'healthHazard',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.DESCRIPTION']
      },
      {
        value: 'flame',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.FLAME.DESCRIPTION']
      },
      {
        value: 'exclamationMark',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.DESCRIPTION']
      },
      {
        value: 'corrosion',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.CORROSION.DESCRIPTION']
      },
      {
        value: 'gasCylinder',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.DESCRIPTION']
      },
      {
        value: 'explodingBomb',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.DESCRIPTION']
      },
      {
        value: 'skullAndCrossbones',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.DESCRIPTION']
      },
      {
        value: 'flameOverCircle',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.DESCRIPTION']
      },
      {
        value: 'environment',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.DESCRIPTION']
      },
      {
        value: 'biohazard',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.DESCRIPTION']
      }
    ];

    $scope.oldHazardTypes = [
      {
        value: 'flame_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.FLAME.DESCRIPTION']
      },
      {
        value: 'exclamationMark_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.DESCRIPTION']
      },
      {
        value: 'corrosion_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.CORROSION.DESCRIPTION']
      },
      {
        value: 'gasCylinder_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.DESCRIPTION']
      },
      {
        value: 'skullAndCrossbones_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.DESCRIPTION']
      },
      {
        value: 'flameOverCircle_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.DESCRIPTION']
      },
      {
        value: 'reactiveMaterials_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.DESCRIPTION']
      },
      {
        value: 'biohazard_old',
        title: translate['PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE'],
        description: translate['PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.DESCRIPTION']
      }
    ];

    $scope.sdsTextSections = [
      {
        field: 'product_and_company_identification',
        title: translate['PROCESS.SOLR_FORM.SECTION_1'],
        fuzzyMatch: [
          /^1+ProductandCompanyIdentification/i,
          /^1+identification/i,
          /^1+ChemicalProductandCompanyIdentification/i,
          /^1+PRODUCTIDENTIFICATIONANDPREPARATIONINFORMATION/i,
          /^1+PRODUCTIDENTIFICATION/i,
          /^1+PRODUCTINFORMATION/i,
          /^1+chemicalproductandcompanyinformation/i,
          /^1+Identificationdelasubstancedumlangeetdelasocitlentreprise/i,
          /^1+lidentificationdeproduitchimiqueetdecompagnie/i,
          /^ChemicalProductAndCompanyIdentificationIdentificationDuProduitChimiqueEtDeLEntreprise$/i
        ]
      },
      {
        field: 'hazards_identification',
        title: translate['PROCESS.SOLR_FORM.SECTION_2'],
        fuzzyMatch: [
          /^2+HazardsIdentification/i,
          /^2+HazardIdentification/i,
          /^2+HEALTHHAZARDDATA/i,
          /^2+HazardsOverview/i,
          /^2+HEALTHHAZARDSASummaryRisks/i,
          /^2+HEALTHHAZARDSBSignsSymptomsofOverexposure/i,
          /^2+identificationdes?risques/i,
          /^2+Identificationdes?dangers/i,
          /^HazardsIdentificationReconnaissanceDesDangers$/i
        ]
      },
      {
        field: 'composition_information_ingredients',
        title: translate['PROCESS.SOLR_FORM.SECTION_3'],
        fuzzyMatch: [
          /^3+Compositioninformationoningredient/i,
          /^3+COMPOSITIONINFORMATIONINGREDIENT/i,
          /^3+HAZARDOUSINGREDIENTS/i,
          /^3+INGREDIENTS/i,
          /^3+PRODUCTINGREDIENTS/i,
          /^3+composition(et)?informationsur[ld]esingrdients?/i,
          /^3+Compositioninformations?surlescomposants?/i,
          /^3+compositioninformations?surdeslments/i,
          /^3+compositioninformations?surlesingrdients/i,
          /^CompositionInformationOnIngredientsCompositionInformationSurLesIngrdients$/i
        ]
      },
      {
        field: 'first_aid_measures',
        title: translate['PROCESS.SOLR_FORM.SECTION_4'],
        fuzzyMatch: [
          /^4+Firstaidmeasures/i,
          /^4+FIRSTAID/i,
          /^4+EMERGENCYANDFIRSTAIDPROCEDURES/i,
          /^4+HEALTHHAZARDSCFirstAidEmergencyProcedures/i,
          /^4+premierssoins/i,
          /^4+Premierssecours/i,
          /^4+MesuresDePremiersSoins/i,
          /^4+mesuresdepremierssecours/i,
          /^FirstAidPremiersSoins$/i
        ]
      },
      {
        field: 'fire_fighting_measures',
        title: translate['PROCESS.SOLR_FORM.SECTION_5'],
        fuzzyMatch: [
          /^5+FireFightingMeasures/i,
          /^5+FIREANDEXPLOSIONDATA/i,
          /^5+FIREEXPLOSIONHAZARDANDREACTIVITYDATA/i,
          /^5+Mesuresdeluttecontrelincendie/i,
          /^5+MesuresPrendreEnCasDIncendie/i,
          /^5+mesuresdelutteencasdincendie/i,
          /^FirefightingMeasuresMesuresDeLutteContreLIncendie$/i
        ]
      },
      {
        field: 'accidental_release_measures',
        title: translate['PROCESS.SOLR_FORM.SECTION_6'],
        fuzzyMatch: [
          /^6+AccidentalReleaseMeasures/i,
          /^6+SPILLORLEAKPROCEDURES/i,
          /^6+SPILLANDDISPOSALPROCEDURES/i,
          /^6+Mesuresprendreencasdedispersionaccidentelle/i,
          /^6+MesuresDeDchargementAccidentel/i,
          /^6+MesuresPrendreEnCasDeDversements?Accident[ae]ls?/i,
          /^6+AccidentalMaterialReleaseOrSpillControlMeasures/i,
          /^6+librationaccidentelledematriauxoumesuresdecontrle/i,
          /^AccidentalReleaseMeasuresMesuresPrendreEnCasDeDispersionAccidentelle$/i
        ]
      },
      {
        field: 'handling_storage',
        title: translate['PROCESS.SOLR_FORM.SECTION_7'],
        fuzzyMatch: [
          /^7+HandlingandStorage/i,
          /^7+Manipulationetstockage/i,
          /^7+REACTIVITYDATA/i,
          /^7+PRECAUTIONARYMEASURES/i,
          /^7+SPECIALPRECAUTIONS/i,
          /^7+Manutentionetentreposage/i,
          /^7+ManutentionEtStockage/i,
          /^7+TraiterEtStockage/i,
          /^7+manipulationetentreposage/i,
          /^HandlingAndStorageManipulationEtEntreposage$/i
        ]
      },
      {
        field: 'exposure_controls_personal_protection',
        title: translate['PROCESS.SOLR_FORM.SECTION_8'],
        fuzzyMatch: [
          /^8+ExposureControlsPersonalProtection/i,
          /^8+EXPOSURECONTROLS/i,
          /^8+ExposureControlPersonalProtection/i,
          /^8+PREVENTIVEMEASURES/i,
          /^8+SPECIALPROTECTIONINFORMATION/i,
          /^8+PROTECTIVEEQUIPMENT/i,
          /^8+PREVENTATIVEMEASURES/i,
          /^8+Contrledelexpositionetprotectionindividuelle/i,
          /^8+Contrles?delexpositionprotectionindividuelle/i,
          /^8+ContrlesDExpositionProtectionPersonnelle/i,
          /^8+commandesdexpositionprotectionpersonnelle/i,
          /^8+contrlesdesexpositions/i,
          /^ExposureControlAndPersonalProtectionContrleDeLExpositionEtProtectionPersonnelle$/i
        ]
      },
      {
        field: 'physical_chemical_properties',
        title: translate['PROCESS.SOLR_FORM.SECTION_9'],
        fuzzyMatch: [
          /^9+PhysicalandChemicalProperties/i,
          /^9+PHYSICALPROPERTIES/i,
          /^9+PHYSICALCHEMICALCHARACTERISTICS/i,
          /^9+PHYSICALDATA/i,
          /^9+Propritsphysiquesetchimiques/i,
          /^9+PropritsMatriellesEtChimiques/i,
          /^PhysicalAndChemicalPropertiesPropritsPhysiquesEtChimiques$/i
        ]
      },
      {
        field: 'stability_reactivity',
        title: translate['PROCESS.SOLR_FORM.SECTION_10'],
        fuzzyMatch: [
          /^10+StabilityandReactivity/i,
          /^10+Stabilitetractivit/i,
          /^StabilityAndReactivityStabilitEtRactivit$/i
        ]
      },
      {
        field: 'toxilogial_information',
        title: translate['PROCESS.SOLR_FORM.SECTION_11'],
        fuzzyMatch: [
          /^11+ToxicologicalInformation/i,
          /^11+ToxicologyInformation/i,
          /^11+Informationtoxicologique/i,
          /^11+TOXICOLOGICALPROPERTIES/i,
          /^11+LInformation(De)?Toxicologi(e|que)/i,
          /^11+DonnesToxicologiques/i,
          /^11+renseignementstoxicologiques/i,
          /^ToxicologicalInformationInformationToxicologique$/i
        ]
      },
      {
        field: 'ecological_information',
        title: translate['PROCESS.SOLR_FORM.SECTION_12'],
        fuzzyMatch: [
          /^12+EcologicalInformation/i,
          /^12+ECOTOXICOLOGICALINFORMATION/i,
          /^12+ECOLOGICALCONSIDERATIONS/i,
          /^12+Informationscologiques/i,
          /^12+Informationsurlcologie/i,
          /^12+LInformationcologique/i,
          /^12+DonnesCologiques/i,
          /^12+renseignementscologiques/i,
          /^EcologicalInformationInformationscologiques$/i
        ]
      },
      {
        field: 'disposal_considerations',
        title: translate['PROCESS.SOLR_FORM.SECTION_13'],
        fuzzyMatch: [
          /^13+DisposalConsiderations/i,
          /^13+DisposalConsideration/i,
          /^13+SPILLANDDISPOSALPROCEDURES/i,
          /^13+Considrationslorsdellimination/i,
          /^13+Considrationsrelativesllimination/i,
          /^13+ConsidrationsDeDisposition/i,
          /^13+DonnesSurLlimination/i,
          /^DisposalConsiderationsConsidrationsRelativesLlimination$/i
        ]
      },
      {
        field: 'transport_information',
        title: translate['PROCESS.SOLR_FORM.SECTION_14'],
        fuzzyMatch: [
          /^14+TransportInformation/i,
          /^14+TransportationInformation/i,
          /^14+Informationrelativeautransport/i,
          /^14+Informationsrelativesautransport/i,
          /^14+LInformationDeTransport/i,
          /^TransportInformationInformationSurLeTransport$/i
        ]
      },
      {
        field: 'regulatory_information',
        title: translate['PROCESS.SOLR_FORM.SECTION_15'],
        fuzzyMatch: [
          /^15+RegulatoryInformation/i,
          /^15+Informationrglementaire/i,
          /^15+Informationsrglementaires/i,
          /^15+LInformationDeNormalisation/i,
          /^15+InformationsSurL[ae]Rglementation/i,
          /^15+informationslgales/i,
          /^RegulatoryInformationRenseignementsSurLaRglementation$/i
        ]
      },
      {
        field: 'other_information',
        title: translate['PROCESS.SOLR_FORM.SECTION_16'],
        fuzzyMatch: [
          /^16+OtherInformation/i,
          /^16+PREPARATIONINFORMATION/i,
          /^16+ADDITIONALINFORMATION/i,
          /^16+Renseignementssupplmentaires/i,
          /^16+Autresinformations/i,
          /^16+LAutreInformation/i,
          /^OtherInformationAutresRenseignements$/i
        ]
      }
    ];
  }

  $scope.hazardSelection = [];
  $scope.ppe_private_companyIds = [];
  $scope.ppeSelection = [];
  $scope.companiesPPE = {};
  $scope.companies = {};
  $scope.productsInSds = {};
  $scope.companySelection = [];

  let tagSelection = [];

  var textFilters = [
    /^MSDS\s?#\s?\d+/i,
    /^©/i,
    /^page\.?\s*\d+\s*(?:(?:of)|(?:de\.?)|(?:\/))\s*\d+/i,
    /_{3,}/,
    /end\s*of\s*sds/i,
    /^Fiche\s*Signalétique\./i
  ];

  let tagsWereChanged = [];

  var issueDateFilter = [
    /(.*)(issue date)(.*)/i,
    /(.*)(Issuing Date)(.*)/i,
    /(.*)(Date of Revision)(.*)/i,
    /(.*)(Revision Date)(.*)/i,
    /(.*)(date d'édition)(.*)/i,
    /(.*)(Date d'impression)(.*)/i,
    /(.*)(MSDS Preparation Date)(.*)/i,
    /(.*)(MSDS Date Of Preparation)(.*)/i,
    /(.*)(Effective Date)(.*)/i,
    /(.*)(DATE PREPARED)(.*)/i,
    /(.*)(Revised)(.*)/i,
    /(.*)(^DATE)(.*)/i,
    /(.*)(Supplier MSDS date)(.*)/i,
    /(.*)(creation date)(.*)/i
  ];

  var productNameFilter = [
    /.*Product name/i,
    /.*ProductName/i,
    /.*ChemicalName/i,
    /.*Nom du produit/i,
    /.*Nom chimique/i,
    /.*Product Description/i,
    /.*Generic Name/i,
    /.*Chemical Name/i,
    /.*Trade Name/i,
    /.*MSDS Name/i,
    /^Produit/i
  ];

  let companyNotUniqueAttrs = {};

  vm.isCompanyAttrNotUnique = function (attrName, companyId) {
    return !!~(companyNotUniqueAttrs[companyId] || []).indexOf(attrName);
  };

  $scope.addNewProduct = function () {
    return ModalService.open({
      templateUrl: 'createProductModal.html',
      size: 'xlg',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          const modal = this;

          modal.product = {
            name: '',
            sdsId: [$scope.sdsDocument.id],
            sdsRequiredDisabled: true
          };

          modal.onCreated = function (product) {
            const request = {
              data:
                '[' +
                JSON.stringify({ id: $scope.sdsDocument.id, products: { add: product.id } }) +
                ']'
            };
            SolrDocument.update(request).$promise.then(function () {
              $scope.productsInSds[product.id] = true;
              vm.products.push(product);
              $uibModalInstance.close(product);
            });
          };

          modal.cancel = function () {
            $uibModalInstance.dismiss();
          };
        }
      ]
    });
  };

  $scope.addAttachment = function (event) {
    event.stopPropagation();
    AttachmentService.addAttachmentModal($scope.sdsDocument.id, $scope).then(function (res) {
      for (let i in res) {
        const item = res[i];
        vm.attachments.push(item);
      }
      vm.attachments = AttachmentService.filterAttachments(vm.attachments);
    });
  };

  $scope.saveSdsDocument = SpinnerService.wrap(function () {
    addProductsCompanies();

    //set the save states
    const isDirtyState = $scope.isDirty;
    $scope.saveState = 'Saving ... ';
    $scope.saveError = false;
    $scope.isDirty = false;

    return saveData()
      .then(() => {
        formateDateModified();

        $scope.saveState = 'Save Complete';
        companyNotUniqueAttrs = {};
      })
      .catch(err => {
        formateDateModified();

        $scope.saveState = '';
        $scope.saveError = true;
        $scope.isDirty = isDirtyState;
      });
  });

  $scope.changed = function () {
    setUnsavedStatus();
  };

  $scope.checkLegislationCode = SpinnerService.wrap(function ($item, $model, $label, formInput) {
    return SolrDocument.canAddLegislationCodeToSDSLegislationGroup(
      {},
      { sdsId: $scope.sdsDocument.id, legislationCode: $scope.selectedLegislation.code }
    )
      .$promise.then(response => {
        if (response.result) {
          vm.typeaheadSelected($item, $model, $label, formInput);
        } else {
          MessagesService.warning('This code is already in use for this SDS legislation group');

          $scope.selectedLegislation = getLegislationByCode($scope.sdsDocument.legislationCode);
        }
      })
      .catch(err => {
        $scope.selectedLegislation = getLegislationByCode($scope.sdsDocument.legislationCode);

        simpleCatch(err);
      });
  });

  // to uncheck NFPA radio
  $scope.changedNFPA = function (event, el) {
    if (event.target.value === vm.changeCopyNFPA[el]) {
      $scope.processViewModel.nfpa[el] = false;
    }
    vm.changeCopyNFPA[el] = $scope.processViewModel.nfpa[el];

    $scope.changed();
  };

  $scope.changeVerifiedStatus = function () {
    setUnsavedStatus();

    if ($scope.sdsDocument.isVerified) {
      $scope.sdsDocument.dateModified = Math.floor(new Date().getTime() / 1000);
      formateDateModified();
    }
  };

  function setUnsavedStatus() {
    $scope.saveState = '';
    $scope.saveError = false;
    $scope.isDirty = true;
  }

  function saveData() {
    const sdsId = $scope.sdsDocument.id;
    //add in the hazards, tags and companies
    $scope.sdsDocument.nfpa = {
      set: Object.values(vm.nfpa)
        .map(el => el)
        .filter(el => el)
    };

    $scope.sdsDocument.hazardType = { set: $scope.hazardSelection };
    $scope.sdsDocument.ppe_private_companyIds = { set: $scope.ppe_private_companyIds };
    $scope.sdsDocument.ppe = { set: $scope.ppeSelection };
    for (const ppeKey in $scope.companiesPPE) {
      $scope.sdsDocument[ppeKey] = { set: $scope.companiesPPE[ppeKey] };
    }
    $scope.sdsDocument.language = { set: SearchFactory.getSelectedLanguages() };
    const langStr = $scope.sdsDocument.language.set.join(',');
    $scope.sdsDocument.mirrorSDS = {
      set: SearchFactory.languages.filter(function (el) {
        return langStr.indexOf(el) < 0;
      })
    };
    $scope.sdsDocument.tag = { set: tagSelection };
    $scope.sdsDocument.companyId = { set: $scope.companySelection };
    $scope.sdsDocument.disposalCodes = { set: vm.getSelectedDisposalCodes() };
    $scope.sdsDocument.storageCodes = { set: vm.getSelectedStorageCodes() };
    $scope.sdsDocument.products = {
      set: deObjectify($scope.productsInSds)
    };
    if ($scope.selectedLegislation) {
      $scope.sdsDocument.legislationCode = { set: $scope.selectedLegislation.code };
    }
    if ($scope.selectedManufacturer && $scope.selectedManufacturer.id) {
      $scope.sdsDocument.manufacturer = { set: $scope.selectedManufacturer.id };
      $scope.sdsDocument.manufacturerName = {
        set: encodeURIComponent($scope.selectedManufacturer.name)
      };
    } else {
      $scope.sdsDocument.manufacturer = { set: null };
      $scope.sdsDocument.manufacturerName = { set: null };
    }
    $scope.sdsDocument.synonyms = { set: getSynonyms(true) };

    return getNotUniqueAttrs(vm.attrs, sdsId).then(function (notUniqueAttrs) {
      const companiesWithNotUniqueAttrs = Object.keys(notUniqueAttrs);

      if (companiesWithNotUniqueAttrs.length) {
        companyNotUniqueAttrs = notUniqueAttrs;

        stopSpinner();
        setUnsavedStatus();
        MessagesService.error('COMMON.MESSAGES.SOME_NOT_UNIQUE', {
          fields: companiesWithNotUniqueAttrs
            .reduce(function (fieldsStr, companyId) {
              return (fieldsStr += notUniqueAttrs[companyId].join(', ') + ', ');
            }, '')
            .replace(/, $/, '')
        });

        return;
      }

      let lPromise = $q(resolve => resolve({ count: 0 }));

      if (!isEmpty($scope.productsInSds)) {
        lPromise = Product.updateSdsId({ sdsId: sdsId }, $scope.productsInSds).$promise;
      }

      return lPromise
        .then(function () {
          //this copies the json into a new object so we don't affect the form data
          var cleanData = JSON.parse(JSON.stringify($scope.sdsDocument));

          return saveDocumentModel(cleanData, null, false);
        })
        .then(function () {
          return SolrDocument.setAttrs({
            id: sdsId,
            attrs: vm.attrs,
            withPropagation: false
          }).$promise;
        })
        .then(function () {
          return SolrDocument.propagateToLinked({
            id: sdsId,
            copyFields: ['companyId', 'tag', 'restricted_in_*', 'attr_*', 'products']
          }).$promise;
        })
        .then(function () {
          if (!newAddedCompanies.length) return;

          const sdsIndex = linkedSds.indexOf($scope.sdsDocument.id);

          if (!~sdsIndex) linkedSds.push($scope.sdsDocument.id);

          return SolrDocument.notify(
            { actionName: 'added' },
            { companyIds: newAddedCompanies, sdsIds: linkedSds }
          ).$promise;
        })
        .then(function () {
          newAddedCompanies = [];
        })
        .catch(simpleCatch);
    });
  }

  function getNotUniqueAttrs(attrs, sdsId) {
    const getAttrCompanies = function (attrs) {
      return Object.keys(attrs);
    };
    const filterEmptyAttrs = function (attrs) {
      return Object.keys(attrs)
        .filter(function (attrName) {
          return attrs[attrName];
        })
        .reduce(function (attr, attrName) {
          attr[attrName] = attrs[attrName];

          return attr;
        }, {});
    };
    const checkCompanyAttrs = function (companyId) {
      const companyAttrs = filterEmptyAttrs(attrs[companyId]);

      if (!Object.keys(companyAttrs).length) return $q.resolve(null);

      return SolrDocument.getNotUniqueAttrs({
        attrs: companyAttrs,
        companyId: companyId,
        sdsId: sdsId
      }).$promise.then(function (res) {
        if (!res.notUniqueAttrs.length) return null;

        const obj = {};
        obj[companyId] = res.notUniqueAttrs;

        return obj;
      });
    };

    return $q.all(getAttrCompanies(attrs).map(checkCompanyAttrs)).then(function (resArr) {
      return resArr
        .filter(function (res) {
          return !!res;
        })
        .reduce(function (obj, companyNotUniqueAttrs) {
          const companyId = Object.keys(companyNotUniqueAttrs)[0];
          obj[companyId] = companyNotUniqueAttrs[companyId];

          return obj;
        }, {});
    });
  }

  function getSynonyms(onSave) {
    var synonyms = [];
    var synonymsString = '';
    const synonymRegex = new RegExp(
      [
        '(?:',
        '(?:common\\s*name\\w?)|',
        '(?:other\\s*name\\w?)|',
        '(?:alternate\\s*names?\\s*)|',
        '(?:autre\\s*nom\\s*)|',
        '(?:(?:d.)?autres\\s*(?:moyens\\s*)?(?:d.)?identifications?:)|',
        '(?:synonymes?)|',
        '(?:identification\\s*du\\s*produit)|',
        '(?:synonym\\w*\\s*(?:\\/?\\s*gener\\w*\\s*name\\w?)?)|',
        '(?:other\\s*(?:means\\s*of\\s*)?identification\\s*:(?!\\s*\\n))',
        ')',
        '\\s*:?\\s*',
        '(.*)'
      ].join(''),
      'ig'
    );
    const nonSynonymRegex = new RegExp(
      [
        '(?:',
        '(?:.*synonym.*)|',
        '(?:\\s*n\\/a\\.?\\s*?)|',
        '(?:\\s*none\\.?\\s*?)|',
        '(?:\\s*non\\s*disponible\\.?\\s*)|',
        '(?:\\s*aucun\\s*list%C3%A9\\.?\\s*)|',
        '(?:\\s*aucun\\(e\\)\\.?\\s*)|',
        '(?:\\s*aucune?\\.?\\s*)|',
        '(?:\\s*ninguno\\.?\\s*?)|',
        '(?:\\s*not\\s*applicable\\.?\\s*?)|',
        '(?:\\s*not\\s*available\\.?\\s*?)',
        ')'
      ].join(''),
      'ig'
    );
    var product_and_company_identification =
      $scope.sdsDocument.product_and_company_identification.set;
    var matches;

    if (synonymRegex.test(product_and_company_identification)) {
      synonymRegex.lastIndex = 0;

      while ((matches = synonymRegex.exec(product_and_company_identification)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (matches.index === synonymRegex.lastIndex) {
          synonymRegex.lastIndex++;
        }

        // The result can be accessed through the `matches`-variable.
        synonymsString = matches[1].trim();

        synonyms = synonyms.concat(synonymsString.split(/\s*[;/]\s*/g));
      }

      synonyms = synonyms.filter(function (synonym) {
        nonSynonymRegex.lastIndex = 0;
        return !nonSynonymRegex.test(synonym);
      });

      synonyms = synonyms.map(function (synonym) {
        return encodeURIComponent(synonym);
      });
    } else if (
      onSave &&
      (!$scope.sdsDocument.synonyms ||
        $scope.sdsDocument.synonyms.length === 0 ||
        ($scope.sdsDocument.synonyms.set && !$scope.sdsDocument.synonyms.set.length))
    ) {
      synonymsString = prompt(
        `ATTENTION: There aren't any synonyms in the first section!\n Would you like to add it manually?`
      );

      if (synonymsString) {
        synonyms = synonymsString.split(/\s*[;/]\s*/g);

        synonyms = synonyms.map(function (synonym) {
          return encodeURIComponent(synonym);
        });

        product_and_company_identification += '\n\nSynonyms: ' + synonymsString;
      }
    } else if (!onSave && $scope.sdsDocument.synonyms && $scope.sdsDocument.synonyms.length) {
      product_and_company_identification += '\n\nSynonyms: ';

      $scope.sdsDocument.synonyms.forEach(function (synonym) {
        synonym = decodeURIComponent(synonym);
        product_and_company_identification += synonym + ', ';
      });

      product_and_company_identification = product_and_company_identification.replace(/,\s?$/, '');
    }

    $scope.sdsDocument.product_and_company_identification.set = product_and_company_identification;

    return synonyms;
  }

  function formateDateModified() {
    const currentDate = new Date();
    const currentFullDate = getFullDate(currentDate);
    const modifiedDate = new Date($scope.sdsDocument.dateModified * 1000);
    const modifiedFullDate = getFullDate(modifiedDate);

    if ($scope.sdsDocument.dateModified && currentFullDate !== modifiedFullDate) {
      $scope.formatedDateModified = modifiedFullDate;
    } else {
      $scope.formatedDateModified = 'today';
    }
  }

  function getFullDate(date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  //load the list of files that are awaiting processing
  $scope.load = function () {
    $http.get('/api/containers/upload/files').success(function (data) {
      $scope.files = data;

      $scope.files.forEach(function (file) {
        file.resourcePath = '/api/containers/upload/download/' + file.name;
      });
    });
  };

  // delete the document
  $scope.delete = function (index, id) {
    $http
      .delete('/api/containers/upload/files/' + encodeURIComponent(id))
      .success(function (data, status, headers) {
        $scope.files.splice(index, 1);
      });
  };

  // toggle selection for a given hazard by name
  $scope.toggleHazard = function (hazard) {
    setUnsavedStatus();
    var idx = $scope.hazardSelection.indexOf(hazard);
    if (idx > -1) {
      $scope.hazardSelection.splice(idx, 1);
      $('#' + hazard).prop('checked', false);
    } else {
      $scope.hazardSelection.push(hazard);
      $('#' + hazard).prop('checked', true);
    }
  };

  $scope.propagatePublicPPE = function () {
    vm.allSdsCompanies.companyIds.forEach(companyId => {
      if (
        !vm.companySettingsByCompanyId[companyId] ||
        vm.companySettingsByCompanyId[companyId].showOnlyCompanyPPE ||
        $scope.ppe_private_companyIds.includes(companyId)
      ) {
        return;
      }
      const key = `ppe_${companyId}`;
      if (!$scope.companiesPPE[key]) {
        $scope.companiesPPE[key] = [];
      }
      $scope.companiesPPE[key] = [...$scope.ppeSelection];
    });
  };

  // toggle selection for a given ppe by name
  $scope.togglePPE = function (ppe) {
    setUnsavedStatus();
    const idx = $scope.ppeSelection.indexOf(ppe);
    if (idx > -1) {
      $scope.ppeSelection.splice(idx, 1);
    } else {
      $scope.ppeSelection.push(ppe);
    }
    $scope.ppeSelection = $scope.ppeSelection.sort();
    $scope.propagatePublicPPE();
  };

  $scope.addToPrivateCompanyPPE = function (companyId) {
    if (!$scope.ppe_private_companyIds.includes(companyId)) {
      $scope.ppe_private_companyIds.push(companyId);
    }
  };

  $scope.toggleCompanyPPE = function (companyId, key, ppe) {
    setUnsavedStatus();
    if (!$scope.companiesPPE[key]) {
      $scope.companiesPPE[key] = [];
    }
    const idx = $scope.companiesPPE[key].indexOf(ppe);
    if (idx > -1) {
      $scope.companiesPPE[key].splice(idx, 1);
    } else {
      $scope.companiesPPE[key].push(ppe);
    }
    $scope.companiesPPE[key] = $scope.companiesPPE[key].sort();
    $scope.addToPrivateCompanyPPE(companyId);
  };

  function getSdsDocumentData() {
    SdsDocumentSolrMap.find({
      filter: { where: { solrId: $scope.sdsDocument.id } }
    }).$promise.then(
      function (response) {
        if (response[0]) {
          vm.selectedStorageCode = response[0].storageCodes;
          vm.selectedDisposalCode = response[0].disposalCodes;
        } else {
          vm.selectedStorageCode = {};
          vm.selectedDisposalCode = {};
        }

        vm.allSdsCompanies.companyIds.forEach(function (companyId) {
          GetCompanySettings(companyId, function () {});
        });
      },
      function (error) {}
    );
    return true;
  }

  vm.getSelectedDisposalCodes = function () {
    var disposalCodes = [];
    Object.keys(vm.selectedDisposalCode).forEach(function (key) {
      if (vm.selectedDisposalCode[key]) {
        disposalCodes.push(vm.selectedDisposalCode[key]);
      }
    });

    return disposalCodes;
  };

  vm.getSelectedStorageCodes = function () {
    var storageCodes = [];
    Object.keys(vm.selectedStorageCode).forEach(function (key) {
      if (vm.selectedStorageCode[key]) {
        storageCodes.push(vm.selectedStorageCode[key]);
      }
    });

    return storageCodes;
  };

  $scope.deleteCompanyPPE = function () {
    const allCompaniesPpeKeys = Object.keys($scope.companiesPPE);
    const allSdsCompanies = vm.allSdsCompanies.companyIds.map(value => `ppe_${value}`);
    allCompaniesPpeKeys.forEach(key => {
      if (!allSdsCompanies.includes(key)) {
        $scope.companiesPPE[key] = [];
        const companyId = key.replace('ppe_', '');
        if ($scope.ppe_private_companyIds.includes(companyId)) {
          const idx = $scope.ppe_private_companyIds.indexOf(companyId);
          $scope.ppe_private_companyIds.splice(idx, 1);
        }
      }
    });
  };

  $scope.resetToPublicPpe = function (companyId, companyPpeKey) {
    setUnsavedStatus();
    $scope.companiesPPE[companyPpeKey] = [...$scope.ppeSelection];
    const idx = $scope.ppe_private_companyIds.indexOf(companyId);
    $scope.ppe_private_companyIds.splice(idx, 1);
  };

  // toggle selection for a given company by name
  $scope.toggleCompany = function (companyId, initialToggle) {
    const companyWasUnchecked = !$('#' + companyId).prop('checked') && !initialToggle;

    if (!initialToggle) {
      if (companyWasUnchecked) {
        newAddedCompanies = newAddedCompanies.filter(function (id) {
          return id !== companyId;
        });
      } else {
        newAddedCompanies = newAddedCompanies.concat(companyId);
      }
    }

    if (
      companyWasUnchecked &&
      $scope.sdsDocument.products &&
      Array.isArray($scope.sdsDocument.products.set)
    ) {
      const products = $scope.sdsDocument.products.set,
        productsById = pivotArray(vm.products);

      const companyProducts = products.filter(pId => {
        return (
          get(productsById[pId], 'inventoriedInCompanies', []).includes(companyId) ||
          get(productsById[pId], 'privateCompanyId', '') === companyId
        );
      });
      if (companyProducts.length > 0) {
        showCompanyProductWarningModal();
        $('#' + companyId).prop('checked', true);
        return;
      } else {
        CompanyService.hasProducts(companyId, products).then(function (companyHasProducts) {
          if (companyHasProducts) {
            showCompanyProductWarningModal();
            $('#' + companyId).prop('checked', true);

            return;
          }

          handleCompanyToggling(companyId);
        });
      }
    } else {
      handleCompanyToggling(companyId);
    }

    function handleCompanyToggling(companyId) {
      setUnsavedStatus();

      var idx = $scope.companySelection.indexOf(companyId);
      if (idx > -1) {
        removeSdsCompanySelection(companyId);
        ppeHandler();
      } else {
        addSdsCompanySelection(companyId);

        const company = lookupCompany(companyId);
        if (company && company.childSdsCompanies) {
          company.childSdsCompanies.forEach(company => {
            GetTagsForCompany(company.id);
            // setTimeout - sometimes storage and disposal codes don't set with the first time
            setTimeout(() => GetCompanySettings(company.id, ppeHandler));
          });
        }

        if (initialToggle !== true) {
          GetCompanySettings(companyId, ppeHandler);

          if (vm.isSdsChildCompany(company)) {
            const sdsParentCompanyId = company.sdsParentCompanyId;

            addSdsCompanySelection(sdsParentCompanyId);
            GetCompanySettings(sdsParentCompanyId, ppeHandler);
          }
        }
      }
    }

    function ppeHandler() {
      if (!initialToggle) {
        $scope.propagatePublicPPE();
        $scope.deleteCompanyPPE();
      }
    }
  };

  // toggle selection for a given tag by name
  $scope.toggleTag = function (tag, companyId) {
    setUnsavedStatus();

    if (!~tagsWereChanged.indexOf(tag.id)) tagsWereChanged.push(tag.id);

    vm.UniversalTags.toggleTagSelected(tag.id);

    vm.allSdsCompanies.companyIds.forEach(function (companyId) {
      if (vm.tagsByCompanyId[companyId]) {
        vm.tagsByCompanyId[companyId].toggleTagSelected(tag.id);
      }
    });

    if (companyId && vm.tagsByCompanyId[companyId].restricted) $scope.getRestrictedNotes(companyId);

    var idx = tagSelection.indexOf(tag.id);
    if (idx > -1) {
      tagSelection.splice(idx, 1);
    } else {
      tagSelection.push(tag.id);
    }

    checkParentTagIfAllChildrenAreSelected(tag);
  };

  $scope.deleteDocument = function () {
    SolrDocument.deleteById({
      id: $scope.sdsDocument.id
    })
      .$promise.then(function (res) {
        $scope.documentDeleted = true;
        $scope.sdsDocument = {};

        const previousVersionId = res.previousVersionId;
        previousVersionId && $state.go('viewSds', { id: previousVersionId });
      })
      .catch(ErrorService.simpleCatch);
  };

  $scope.processAllDocuments = function () {
    $scope.files.forEach(function (file, index) {
      if (!file.kit && (!file.state || file.state == '')) {
        processDocument(file);
      }
    });
  };

  $scope.removeAllDocuments = SpinnerService.wrap(function () {
    const filesToDelete = $scope.files.filter(file => !file.state);

    return Promise.all(
      filesToDelete.map(file =>
        $http.delete('/api/containers/upload/files/' + encodeURIComponent(file.name))
      )
    ).then(() => {
      $scope.files = $scope.files.filter(file => !filesToDelete.includes(file));
      $scope.$apply();
    });
  });

  $scope.processAsKit = function () {
    const filesPromises = $scope.files.reduce(function (arr, file, index) {
      if (file.kit && (!file.state || file.state == '')) {
        arr.push(processDocument(file));
      }

      return arr;
    }, []);

    $q.all(filesPromises)
      .then(function (docs) {
        kitIds = docs.map(function (doc) {
          return doc.id;
        });
        const docModels = docs.map(function (doc) {
          return {
            id: doc.id,
            kit_ids: { set: kitIds },
            kit_name: { set: setKitName(docs) }
          };
        });
        const postData = { data: JSON.stringify(docModels) };

        return SolrDocument.update(postData);
      })
      .catch(simpleCatch);
  };

  $scope.isAnyKitUnfinished = function () {
    return ($scope.files || []).some(function (file) {
      return file.kit && (!file.state || file.state == '');
    });
  };

  $scope.isKitProcessed = function () {
    const kitFiles = getKitFiles();

    if (!kitFiles.length) return false;

    return kitFiles.every(function (file) {
      return file.state === 'complete';
    });
  };

  $scope.areAllFilesProcessed = function () {
    return ($scope.files || []).every(function (file) {
      return file.state === 'complete';
    });
  };

  function setKitName(docs) {
    const kitCover = vm.kitCoverFile;
    if (kitCover != null && typeof kitCover === 'object' && kitCover.name) {
      return encodeURIComponent(getNameFromFile(kitCover.name));
    }

    if (typeof kitCover === 'string' && kitCover !== '') {
      return kitCover;
    }

    const chemicalFriendlyName = docs[0].chemicalFriendlyName;
    if (
      chemicalFriendlyName != null &&
      typeof chemicalFriendlyName === 'object' &&
      chemicalFriendlyName.set !== ''
    ) {
      return chemicalFriendlyName.set;
    }

    if (
      chemicalFriendlyName != null &&
      typeof chemicalFriendlyName === 'string' &&
      chemicalFriendlyName !== ''
    ) {
      return chemicalFriendlyName;
    }

    const kitFiles = getKitFiles();
    if (!kitFiles.length) throw `Can't set the kit name`;

    return encodeURIComponent(getNameFromFile(kitFiles[0].name));
  }

  function lookupCompany(companyId) {
    return $scope.companies[companyId];
  }

  function addSdsCompanySelection(companyId) {
    const idx = $scope.companySelection.indexOf(companyId);

    if (!~idx) {
      vm.allSdsCompanies.add(companyId);
      $scope.companySelection.push(companyId);
      $('#' + companyId).prop('checked', true);
      GetTagsForCompany(companyId);
    }
  }

  function removeSdsCompanySelection(companyId) {
    const idx = $scope.companySelection.indexOf(companyId);

    if (~idx) {
      vm.allSdsCompanies.remove(companyId);
      $scope.companySelection.splice(idx, 1);
      $('#' + companyId).prop('checked', false);
    }
  }

  function getKitFiles() {
    return ($scope.files || []).filter(function (file) {
      return file.kit;
    });
  }

  //create a documentModel for this file, add to solr, extract the contents, and save the model back
  //callback returns the new created documentModel
  function processDocument(file, callback) {
    var documentModel = createDocumentModel(file);
    file.resourcePath = documentModel.resourcePath;
    file.state = 'adding...';
    return addDocument(file, function (err, data) {
      if (err) {
        //console.log("Error adding " + JSON.stringify(err));
        file.state = 'error';
        if (
          err.data &&
          err.data.error &&
          err.data.error.message &&
          err.data.error.message == 'file exists'
        ) {
          file.state = 'error : file already exists';
        }

        return callback && callback(err, null);
      }
    })
      .$promise.then(function (data) {
        //set new id
        documentModel.id = data.id;
        file.id = data.id;

        if (file.kit && file === vm.kitCoverFile) documentModel.kit_cover = true;

        file.state = 'saving...';
        return saveDocumentModel(documentModel, function (err) {
          if (err) {
            file.state = 'error';
            callback && callback(err, null);
          }
        });
      })
      .then(function () {
        file.state = 'extracting...';

        return autoExtractSections(file.name, documentModel, function (err) {
          if (!err) {
            file.state = 'complete';
            callback && callback(null, documentModel);
          } else {
            file.state = 'error';
            callback && callback(err, null);
          }
        });
      });
  }

  function addDocument(file, callback) {
    console.log(file);
    var params = {};
    params.container = 'upload';
    params.file = encodeURIComponent(file.name);

    return SolrDocument.addDocument(
      params,
      function (data) {
        callback(null, data);
      },
      function (err) {
        //console.log("error adding : " + JSON.stringify(err));
        callback(err);
      }
    );
  }

  function saveDocumentModel(documentModel, callback, withPropagation = true) {
    //clean the inputs so no invalid json gets submitted.
    if (documentModel.chemicalFriendlyName && documentModel.chemicalFriendlyName.set) {
      documentModel.chemicalFriendlyName.set = encodeURIComponent(
        documentModel.chemicalFriendlyName.set
      );
      //documentModel.chemicalFriendlyName.set = documentModel.chemicalFriendlyName.set.replace(/\%20/g, " ");
      //documentModel.chemicalFriendlyName.set = "<![CDATA[" + documentModel.chemicalFriendlyName.set + "]]>";
      //documentModel.chemicalFriendlyName.set = unescape(encodeURIComponent(documentModel.chemicalFriendlyName.set));
    }

    if (documentModel.issueDate && documentModel.issueDate.set) {
      documentModel.issueDate.set = encodeURIComponent(documentModel.issueDate.set);
    }

    if (documentModel.safe_handling && documentModel.safe_handling.set) {
      documentModel.safe_handling.set = encodeURIComponent(documentModel.safe_handling.set);
    }

    if (documentModel.new_jersey_right && documentModel.new_jersey_right.set) {
      documentModel.new_jersey_right.set = encodeURIComponent(documentModel.new_jersey_right.set);
    }

    vm.allSdsCompanies.companyIds.forEach(function (companyId) {
      if (
        documentModel['restricted_in_' + companyId] &&
        documentModel['restricted_in_' + companyId].set
      ) {
        documentModel['restricted_in_' + companyId].set = encodeURIComponent(
          documentModel['restricted_in_' + companyId].set
        );
      }
    });

    $scope.sdsTextSections.forEach(function (section) {
      if (documentModel[section.field]) {
        documentModel[section.field].set = encodeURIComponent(documentModel[section.field].set);
        //documentModel[section.field].set = documentModel[section.field].set.replace(/\%20/g, " ");
      }
    });

    if (documentModel.kit_name && documentModel.kit_name.set) {
      documentModel.kit_name.set = encodeURIComponent(documentModel.kit_name.set);
    }

    //create post data
    var postData = { data: '[' + JSON.stringify(documentModel) + ']' };

    //console.log("post data : " + JSON.stringify(postData));

    return SolrDocument.update(postData)
      .$promise.then(function (result) {
        var additionalData = {
          solrId: documentModel.id,
          disposalCodes: vm.selectedDisposalCode,
          storageCodes: vm.selectedStorageCode
        };
        const sdsDocumentSolrMapPromise = SdsDocumentSolrMap.find({
          filter: { where: { solrId: additionalData.solrId } }
        }).$promise.then(function (response) {
          if (response[0]) additionalData.id = response[0].id;

          return SdsDocumentSolrMap.upsert(additionalData);
        });

        if (result.resp.updates) {
          if (result.resp.updates.nfpa) {
            $scope.setNFPA(result.resp.updates.nfpa);
          }
        }

        return $q
          .all([
            sdsDocumentSolrMapPromise,
            withPropagation &&
              SolrDocument.propagateToLinked({
                id: documentModel.id,
                copyFields: ['companyId', 'tag', 'restricted_in_*', 'attr_*', 'products']
              }).$promise
          ])
          .then(() => {
            callback && callback(null, documentModel);

            return documentModel;
          });
      })
      .catch(function (err) {
        if (callback) {
          callback(err);
        } else {
          throw err;
        }
      });
  }

  function autoExtractSections(filename, documentModel, callback) {
    var extractOptions = {};
    extractOptions.container = 'sds';
    extractOptions.file = filename;

    return SolrDocument.extractContent(extractOptions)
      .$promise.then(function (response) {
        parseContentToModel(documentModel, response.strings);
        return saveDocumentModel(
          documentModel,
          function (err, results) {
            callback(null, results);
          },
          function (err) {
            //console.log("oh no again! - and error : " + JSON.stringify(err));
            callback(err, null);
          }
        );
      })
      .catch(function (err) {
        //console.log("ERROR : " + JSON.stringify(err));
        callback(err, null);
      });
  }

  $scope.onProcessDocumentButtonClicked = function (file) {
    startSpinner();
    resetData();

    processDocument(file, function (err, documentModel) {
      stopSpinner();
      if (!err) {
        populateForm(documentModel);
      } else {
        //console.log("there was an error :" + JSON.stringify(err));
      }
    });
  };

  function addProductsCompanies() {
    const productsById = pivotArray(vm.products);
    let companies = [];
    Object.keys($scope.productsInSds).forEach(pId => {
      if ($scope.productsInSds[pId] && productsById[pId]) {
        let productCompanyIds = get(productsById[pId], 'companies', []).map(c => c.id);
        if (productsById[pId].privateCompanyId) {
          productCompanyIds.push(productsById[pId].privateCompanyId);
        }
        productCompanyIds.forEach(companyId => {
          if (!$scope.companySelection.includes(companyId)) {
            $scope.toggleCompany(companyId, true);
          }
        });
      }
    });

    return uniq(companies);
  }

  function resetData() {
    $scope.sdsDocument['issueDate'] = { set: '' };
    $scope.sdsDocument['chemicalFriendlyName'] = { set: '' };
    $scope.sdsDocument['dateCreated'] = '';
    $scope.sdsDocument['dateModified'] = '';
    $scope.sdsDocument['isVerified'] = false;
    $scope.saveState = '';
    $scope.saveError = false;
    $scope.isDirty = false;
    $scope.unmatched = '';
    $scope.documentDeleted = false;
  }

  $scope.reParseDocument = function (id) {
    //console.log("Reparse Content");
    startSpinner();
    $scope.documentDeleted = false;

    var extractOptions = {
      container: 'sds',
      file: $scope.currentFilename
    };

    return SolrDocument.extractContent(extractOptions)
      .$promise.then(function (response) {
        $scope.unmatched = '';
        parseContentToModel($scope.sdsDocument, response.strings, 'pdfjs');
        setUnsavedStatus();
        stopSpinner();
      })
      .catch(function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
        stopSpinner();
        $translate('COMMON.MESSAGES.DOCUMENT_NOT_FOUND').then(
          function (message) {
            showHideAlertPopup('danger', message);
          },
          function (translationId) {
            showHideAlertPopup('danger', translationId);
          }
        );
      });
  };

  $scope.reParseDocumentWithSolr = function (id) {
    //console.log("Reparse Content");
    startSpinner();
    $scope.documentDeleted = false;

    var extractParamStr = '{"container":"sds", "file":"' + $scope.currentFilename + '"}';
    var extractOptions = JSON.parse(extractParamStr);

    SolrDocument.extractDocumentContent(
      extractOptions,
      function (response) {
        $scope.unmatched = '';
        parseContentToModel($scope.sdsDocument, response.html.body['0'].div['0'].p, 'solr');
        setUnsavedStatus();
        stopSpinner();
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
        stopSpinner();
        $translate('COMMON.MESSAGES.DOCUMENT_NOT_FOUND').then(
          function (message) {
            showHideAlertPopup('danger', message);
          },
          function (translationId) {
            showHideAlertPopup('danger', translationId);
          }
        );
      }
    );
  };

  function showHideAlertPopup(type, msg) {
    var popup = '<div class="alert alert-popup alert-' + type + '">' + msg + '</div>';

    popup = $(popup).appendTo('body');
    popup.fadeIn(300);

    var timeOut = setTimeout(function () {
      $(popup).fadeOut(300, function () {
        $(this).remove();
      });
    }, 3000);
  }

  function data2model(data) {
    const exclude = ['id', 'isVerified', 'dateModified', 'dateCreated'];
    return Object.keys(data).reduce(function (sum, key) {
      sum[key] = ~exclude.indexOf(key) ? data[key] : { set: data[key] };
      return sum;
    }, {});
  }

  function fillCrossFields(documentModel) {
    for (let i = 0; i < $rootScope.languages.length; i++) {
      const crossName = documentModel['chemicalFriendlyName_' + $rootScope.languages[i]];
      if (crossName) {
        $scope.sdsDocument['chemicalFriendlyName_' + $rootScope.languages[i]] =
          decodeURIComponent(crossName);
      }
    }
  }
  function populateForm(documentModel) {
    $scope.currentFilename = getFilenameFromPath(documentModel.resourcePath.set);
    $scope.sdsDocument.id = documentModel.id;
    $scope.sdsDocument.resourcePath = { set: documentModel.resourcePath.set };
    $scope.sdsDocument.isVerified = false;

    $scope.sdsDocument['issueDate'] = {
      set: decodeURIComponent(documentModel.issueDate.set)
    };
    $scope.sdsDocument['chemicalFriendlyName'] = {
      set: decodeURIComponent(documentModel.chemicalFriendlyName.set)
    };

    fillCrossFields(documentModel);

    $scope.sdsDocument['safe_handling'] = {
      set: decodeURIComponent((documentModel.safe_handling || {}).set || '')
    };

    $scope.sdsDocument['new_jersey_right'] = {
      set: decodeURIComponent((documentModel.new_jersey_right || {}).set || '')
    };

    $scope.sdsTextSections.forEach(function (section) {
      if (documentModel[section.field])
        addContentToForm(section.field, decodeURIComponent(documentModel[section.field].set));
    });

    const ls = {};
    ls[documentModel.language[0] || 'en'] = true;
    SearchFactory.languageSelected = ls;

    //console.log("document currentfilename is : " + $scope.currentFilename);
    //console.log("document resourcePath is : " + documentModel.resourcePath.set);
  }

  //lines is an array of strings, each one is a line of text.
  //document model is the object to add sections too.
  function parseContentToModel(documentModel, lines, parseBy) {
    var currentSection = '';
    var cleanLine = '';
    var firstEmptyLineInSection = true;
    var chemtrecMarker = false;
    var chemtrecInfo = '';
    var productInfo = '';
    var productSection = false;
    var lastLineIndex = lines.length - 1;
    var sdsCode;
    var supplierInfo;
    var productCode;
    var fisherScientific = {
      manufacturer: '',
      distributor: '',
      labels: processingTemplates.fisherScientific.labels,
      bolds: processingTemplates.fisherScientific.bolds,
      skips: processingTemplates.fisherScientific.skipText
    };
    var filters = textFilters.slice();
    var issueDateFilters = issueDateFilter.slice();

    var regExs = {
      sdsCodeRegEx: /(\d.+).+(safety\s+data\s+sheet\s+page.+)/i,
      supplierInfoRegEx: /(Flinn Scientific Canada Inc).+(\n+.+){1,}\n*(\d+-\d+-\d+-\d+)/i,
      productCodeRegEx: /code:\s+(\S+?)\s*(product.*)?$/i
    };

    documentModel.issueDate = { set: '' };
    documentModel.chemicalFriendlyName = { set: '' };
    documentModel.safe_handling = { set: '' };

    // set EN by default
    SearchFactory.setLanguageSelected('en');

    //initialize the document models sections
    $scope.sdsTextSections.forEach(function (section) {
      documentModel[section.field] = { set: '' };
    });

    var fsm = StateMachine.create({
      initial: 'searchingForSectionHeader',
      events: [
        { name: 'sectionHeaderFound', from: '*', to: 'waitingForSectionStart' },
        {
          name: 'sectionStartFound',
          from: 'waitingForSectionStart',
          to: 'inSectionContent'
        },
        {
          name: 'emptyLineFound',
          from: 'inSectionContent',
          to: 'searchingForSectionParagraph'
        },
        {
          name: 'sectionParagraphFound',
          from: 'searchingForSectionParagraph',
          to: 'inSectionContent'
        }
      ],
      callbacks: {
        onsectionHeaderFound: function (event, from, to, newSection) {
          currentSection = newSection;
          cleanLine = ''; //don't add the header as content
          firstEmptyLineInSection = true;
        },
        onsectionStartFound: function (event, from, to, newContent) {
          documentModel[currentSection].set += newContent;
        },
        onsectionParagraphFound: function (event, from, to, newContent) {
          documentModel[currentSection].set += '\n\n' + newContent;
        }
      }
    });

    if ($scope.selectedTemplate === 'Flinn Scientific US' && parseBy !== 'solr') {
      filters.push(
        /©/i,
        /All\s?Rights\s?Reserved/i,
        /^FLINNSCIENTIFIC,\s?INC\.$/i,
        /^Safety\s?Data\s?Sheet$/i
      );

      regExs.sdsCodeRegEx = /SDS\s*#:\s*\d+\.\d+/i;
      regExs.company = /(.+\n+){0,}(Flinn\s*Scientific,\sInc.*?)(\n+.*){1,}/i;
      regExs.chemtrec = /(CHEMTREC)(.*)(Signal Word)/i;
    }

    if ($scope.selectedTemplate === 'Fisher Scientific' && parseBy !== 'solr') {
      [].unshift.apply(issueDateFilters, [
        /()(creation\sdate\s*\d*.\w*.\d*\s*revision\sdate\s*)(\d*.\w*.\d*)(.*)/i,
        /()(creation\sdate\s*)(\d*.\w*.\d*)(.*)/i
      ]);

      filters.push(/.{1,}revision\sdate\s*\d{2}-\w*-\d{4}/i);
    }

    lines.forEach(function (line, index) {
      if (!line && firstEmptyLineInSection) return;

      firstEmptyLineInSection = false;
      cleanLine = line.trim();

      if ($scope.selectedTemplate === `Ward's Science`) {
        // if this is end of product_and_company_identification section
        if (productSection && currentSection !== 'product_and_company_identification') {
          productSection = false;

          // remove '\n' at the begin and end of the documentModel and add '\n' to productInfo and chemtrecInfo
          documentModel['product_and_company_identification'].set = documentModel[
            'product_and_company_identification'
          ].set.replace(/^\n*|\n*$/g, '');
          productInfo = productInfo.replace(/^\n*|\n*$/g, '') + '\n\n';
          chemtrecInfo = '\n\n' + chemtrecInfo.replace(/^\n*|\n*$/g, '');

          documentModel['product_and_company_identification'].set =
            productInfo + documentModel['product_and_company_identification'].set + chemtrecInfo;
        }

        switch (currentSection) {
          case 'product_and_company_identification':
            if (/^product/i.test(cleanLine)) {
              productNameFilter.push(/^product/i);
              documentModel.chemicalFriendlyName.set = getProductName(cleanLine);
            }

            if (/^product|synonyms/i.test(cleanLine)) {
              var withColon = cleanLine.replace(/^product|synonyms/i, function (w) {
                return w + ':';
              });
              productInfo += !productInfo ? withColon : '\n' + withColon;
              return;
            }

            if (!productSection) productSection = true;

            if (!chemtrecMarker && /^chemtrec/i.test(cleanLine)) {
              chemtrecMarker = true;
              if (fsm.current === 'waitingForSectionStart') fsm.sectionStartFound('');
            }

            break;
        }
      } else if ($scope.selectedTemplate === 'Flinn Scientific CDN' && parseBy !== 'solr') {
        cleanLine = cleanLine.replace(/:?\s*?\.{3,}/, ': ');

        if (regExs.sdsCodeRegEx.test(cleanLine)) {
          sdsCode = regExs.sdsCodeRegEx.exec(cleanLine)[1];
          return;
        } else if (regExs.productCodeRegEx.test(cleanLine)) {
          productCode = regExs.productCodeRegEx.exec(cleanLine)[1];
          return;
        } else if (/(sds.+\.com)/gi.test(cleanLine) && index !== lastLineIndex) return;

        if (index === lastLineIndex) {
          if (/(sds.+\.com)/gi.test(cleanLine))
            cleanLine = 'SDS & Labels by TECIS <a href="www.trivalent.com">www.trivalent.com</a>';

          documentModel['product_and_company_identification'].set += '\nCODE: ' + productCode;

          if (regExs.supplierInfoRegEx.exec($scope.unmatched)) {
            documentModel['product_and_company_identification'].set +=
              '\n\nSUPPLIER:\n' + regExs.supplierInfoRegEx.exec($scope.unmatched)[0];
          }

          documentModel['other_information'].set += '\n\nSDS #: ' + sdsCode;

          angular.forEach(regExs, function (value, key) {
            $scope.unmatched = $scope.unmatched.replace(value, '');
          });
        }
      } else if ($scope.selectedTemplate === 'Flinn Scientific US' && parseBy !== 'solr') {
        if (!documentModel.issueDate.set) {
          documentModel.issueDate.set = getIssueDate(cleanLine, issueDateFilters);

          if (documentModel.issueDate.set) return;
        }

        if (/(.*)(Revision Date)(.*)/i.test(cleanLine)) return;

        if (
          !documentModel.chemicalFriendlyName.set &&
          currentSection === 'product_and_company_identification'
        ) {
          documentModel.chemicalFriendlyName.set = cleanLine;
        }

        if (regExs.sdsCodeRegEx.test(cleanLine)) {
          sdsCode = cleanLine.match(regExs.sdsCodeRegEx);

          if (index !== lastLineIndex) return;
        }

        if (index === lastLineIndex) {
          documentModel['other_information'].set += '\n\n' + sdsCode;
          documentModel['other_information'].set +=
            '\n\nRevision Date: ' + documentModel.issueDate.set;

          documentModel['product_and_company_identification'].set = documentModel[
            'product_and_company_identification'
          ].set.replace(/Pictograms\n+/i, '');

          if (regExs.chemtrec.test(documentModel['product_and_company_identification'].set)) {
            documentModel['product_and_company_identification'].set = documentModel[
              'product_and_company_identification'
            ].set.replace(regExs.chemtrec, '$1 $2\n\n$3 ');
          }
        }
      } else if ($scope.selectedTemplate === 'Fisher Scientific' && parseBy !== 'solr') {
        if (!documentModel.chemicalFriendlyName.set)
          documentModel.chemicalFriendlyName.set = getProductName(cleanLine);
        if (!documentModel.issueDate.set)
          documentModel.issueDate.set = getIssueDate(cleanLine, issueDateFilters);

        fisherScientific.skips.forEach(function (skip) {
          if (skip === cleanLine) cleanLine = '';
        });

        cleanLine = makeColonLabels(fisherScientific.labels, cleanLine);
        cleanLine = makeBold(fisherScientific.bolds, cleanLine);

        switch (currentSection) {
          case 'product_and_company_identification':
            if (/(product)\s*(?:name|)\s*:*\s*(.*)/i.test(cleanLine)) {
              cleanLine = cleanLine.replace(/(product)\s*(?:name|)\s*:*\s*(.*)/i, '$1: $2');
            }

            if (cleanLine === '<b>Company</b>') cleanLine = '\n' + cleanLine;

            if (/\s*emergency\s*telephone\s*number\s*/i.test(cleanLine)) {
              documentModel['product_and_company_identification'].set +=
                fisherScientific.distributor + '\n' + fisherScientific.manufacturer;
              fisherScientific.stopFill = true;
            }

            if (fisherScientific.stopFill && !cleanLine) return;

            if (
              !fisherScientific.stopFill &&
              cleanLine &&
              (/\s*manufacturer\s*/i.test(cleanLine) || fisherScientific.manufacturer)
            ) {
              return fillManufacturerDistributor(fisherScientific, cleanLine);
            }

            break;
        }
      }

      if (index === lines.length - 1) {
        documentModel.safe_handling.set = documentModel.handling_storage.set;
      }

      if (filterText(cleanLine, filters)) return;

      checkForNewSection(cleanLine, currentSection, fsm);

      if (!documentModel.issueDate.set)
        documentModel.issueDate.set = getIssueDate(cleanLine, issueDateFilters);
      if (!documentModel.chemicalFriendlyName.set)
        documentModel.chemicalFriendlyName.set = getProductName(cleanLine);

      switch (fsm.current) {
        case 'waitingForSectionStart':
          if (cleanLine != '') fsm.sectionStartFound(cleanLine);
          break;
        case 'searchingForSectionParagraph':
          if (cleanLine != '') fsm.sectionParagraphFound(cleanLine);
          break;
        case 'inSectionContent':
          if (cleanLine == '') {
            fsm.emptyLineFound();
          } else if (chemtrecMarker) {
            chemtrecInfo += '\n' + cleanLine;
            if (
              cleanLine == '' ||
              /not\s*for\s*drug.\s*food\s*or\s*household\s*use.*/i.test(cleanLine)
            )
              chemtrecMarker = false;
          } else {
            documentModel[currentSection].set += '\n' + cleanLine;
          }
          break;
        default:
          if (cleanLine != '') $scope.unmatched += cleanLine + '\n';
          break;
      }
    });
  }

  //creates a document model that can be used to save data to the database.
  //set is required by solr to update fields in the database.
  //only fields that exist in solr can be a part of the document model.
  function createDocumentModel(file) {
    const now = Math.floor(new Date().getTime() / 1000);
    var documentModel = {};

    // default language when doc create.
    documentModel.language = ['en'];

    documentModel.dateModified = $scope.sdsDocument.dateModified = now;
    documentModel.dateCreated = $scope.sdsDocument.dateCreated = now;
    documentModel.resourcePath = {
      set: '/api/containers/sds/download/' + encodeURIComponent(file.name)
    };
    documentModel.isVerified = false;
    documentModel.mirrorSDS = {
      set: SearchFactory.languages
    };
    documentModel.issueDate = { set: '' };
    documentModel.chemicalFriendlyName = { set: '' };
    documentModel.safe_handling = { set: '' };
    documentModel.sds_source = { set: '' };

    $scope.sdsTextSections.forEach(function (section) {
      documentModel[section.field] = { set: '' };
    });

    return documentModel;
  }

  function getIssueDate(cleanLine, filters) {
    var line = cleanLine.toLowerCase();
    var isSet = false;
    var cleanDate = '';
    filters.forEach(function (filter) {
      if (!isSet && line.match(filter)) {
        cleanDate = line.replace(filter, '$1 $3');
        cleanDate = cleanDate.replace(/:/, '');
        cleanDate = cleanDate.replace(/-/g, ' ');
        cleanDate = cleanDate.replace(/supercedes.*/i, '');
        cleanDate = cleanDate.trim();
        cleanDate = cleanDate.replace(/\b[a-z]/g, function (l) {
          return l.toUpperCase();
        });
        $scope.sdsDocument['issueDate'] = { set: cleanDate };
        isSet = true;
      }
    });

    return cleanDate;
  }

  function getProductName(cleanLine) {
    var line = cleanLine.toLowerCase();
    var isSet = false;
    var cleanName = '';

    productNameFilter.forEach(function (filter) {
      if (!isSet && line.match(filter)) {
        cleanName = line.replace(filter, '');
        cleanName = cleanName.replace(/:/, '');
        cleanName = cleanName.trim();
        cleanName = cleanName.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
        isSet = true;
      }
    });

    return cleanName;
  }

  $scope.getDocument = function getDocumentById(documentId) {
    var searchId = { idList: 'id:' + documentId };
    SolrDocument.findByIdList(searchId).$promise.then(function (results) {
      var document = results.response.docs[0];
      if (!document) {
        $scope.documentDeleted = true;
        return;
      }
      $rootScope.languages.forEach(langEl => {
        linkedSds = linkedSds.concat(document[`${langEl}VersionId`]);
      });

      $scope.sdsDocument.id = document.id;
      $scope.sdsDocument['chemicalFriendlyName'] = {
        set: decodeURIComponent(document.chemicalFriendlyName)
      };
      $scope.sdsDocument['issueDate'] = {
        set: decodeURIComponent(document.issueDate)
      };
      $scope.sdsDocument.dateCreated = document.dateCreated;
      $scope.sdsDocument.dateModified = document.dateModified;
      $scope.sdsDocument['isVerified'] = document.isVerified;
      $scope.sdsDocument['safe_handling'] = {
        set: decodeURIComponent(document.safe_handling || '')
      };
      $scope.sdsDocument['new_jersey_right'] = {
        set: decodeURIComponent(document.new_jersey_right || '')
      };
      $scope.sdsDocument['sds_source'] = {
        set: decodeURIComponent(document.sds_source || '')
      };
      $scope.sdsDocument['resourcePath'] = { set: document.resourcePath };
      $scope.sdsDocument['synonyms'] = document.synonyms;
      $scope.sdsDocument.msds_format = document.msds_format;
      $scope.sdsDocument.no_sds = document.no_sds;
      $scope.sdsDocument.legislationCode = document.legislationCode;
      $scope.sdsDocument.manufacturer = document.manufacturer;
      $scope.sdsDocument.products = { set: document.products };
      $scope.sdsDocument.kit_cover = { set: document.kit_cover };
      $scope.currentFilename = getFilenameFromPath(document.resourcePath);
      $scope.sdsDocument.kit_cover = { set: document.kit_cover };

      if (document.kit_ids) {
        $scope.sdsDocument.kit_ids = { set: document.kit_ids };
        linkedSds = linkedSds.concat(document.kit_ids);
      }
      if (document.kit_name && document.kit_name !== 'undefined') {
        $scope.sdsDocument.kit_name = { set: decodeURIComponent(document.kit_name) };
      } else if (document.kit_name === 'undefined') {
        $scope.sdsDocument.kit_name = { set: null };
      }

      $scope.selectedLegislation = getLegislationByCode(document.legislationCode);
      $scope.selectedManufacturer = vm.manufacturers[document.manufacturer];

      document.products &&
        document.products.forEach(function (productId) {
          $scope.productsInSds[productId] = true;
        });

      getProductsOfDocument(document.products);

      formateDateModified();

      if ($scope.sdsDocument['chemicalFriendlyName'].set == 'undefined') {
        $scope.sdsDocument['chemicalFriendlyName'].set = '';
      }
      if ($scope.sdsDocument['issueDate'].set == 'undefined') {
        $scope.sdsDocument['issueDate'].set = '';
      }

      if ($scope.sdsTextSections) {
        $scope.sdsTextSections.forEach(function (section) {
          addContentToForm(section.field, decodeURIComponent(document[section.field]));
          if ($scope.sdsDocument[section.field].set == 'undefined') {
            $scope.sdsDocument[section.field].set = '';
          }
        });
      }

      if (document.hazardType) {
        document.hazardType.forEach(function (hazard) {
          $scope.toggleHazard(hazard);
        });
      }

      if (document.ppe_private_companyIds) {
        $scope.ppe_private_companyIds = document.ppe_private_companyIds;
      }

      if (document.ppe) {
        $scope.ppeSelection = document.ppe;
      }

      const regex = /^ppe_(?!private_companyIds)/;
      const companiesPPE = Object.keys(document).filter(key => regex.test(key));
      companiesPPE.forEach(key => {
        $scope.companiesPPE[key] = document[key];
      });

      initSelectedLanguages(document.language);

      if (document.tag) {
        tagSelection = document.tag;
        CheckTags();
      }

      if (document.companyId) {
        // workaround for duplicates
        document.companyId
          .reduce(function (sum, id) {
            return ~sum.indexOf(id) ? sum : sum.concat(id);
          }, [])
          .forEach(function (companyId) {
            $scope.toggleCompany(companyId, true);
          });
      }

      if (document.kit_ids && document.kit_ids.length) {
        vm.kitMode = true;
      }

      //not dirty, we just finished loading it
      $scope.saveState = '';
      $scope.saveError = false;
      $scope.isDirty = false;

      getSynonyms();
      getSdsDocumentData();

      CompanyService.getCurrentCompanyPromise().$promise.then(function () {
        Attachment.find({ filter: { where: { sourceId: [$scope.sdsDocument.id] } } }).$promise.then(
          function (res) {
            vm.attachments = AttachmentService.filterAttachments(res);
          }
        );
      });

      linkedSds = linkedSds.filter(function (id) {
        return !!id;
      });

      $scope.setNFPA(document.nfpa);
      $timeout(() => $rootScope.$broadcast('elastic:adjust'));
    });
  };

  $scope.setNFPA = function (sdsNFPA) {
    vm.nfpa = (sdsNFPA || []).reduce((all, el) => {
      let value = el.split('_');
      all[value[0]] = el;

      return all;
    }, {});

    vm.changeCopyNFPA = Object.assign({}, vm.nfpa);
  };

  $scope.getRestrictedNotes = function (companyId) {
    var searchId = { idList: 'id:' + $scope.sdsDocument.id };
    SolrDocument.findByIdList(searchId).$promise.then(function (results) {
      var document = results.response.docs[0];

      if (
        !$scope.sdsDocument['restricted_in_' + companyId] &&
        !document['restricted_in_' + companyId] &&
        document['restricted_in_common']
      ) {
        $scope.sdsDocument['restricted_in_' + companyId] = {
          set: decodeURIComponent(document['restricted_in_common'] || '')
        };
      }

      if (!$scope.sdsDocument['restricted_in_' + companyId]) {
        $scope.sdsDocument['restricted_in_' + companyId] = {
          set: decodeURIComponent(document['restricted_in_' + companyId] || '')
        };
      }
    });
  };

  vm.handleKitDropdownOpen = function (isOpen) {
    if (!isOpen) {
      vm.kitSpinnerActive = true;

      return $q.resolve();
    }

    return SolrDocument.getKit({ id: $scope.sdsDocument.id }).$promise.then(function (res) {
      vm.kitSpinnerActive = false;
      vm.kitDocs = res.docs;
    });
  };

  vm.createKit = function () {
    const kitIds = [$scope.sdsDocument.id];
    const kitName = $scope.sdsDocument.chemicalFriendlyName.set;
    const data = [
      {
        id: $scope.sdsDocument.id,
        kit_ids: { set: kitIds },
        kit_name: { set: encodeURIComponent(kitName) }
      }
    ];
    const body = {
      data: JSON.stringify(data)
    };

    return SolrDocument.update(body).$promise.then(function () {
      vm.kitMode = true;
      $scope.sdsDocument.kit_ids = { set: kitIds };
      $scope.sdsDocument.kit_name = { set: kitName };
    });
  };

  vm.onAddToKit = function () {
    ModalService.open({
      templateUrl: require('../../../views/modals/search-sds-without-kit.html'),
      controller: [
        '$uibModalInstance',
        'SearchPageService',
        function ($uibModalInstance, SearchPageService) {
          const modal = this;
          const customFilters = {
            filters: [{ field: '-kit_ids', value: `['' TO *]` }]
          };

          modal.selectedSds = {};
          modal.sps = SearchPageService;

          modal.sps.onReset();

          modal.onSearch = function (opts) {
            const payload = angular.copy(customFilters, opts || {});

            modal.sps.doSearch(payload);
          };

          modal.onPageChange = function () {
            modal.sps.onPageChange(customFilters);
          };

          modal.anySelected = function () {
            return Object.keys(modal.selectedSds).some(function (sdsId) {
              return modal.selectedSds[sdsId];
            });
          };

          modal.addIds = function () {
            $uibModalInstance.close(deObjectify(modal.selectedSds));
          };

          modal.cancel = $uibModalInstance.dismiss;
        }
      ]
    }).then(function (idsToAdd) {
      const newKitIds = $scope.sdsDocument.kit_ids.set.concat(idsToAdd);
      const data = newKitIds.map(function (id) {
        return {
          id: id,
          kit_ids: { set: newKitIds },
          kit_name: { set: encodeURIComponent($scope.sdsDocument.kit_name.set) }
        };
      });
      const body = {
        data: JSON.stringify(data)
      };

      $scope.sdsDocument.kit_ids.set = newKitIds;

      return SolrDocument.update(body).$promise;
    });
  };

  vm.onEditKitNameCover = function (kitDocs, kitName) {
    ModalService.open({
      templateUrl: require('../../../views/modals/edit-sds-kit.html'),
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          var modal = this;

          modal.cancel = $uibModalInstance.dismiss;
          modal.kitDocs = angular.copy(kitDocs);
          modal.kitName = kitName;
          modal.kitCoverId = (
            kitDocs.filter(function (kitDoc) {
              return kitDoc.kit_cover;
            })[0] || {}
          ).id;

          modal.confirm = function () {
            $uibModalInstance.close({
              kitName: modal.kitName,
              kitCoverId: modal.kitCoverId,
              kitIds: modal.kitDocs.map(function (doc) {
                return doc.id;
              })
            });
          };
        }
      ]
    }).then(function (result) {
      const kitIds = kitDocs.map(function (kitDoc) {
        return kitDoc.id;
      });
      const data = kitIds.map(function (id) {
        return {
          id: id,
          kit_name: { set: encodeURIComponent(result.kitName) },
          kit_cover: { set: id === result.kitCoverId },
          kit_ids: { set: result.kitIds }
        };
      });
      const body = {
        data: JSON.stringify(data)
      };

      if ($scope.sdsDocument.id === result.kitCoverId) {
        $scope.sdsDocument.kit_cover.set = true;
      } else {
        $scope.sdsDocument.kit_cover.set = false;
      }

      $scope.sdsDocument.kit_name.set = result.kitName;
      $scope.sdsDocument.kit_ids.set = result.kitIds;

      return SolrDocument.update(body).$promise;
    });
  };

  vm.onRemoveFromKit = function (document) {
    ModalService.open({
      templateUrl: require('../../../views/modals/confirm-message.html'),
      resolve: {
        message: [
          '$translate',
          function ($translate) {
            return $translate('PROCESS.REMOVE_FROM_KIT');
          }
        ]
      },
      controller: [
        '$uibModalInstance',
        'message',
        function ($uibModalInstance, message) {
          var modal = this;

          modal.message = message;
          modal.confirm = $uibModalInstance.close;
          modal.cancel = $uibModalInstance.dismiss;
        }
      ]
    }).then(function () {
      const updatedKitIds = $scope.sdsDocument.kit_ids.set.filter(function (id) {
        return id !== document.id;
      });
      const data = vm.kitDocs.map(function (kitDoc) {
        const dataItem = {
          id: kitDoc.id,
          kit_ids: { set: kitDoc.id === document.id ? [] : updatedKitIds }
        };

        if (kitDoc.id === document.id) {
          dataItem.kit_cover = { set: false };
          dataItem.kit_name = { set: '' };
        }

        return dataItem;
      });
      const body = {
        data: JSON.stringify(data)
      };

      return SolrDocument.update(body)
        .$promise.then(function () {
          vm.kitMode = false;

          $scope.sdsDocument.kit_ids.set = [];
          $scope.sdsDocument.kit_cover.set = false;
          $scope.sdsDocument.kit_name.set = '';
        })
        .catch(simpleCatch);
    });
  };

  vm.hasAny = function (hsh) {
    return hsh && !!Object.keys(hsh).length;
  };

  vm.searchProducts = function (text) {
    const minSearchTextLength = 3; // min search text length

    if (!text || (text && text.length < minSearchTextLength)) {
      var productIds = deObjectify($scope.productsInSds);

      return getProductsOfDocument(productIds);
    }

    var filter = {
      where: { sdsRequired: true, verified: true },
      include: ['manufacturer', 'companies'],
      fields: { sdsId: 0 }
    };

    Product.search({ text: text, filter: filter }).$promise.then(function (response) {
      vm.products = response.products;
    });
  };

  vm.isCompanyAllowed = function (companyId) {
    return !!~sdsEditorAllowedCompaniesIds.indexOf(companyId);
  };

  function initSelectedLanguages(languages) {
    if (!languages) return;

    languages.forEach(function (language) {
      SearchFactory.setLanguageSelected(language);
      $('#' + language).prop('checked', true);
    });
  }

  $scope.toggleLanguageSelected = function (languageValue) {
    setUnsavedStatus();
    // SearchFactory.toggleLanguageSelected(languageValue);
    const ls = {};
    ls[languageValue] = true;
    SearchFactory.languageSelected = ls;
  };

  //get the file name from the resource path
  function getFilenameFromPath(resourcePath) {
    try {
      return decodeURIComponent(resourcePath).replace(/^.*[\\/]/, '');
    } catch (err) {
      console.error(err);
    }
  }

  //add the line of content to the form
  function addContentToForm(currentSection, line) {
    if (!$scope.sdsDocument[currentSection] || !$scope.sdsDocument[currentSection].set) {
      $scope.sdsDocument[currentSection] = {};
      $scope.sdsDocument[currentSection] = { set: '' };
    }

    if (currentSection === 'handling_storage' && $scope.sdsDocument.safe_handling.set === '') {
      $scope.sdsDocument.safe_handling.set += line;
    }

    $scope.sdsDocument[currentSection].set += line;
  }

  //check if this is a new section and transition state machine
  function checkForNewSection(line, currentSection, fsm) {
    var possibleSection = matchSectionHeader(line);
    if (possibleSection != '' && possibleSection != currentSection) {
      fsm.sectionHeaderFound(possibleSection);
    }
  }

  function matchSectionHeader(line) {
    var cleanLine = prepareHeaderLine(line);

    for (var i = 0; i < $scope.sdsTextSections.length; i++) {
      for (var j = 0; j < $scope.sdsTextSections[i].fuzzyMatch.length; j++) {
        var matchStr = $scope.sdsTextSections[i].fuzzyMatch[j];
        if (matchStr.test(cleanLine)) {
          //console.log(line + "Did match : " + $scope.sdsTextSections[i].field);
          return $scope.sdsTextSections[i].field;
        }
      }
    }

    return '';
  }

  function prepareHeaderLine(line) {
    var cleanLine = line.toLowerCase();
    cleanLine = cleanLine.replace(/section/g, '');
    cleanLine = cleanLine.replace(/^parties/i, '');
    cleanLine = cleanLine.replace(/&/, 'and');
    cleanLine = cleanLine.replace(/[^0-9|\w]+/g, '');
    cleanLine = cleanLine.replace(/\./g, '');
    cleanLine = cleanLine.replace(/-/g, '');
    cleanLine = cleanLine.replace(/,/g, '');
    cleanLine = cleanLine.replace(/\//g, '');
    cleanLine = cleanLine.replace(/\s/g, '');
    cleanLine = cleanLine.replace(/^XX/i, '20');
    cleanLine = cleanLine.replace(/^XIX/i, '19');
    cleanLine = cleanLine.replace(/^XVIII/i, '18');
    cleanLine = cleanLine.replace(/^XVII/i, '17');
    cleanLine = cleanLine.replace(/^XVI/i, '16');
    cleanLine = cleanLine.replace(/^XV/i, '15');
    cleanLine = cleanLine.replace(/^XIV/i, '14');
    cleanLine = cleanLine.replace(/^XIII/i, '13');
    cleanLine = cleanLine.replace(/^XII/i, '12');
    cleanLine = cleanLine.replace(/^XI/i, '11');
    cleanLine = cleanLine.replace(/^X/i, '10');
    cleanLine = cleanLine.replace(/^IX/i, '9');
    cleanLine = cleanLine.replace(/^VIII/i, '8');
    cleanLine = cleanLine.replace(/^VII/i, '7');
    cleanLine = cleanLine.replace(/^VI/i, '6');
    cleanLine = cleanLine.replace(/^V/i, '5');
    cleanLine = cleanLine.replace(/^IV/i, '4');
    cleanLine = cleanLine.replace(/^III/i, '3');
    cleanLine = cleanLine.replace(/^II/i, '2');
    cleanLine = cleanLine.replace(/^I/i, '1');
    return cleanLine;
  }

  function filterText(line, filters) {
    for (var i = 0; i < filters.length; ++i) {
      if (filters[i].test(line)) return true;
    }
    return false;
  }

  function startSpinner() {
    $scope.spinnerActive = true;
    usSpinnerService.spin('spinner-1');
  }

  function stopSpinner() {
    usSpinnerService.stop('spinner-1');
    $scope.spinnerActive = false;
  }

  function GetCompanies(callback) {
    Company.find(
      {
        filter: {
          include: [
            { relation: 'childSdsCompanies', scope: { fields: ['id'] } },
            { relation: 'parentSdsCompany', scope: { fields: ['id', 'name'] } }
          ]
        }
      },
      function (companies) {
        callback(companies);
      },
      function (err) {
        //console.log("ERROR : " + err);
      }
    );
  }

  function HandleLoadedCompanies(companies) {
    companies.forEach(function (company) {
      $scope.companies[company.id] = company;
    });

    if ($state.params.id) {
      $scope.getDocument($state.params.id);
      $scope.editMode = true;
    }
  }

  // get the tags for a company and add them to the list
  function GetTagsForCompany(companyId) {
    if (!vm.tagsByCompanyId[companyId]) {
      vm.tagsByCompanyId[companyId] = new TagFactory(Tag);
    }

    vm.tagsByCompanyId[companyId].getTags(companyId, CheckTags);
  }

  function GetCompanySettings(companyId, callback) {
    CompanySetting.findById(
      {
        id: companyId,
        filter: {
          include: [
            { relation: 'storageCodes', scope: { where: { isEnabled: true } } },
            { relation: 'disposalCodes', scope: { where: { isEnabled: true } } }
          ]
        }
      },
      function (data) {
        vm.companySettingsByCompanyId[companyId] = data;
        return SolrDocument.getCompanyAttrs({
          id: $scope.sdsDocument.id,
          companyId: companyId,
          includeAllAtrrs: true
        }).$promise.then(function (resp) {
          const available = data.attrs || {},
            enabled = resp.attrs;
          vm.attrs[companyId] = Object.keys(available).reduce(function (sum, name) {
            sum[name] = enabled[name];
            return sum;
          }, {});
          callback && callback(null, data);
        });
      },
      function (err) {
        console.log('ERROR : ' + JSON.stringify(err), err);
        callback && callback(err);
      }
    );
  }

  function CheckTags() {
    tagSelection.forEach(function (tagId) {
      vm.UniversalTags.setTagSelected(tagId);

      Object.keys(vm.tagsByCompanyId).forEach(companyId => {
        const companyTagsFactory = vm.tagsByCompanyId[companyId];
        companyTagsFactory.setTagSelected(tagId, function () {
          $scope.getRestrictedNotes(companyId);
        });
      });

      Object.keys(vm.tagsByCompanyId).forEach(companyId => {
        const companyTagsFactory = vm.tagsByCompanyId[companyId];
        for (tagId of Object.keys(companyTagsFactory.tagLookup)) {
          const tag = companyTagsFactory.tagLookup[tagId];
          checkParentTagIfAllChildrenAreSelected(tag);
        }
      });
    });
  }

  function checkParentTagIfAllChildrenAreSelected(tag) {
    const companyTagsFactory = vm.tagsByCompanyId[tag.companyId];
    const parentTag = companyTagsFactory.tagLookup[tag.tagId];

    if (!parentTag) return false;

    const childrenWhichAreNotParents = parentTag.tags.filter(t => !t.tags || !t.tags.length);
    const childrenWhichAreParents = parentTag.tags.filter(t => t.tags && t.tags.length);

    parentTag.parentChecked =
      childrenWhichAreNotParents.every(t => companyTagsFactory.tagSelected[t.id.toString()]) &&
      childrenWhichAreParents.every(t => t.parentChecked);

    if (parentTag.tagId) {
      checkParentTagIfAllChildrenAreSelected(parentTag);
    }
  }

  function getProductsOfDocument(productIds) {
    if (!productIds || !productIds.length) return;

    var filter = {
      filter: {
        include: ['manufacturer', 'measure'],
        fields: { sdsId: 0 },
        where: {
          id: {
            inq: productIds
          }
        }
      }
    };

    return Product.find(filter).$promise.then(function (products) {
      vm.products = products;
    });
  }

  function Initialize() {
    if ($rootScope.checkIfUserIs('sdsEditor')) {
      SdsUser.findById({
        id: $rootScope.currentUser.id,
        filter: {
          include: {
            relation: 'allowedCompanies',
            scope: {
              include: [
                { relation: 'childSdsCompanies', fields: ['id'] },
                { relation: 'parentSdsCompany', fields: ['id', 'name'] }
              ]
            }
          }
        }
      }).$promise.then(user => {
        const allowedCompanies = user.allowedCompanies || [];
        sdsEditorAllowedCompaniesIds = allowedCompanies.map(company => company.id);

        HandleLoadedCompanies(allowedCompanies);
        console.log($scope.processViewModel.nfpa);
      });
    } else {
      GetCompanies(HandleLoadedCompanies);
    }
    vm.UniversalTags.getTags('', CheckTags);
  }

  vm.getBrighterColor = function (color) {
    if (!color) {
      return '';
    }

    return HelperService.getBrighterColor(color);
  };

  vm.newVersion = function (doc) {
    return ModalService.open({
      keyboard: false,
      backdrop: 'static',
      controller: 'newVersionCtrl',
      controllerAs: 'ctrl',
      templateUrl: require('../newVersionCtrl/newVersion.html'),
      resolve: {
        document: $scope.sdsDocument
      }
    }).then(function (filename) {
      SpinnerService.start();
      try {
        return processDocument(
          {
            name: filename
          },
          function (err, model) {
            if (err) {
              SpinnerService.stop();
              return ErrorService.simpleCatch(err);
            }
            return SolrDocument.setNewVersion({
              currentId: $scope.sdsDocument.id,
              newId: model.id,
              copy: true
            })
              .$promise.then(function (data) {
                $scope.sdsDocument = {};
                populateForm(data2model(data));
                $scope.sdsDocument.isVerified = data.isVerified;
                $scope.sdsDocument.dateModified = data.dateModified;
                $scope.sdsDocument.msds_format = data.msds_format;
                $scope.sdsDocument.no_sds = data.no_sds;
                formateDateModified();

                SpinnerService.stop();
                return ModalService.open({
                  templateUrl: 'areYouSure.html'
                })
                  .then(function () {
                    return $scope.reParseDocument(doc.id).then(function () {
                      $scope.sdsDocument.isVerified = false;
                      $scope.sdsDocument.msds_format = false;
                      $scope.sdsDocument.no_sds = false;
                    });
                  })
                  .catch(angular.noop);
              })
              .catch(function (err) {
                SpinnerService.stop();
                return ErrorService.simpleCatch(err);
              });
          }
        );
      } catch (err) {
        SpinnerService.stop();
        console.error(err);
        return ErrorService.simpleCatch(err);
      }
    });
  };

  vm.archiveFiles = function (doc) {
    return ModalService.open({
      keyboard: false,
      backdrop: 'static',
      controller: 'archiveFilesCtrl',
      controllerAs: 'ctrl',
      templateUrl: require('../archiveFilesCtrl/archiveFiles.html'),
      size: 'xlg',
      resolve: {
        document: function () {
          return $scope.sdsDocument;
        },
        companyIds: function () {
          return vm.allSdsCompanies.companyIds;
        },
        attrs: function () {
          return vm.attrs;
        }
      }
    }).then(function (res) {
      console.log(res);
    });
  };

  vm.removeManufacturer = function () {
    $scope.selectedManufacturer = vm.knownManu[0];
    setUnsavedStatus();
  };

  vm.toggleParentTag = function (parentTag, companyId) {
    setUnsavedStatus();
    toggleChildrenTags(parentTag.tags, parentTag.parentChecked, companyId);
  };

  Initialize();

  function showCompanyProductWarningModal() {
    return ModalService.open({
      templateUrl: require('../../../views/modals/confirm-message.html'),
      resolve: {
        message: [
          '$translate',
          function ($translate) {
            return $translate('PROCESS.WARN_REMOVING_COMPANY_WITH_PRODUCTS');
          }
        ]
      },
      controller: [
        '$uibModalInstance',
        'message',
        function ($uibModalInstance, message) {
          var modal = this;

          modal.message = message;

          modal.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };
        }
      ]
    });
  }

  function toggleChildrenTags(tags = [], status, companyId) {
    for (let tag of tags) {
      if (tag.tags && tag.tags.length) {
        tag.parentChecked = status;
        toggleChildrenTags(tag.tags, status, companyId);
        continue;
      }

      if (!~tagsWereChanged.indexOf(tag.id)) tagsWereChanged = uniq(tagsWereChanged.concat(tag.id));

      vm.allSdsCompanies.companyIds.forEach(function (companyId) {
        if (vm.tagsByCompanyId[companyId]) {
          vm.tagsByCompanyId[companyId].toggleTagSelected(tag.id, status);
        }
      });

      const idx = tagSelection.indexOf(tag.id);
      if (status) {
        tagSelection = uniq(tagSelection.concat(tag.id));
      } else {
        tagSelection.splice(idx, 1);
      }
    }
  }
}

var fillManufacturerDistributor = (function () {
  var count = 0;

  return function (fisherScientific, line) {
    if (line === 'Tel: 1-800-234-7437') return (fisherScientific.distributor += '\n' + line);

    if (count % 2) {
      fisherScientific.distributor += '\n' + line;
    } else {
      fisherScientific.manufacturer += '\n' + line;
    }

    count++;
  };
})();

function makeColonLabels(labels, line) {
  var newLine = '';

  labels.some(function (label) {
    if (label.test(line)) {
      if (label == '/^(Methods for Containment and Clean)/') {
        newLine = line.replace(label, '$1 Up: ');
      } else if (label == '/^(Symptomss*/s*effects,s*both acute and)/') {
        newLine = line.replace(label, '$1 delayed: ');
      } else {
        newLine = line.replace(label, '$1: ');
      }

      newLine = newLine.replace(/\s{2,}/g, ' ');
      newLine = newLine.replace(/\s*:/, ':');

      return true;
    }
  });

  return newLine || line;
}

function makeBold(bolds, line) {
  var newLine = '';

  bolds.some(function (bold) {
    if (bold.test(line)) {
      newLine = line.replace(bold, '<b>$1</b>');

      return true;
    }
  });

  return newLine || line;
}
