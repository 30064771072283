import angular from 'angular';
import { uniqBy } from 'lodash';
import app from '../../app';

app.controller('viewSdsCtrl', [
  '$scope',
  '$state',
  '$rootScope',
  '$translate',
  '$location',
  'SolrDocument',
  'CompanySetting',
  'Company',
  'CompanyService',
  'TagService',
  'HelperService',
  'ProductService',
  'HazardService',
  'LabelBatchService',
  function (
    $scope,
    $state,
    $rootScope,
    $translate,
    $location,
    SolrDocument,
    CompanySetting,
    Company,
    CompanyService,
    TagService,
    HelperService,
    ProductService,
    HazardService,
    LabelBatchService
  ) {
    const vm = this;
    let companyId;

    CompanyService.getCurrentCompanyPromise().$promise.then(function (results) {
      companyId = $rootScope.companyId;
    });

    $scope.sdsTextSections = [];
    $scope.document = null;
    $scope.documentId = $state.params.id;
    $scope.companies = [];

    vm.HazardService = HazardService;
    vm.kitMode = false;
    vm.handleSelectDocument = selectDocument;
    vm.isDocAlreadyAdded = LabelBatchService.isDocAlreadyAdded;
    vm.inventories = [];
    vm.currentDocumentId = $scope.documentId;

    vm.toggleDocInLabelBatch = function (doc) {
      const docToAdd = angular.copy(doc);
      docToAdd.tag = vm.tagsFromPanel.filter(function (tag) {
        return companyId === tag.companyId && tag.enabled;
      });
      docToAdd.manufacturer = vm.manufacturerFromPanel;

      LabelBatchService.toggleDocInLabelBatch(docToAdd);
    };

    function textTranslate() {
      $translate([
        'PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.FLAME.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.CORROSION.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.DESCRIPTION',
        'PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.TITLE',
        'PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.DESCRIPTION'
      ]).then(function (translations) {
        var translate = {};

        for (var translation in translations) {
          translate[translation] = translations[translation];
        }

        setTranslations(translate);
      });
    }

    var restrictedInCompanies = [];

    $rootScope.$on('$translateChangeSuccess', function () {
      textTranslate();
    });

    function initialSections(documentId) {
      $scope.sdsTextSections = [
        { field: 'product_and_company_identification', number: 1, visible: true, opened: true },
        { field: 'hazards_identification', number: 2, visible: true, opened: true },
        { field: 'composition_information_ingredients', number: 3, visible: true, opened: true },
        { field: 'first_aid_measures', number: 4, visible: true, opened: true },
        { field: 'fire_fighting_measures', number: 5, visible: true, opened: true },
        { field: 'accidental_release_measures', number: 6, visible: true, opened: true },
        { field: 'handling_storage', number: 7, visible: true, opened: true },
        {
          field: 'exposure_controls_personal_protection',
          number: 8,
          visible: true,
          opened: true
        },
        { field: 'physical_chemical_properties', number: 9, visible: true, opened: true },
        { field: 'stability_reactivity', number: 10, visible: true, opened: true },
        { field: 'toxilogial_information', number: 11, visible: true, opened: true },
        { field: 'ecological_information', number: 12, visible: true, opened: true },
        { field: 'disposal_considerations', number: 13, visible: true, opened: true },
        { field: 'transport_information', number: 14, visible: true, opened: true },
        { field: 'regulatory_information', number: 15, visible: true, opened: true },
        { field: 'other_information', number: 16, visible: true, opened: true }
      ];

      textTranslate();

      $scope.getDocument(documentId || $state.params.id);
    }

    function setTranslations(translate) {
      $scope.hazardTypes = {
        healthHazard: {
          value: 'healthHazard',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.HEALTHHAZARD.TITLE'],
          description: formatDescription('HEALTHHAZARD')
        },
        flame: {
          value: 'flame',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE'],
          description: formatDescription('FLAME')
        },
        exclamationMark: {
          value: 'exclamationMark',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE'],
          description: formatDescription('EXCLAMATIONMARK')
        },
        corrosion: {
          value: 'corrosion',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE'],
          description: formatDescription('CORROSION')
        },
        gasCylinder: {
          value: 'gasCylinder',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE'],
          description: formatDescription('GASCYLINDER')
        },
        explodingBomb: {
          value: 'explodingBomb',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.EXPLODINGBOMB.TITLE'],
          description: formatDescription('EXPLODINGBOMB')
        },
        skullAndCrossbones: {
          value: 'skullAndCrossbones',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE'],
          description: formatDescription('SKULLANDCROSSBONES')
        },
        flameOverCircle: {
          value: 'flameOverCircle',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE'],
          description: formatDescription('FLAMEOVERCIRCLE')
        },
        environment: {
          value: 'environment',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.ENVIRONMENT.TITLE'],
          description: formatDescription('ENVIRONMENT')
        },
        biohazard: {
          value: 'biohazard',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE'],
          description: formatDescription('BIOHAZARD')
        },
        flame_old: {
          value: 'flame_old',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAME.TITLE'],
          description: formatDescription('FLAME')
        },
        exclamationMark_old: {
          value: 'exclamationMark_old',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.EXCLAMATIONMARK.TITLE'],
          description: formatDescription('EXCLAMATIONMARK')
        },
        corrosion_old: {
          value: 'corrosion_old',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.CORROSION.TITLE'],
          description: formatDescription('CORROSION')
        },
        gasCylinder_old: {
          value: 'gasCylinder_old',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.GASCYLINDER.TITLE'],
          description: formatDescription('GASCYLINDER')
        },
        skullAndCrossbones_old: {
          value: 'skullAndCrossbones_old',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.SKULLANDCROSSBONES.TITLE'],
          description: formatDescription('SKULLANDCROSSBONES')
        },
        flameOverCircle_old: {
          value: 'flameOverCircle_old',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.FLAMEOVERCIRCLE.TITLE'],
          description: formatDescription('FLAMEOVERCIRCLE')
        },
        reactiveMaterials_old: {
          value: 'reactiveMaterials_old',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.REACTIVEMATERIALS.TITLE'],
          description: formatDescription('REACTIVEMATERIALS')
        },
        biohazard_old: {
          value: 'biohazard_old',
          title: translate['PROCESS.SOLR_FORM.HAZARDS.BIOHAZARD.TITLE'],
          description: formatDescription('BIOHAZARD')
        }
      };

      $scope.sdsTextSections.forEach(function (section, index) {
        section.title = translate['PROCESS.SOLR_FORM.SECTION_' + ++index];
      });

      function formatDescription(hazard) {
        var description =
          translate['PROCESS.SOLR_FORM.HAZARDS.' + hazard + '.DESCRIPTION'].split('*');
        var formattedDescription = '<ul>';

        description.forEach(function (item) {
          item = item.trim();

          if (!item) return;

          item = item.replace(/^./, function (l) {
            return l.toUpperCase();
          });
          formattedDescription += '<li>' + item + '</li>';
        });

        formattedDescription += '</ul>';

        return formattedDescription;
      }
    }

    $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
      if (from.name) $scope.showBackButton = true;
    });

    $scope.navbarTextStyle = function () {
      let additionalBlocks = 20;
      let el = angular.element('#WrapNavbarButtonRight01');
      if (el.length > 0) additionalBlocks += el[0].offsetWidth;

      el = angular.element('#ButtonBack01');
      if (el.length > 0) additionalBlocks += el[0].offsetWidth;

      return {
        'max-width': 'calc(99% - ' + additionalBlocks + 'px)',
        'white-space': 'nowrap',
        overflow: 'hidden',
        'text-overflow': 'ellipsis'
      };
    };

    $scope.$watch('siteLanguage', function (newValue, oldValue) {
      if (newValue === oldValue) return;

      if ($scope.document[`${newValue}VersionId`]) {
        init($scope.document[`${newValue}VersionId`]);
        $location.path(`/viewSds/${$scope.document[`${newValue}VersionId`]}`);
      }
    });

    $scope.getDocument = function getDocumentById(documentId) {
      const searchId = { idList: 'id:' + documentId };

      SolrDocument.findByIdList(searchId).$promise.then(function (res) {
        const document = res.response.docs[0];

        if (!document) {
          $scope.notFound = true;
          return;
        }

        if (document.kit_ids && document.kit_ids.length) {
          try {
            vm.kitName = decodeURIComponent(document.kit_name);
          } catch (err) {
            console.error(err);
          }

          SolrDocument.getKit({ id: document.id }).$promise.then(function (res) {
            vm.kitDocs = res.docs;

            vm.kitDocs.forEach(function (doc, i) {
              if (doc.id === $scope.documentId) return (vm.coverIndex = i);
            });

            vm.kitMode = true;
          });
        } else {
          selectDocument(document);
        }
      });
    };

    function checkHasRestrictedTag(companyId, documentTagIds) {
      TagService.getCompanyTags(companyId).then(function (tags) {
        for (const documentTagId of documentTagIds) {
          const documentCompanyTag = tags.find(t => t.id.toString() === documentTagId);
          if (!documentCompanyTag) continue;

          if (
            documentCompanyTag.restricted ||
            documentCompanyTag.title.toLowerCase() === 'restricted'
          ) {
            $scope.document.hasRestricts = true;
            restrictedInCompanies.push(companyId);
            $scope.document.restrictedInCompanies = restrictedInCompanies;
          }
        }
      });
    }

    var emphases = [
      { match: /:/, replace: /.*?:/ },
      { match: /#/, replace: /.*?#/ },
      { match: /!/, replace: /.*?!/ },
      { match: /\b[A-Z]{2,}\b/g, replace: /\b[A-Z]{2,}\b/g }
    ];

    //adds <b> tags to the beginning text of paragraphs
    //and to any text that appears to need it.
    function addEmphasis(content) {
      var newContent = '';
      var isNewParagraph = true;
      var lines = content.split(/\n/);

      var isTable = false;

      lines.forEach(function (line) {
        var matchIndex = getEmphasisMatchIndex(line);

        //handle tables
        if (!isTable) {
          isTable = /<table>/i.test(line);
        }

        if (isTable) {
          //just add table content
          newContent += line;
          if (/<\/table>/i.test(line)) {
            isTable = false;
          }
        } else {
          //regular content
          if (matchIndex >= 0) {
            //bold all the things in the ephases list
            newContent += line.replace(emphases[matchIndex].replace, function (matchStr) {
              return '<b>' + matchStr + '</b>';
            });
            newContent += '<br>\n';
            isNewParagraph = false;
          } else if (isNewParagraph) {
            if (/\./.test(line)) {
              //bold the first sentence up the the period
              newContent += line.replace(/.*?\./, function (matchStr) {
                return '<b>' + matchStr + '</b>';
              });
              newContent += '<br>\n';
            } else {
              //no period so just bold the first sentence
              newContent += '<b>' + line + '</b><br>\n';
            }
            isNewParagraph = false;
          } else {
            //add new content as normal and look for blank lines that mean a new paragraph should be coming
            newContent += line + '<br>\n';
            if (line == '') {
              isNewParagraph = true;
            }
          }
        }
      });

      return newContent;
    }

    //returns this index of empahsis that was matched
    function getEmphasisMatchIndex(line) {
      for (var i = 0; i < emphases.length; ++i) {
        if (emphases[i].match.test(line)) return i;
      }
      return -1;
    }

    function init(documentId) {
      if ($scope.currentUser && $scope.currentUser.role === 'admin') {
        initialSections(documentId);
      } else {
        CompanyService.getCurrentCompanyPromise().$promise.then(function (results) {
          companyId = $rootScope.companyId;
          getSections(companyId, function () {
            $scope.getDocument(documentId);
            textTranslate();
          });
        });
      }
    }

    function getSections(companyId, callback) {
      CompanySetting.findById(
        { id: companyId },
        function (layout) {
          if (layout.sections) {
            $scope.sdsTextSections = layout.sections;
          } else {
            initialSections();
          }

          callback();
        },
        function (err) {
          console.log('ERROR : ' + err, err);
          initialSections();
        }
      );
    }

    function selectDocument(document) {
      $scope.document = document;
      vm.currentDocumentId = document.id;

      if (!vm.kitMode) {
        $scope.document.chemicalFriendlyName = decodeURIComponent(
          $scope.document.chemicalFriendlyName
        );
      }

      $scope.sdsTextSections.forEach(function (section) {
        section.value = decodeURIComponent(document[section.field]);
        if (section.value == null || section.value == 'undefined' || section.value == '') {
          section.value = '';
        } else {
          section.value = addEmphasis(section.value);
        }
      });

      if (!$scope.document.language) {
        $scope.document.language = ['en'];
      }

      if ($scope.document.dateCreated) {
        const date = new Date($scope.document.dateCreated * 1000);
        $scope.document.created =
          date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      }

      if ($scope.document.dateModified) {
        const date = new Date($scope.document.dateModified * 1000);
        $scope.document.lastDateModified =
          date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      }

      if ($scope.document.companyId && $scope.document.tag) {
        $scope.document.companyId.forEach(companyId =>
          checkHasRestrictedTag(companyId, $scope.document.tag)
        );
      }

      if ($scope.document.products && $rootScope.checkIfUserIs('admin')) {
        const documentId = $scope.document.id;

        ProductService.getByIds($scope.document.products, [
          'name',
          'code',
          'size',
          'sizeType',
          'customSize'
        ]).then(function (products) {
          if (documentId === $scope.document.id) {
            // the hack for quick switching kit tabs
            $scope.document.productLabels = products.map(function (product) {
              return {
                name: product.name + ' | ' + product.code,
                title: ProductService.showCorrectProductSize(product)
              };
            });
          }
        });
      }

      if ($scope.document.products && companyId) {
        CompanyService.getCurrentCompanyPromise().$promise.then(function (results) {
          companyId = $rootScope.companyId;

          if ($scope.document.products && companyId) {
            const productIdsFilter = $scope.document.products.map(function (productId) {
              return { id: productId };
            });

            Company.products({
              id: companyId,
              filter: {
                where: { and: [{ sdsRequired: true }, { or: productIdsFilter }] },
                include: 'manufacturer'
              }
            }).$promise.then(function (products) {
              vm.documentHasCompanyProducts = !!products.length;

              if (!products.length) return (vm.inventories = []);

              const productIds = products.map(function (product) {
                return product.id;
              });

              ProductService.getInventoriesTree(productIds, companyId).then(function (inventories) {
                vm.inventories = inventories;
              });
            });
          }
        });
      } else {
        vm.inventories = [];
        vm.documentHasCompanyProducts = false;
      }

      Company.find({
        filter: {
          where: {
            id: {
              inq: $scope.document.companyId
            }
          },
          include: 'childSdsCompanies'
        }
      })
        .$promise.then(function (companies) {
          const childSdsCompanies = companies.reduce(
            (sum, company) => sum.concat(company.childSdsCompanies || []),
            []
          );

          return uniqBy(companies.concat(childSdsCompanies), 'id');
        })
        .then(function (companies) {
          $scope.companies = [{ name: 'Global', id: null }].concat(companies);
        })
        .catch(console.error);

      //protect against adblockers
      try {
        if (!$rootScope.checkIfUserIs('admin')) {
          window.gtag('event', 'page_view', {
            page_title: $scope.document.chemicalFriendlyName
          });
        }
      } catch (e) {
        console.error('GA is not available');
      }
    }

    vm.getBrighterColor = function (color) {
      if (!color) {
        return '';
      }

      return HelperService.getBrighterColor(color);
    };

    init($state.params.id);
  }
]);
