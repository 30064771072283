import app from '../app';

app.service('GHSService', [
  'GHSData',
  function (GHSData) {
    const ctx = this;
    ctx.data = {};

    ctx.getData = async function (sdsIds) {
      const response = await GHSData.getGHSData({ sdsIds }).$promise;
      sdsIds.forEach(id => {
        ctx.data[id] = response[id] || {};
      });

      return ctx.data;
    };
  }
]);
