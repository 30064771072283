import app from '../../app';
import { flatWoods } from '../../helpers';

app.directive('settingsAddSdsForm', [
  '$rootScope',
  '$translate',
  'InventoryService',
  'TagService',
  function ($rootScope, $translate, InventoryService, TagService) {
    return {
      restrict: 'E',
      templateUrl: require('./settingsAddSdsForm.html'),
      scope: {
        vmSettings: '=',
        commonSettings: '=',
        formType: '=',
        requesterFormAvailable: '='
      },
      link: function (scope) {
        const trigerRequesterString = $translate.instant('SETTINGS.TRIGER_REQUESTER_FORM');

        scope.tags = [];
        scope.inventories = [];
        scope.dropDown = [{}];
        scope.dropDownValuesToString = values => {
          return values
            .map(v => {
              let value = v.value ? v.value : v;
              if (v.requesterForm) {
                value = `${value} (${trigerRequesterString})`;
              }
              return value;
            })
            .join(' ,');
        };
        scope.addRequestField = (name, dropDown) => {
          scope.vmSettings.addRequestField(scope.formType, name, dropDown);
          scope.dropDown = [{}];
        }
        init();

        function init() {
          if (scope.requesterFormAvailable) {
            initTagsWorkflow();
            initInventoryWorkflow();
          }
        }

        async function initTagsWorkflow() {
          const companyId = $rootScope.companyId;
          await TagService.getCompanyTags(companyId);

          scope.tags = flatWoods(TagService.companyTagsTree[companyId], 'tags')
            .filter(tag => !tag.tags || !tag.tags.length)
            .reduce((sum, tag) => {
              if (tag.archived || tag.restricted) return sum;
              return sum.concat({
                title:
                  tag.path && tag.path.length
                    ? tag.path.join(' / ') + ' / ' + tag.title
                    : tag.title,
                value: tag.id
              });
            }, []);
        }

        async function initInventoryWorkflow() {
          const companyId = $rootScope.companyId;
          const items = await InventoryService.getTreeInfo(companyId);
          scope.inventories = Object.values(items).reduce((sum, inv) => {
            if (!inv.data) return sum;
            return sum.concat({
              title: inv.path && inv.path.length ? inv.path.replace('/', ' / ') : inv.data.name,
              value: inv.data.id
            });
          }, []);
        }
      }
    };
  }
]);
